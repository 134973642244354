import React, { ReactNode } from 'react';

import { HouseCanaryLogoFullColor } from '@hcs/design-system';
import { PropertyStateLocation } from '@hcs/types';
import { formatCityStateZip, formatStreetAddress } from '@hcs/utils';

import { CobrandedLogo } from '../../features';
import { Personalization } from '../../features/Personalization';
import { Page } from '../Page';
import { Title } from '../Title';

import styles from './CoverPage.module.css';

export interface CoverMetadata {
  dataHcName: string;
  label: ReactNode;
  value: ReactNode;
}

interface Props {
  dataHcName: string;
  title: string;
  photoUrl?: string;
  description: string;
  originalAddressInputted?: PropertyStateLocation['location'] | undefined;
  location?: PropertyStateLocation['location'] | null;
  metadata?: CoverMetadata[];
  showPersonalization?: boolean;
  customContent?: ReactNode;
}

export const CoverPage = ({
  dataHcName,
  title,
  description,
  photoUrl,
  originalAddressInputted,
  location,
  metadata,
  customContent,
  showPersonalization,
}: Props) => {
  return (
    <Page dataHcName={dataHcName} className={styles.Page}>
      <CobrandedLogo className={styles.CobrandedLogo} logoOnly={false} />
      <section className={styles.Body}>
        <Title description={description}>{title}</Title>
        <div className={styles.Subject}>
          <div className={styles.SubjectPhoto}>
            <img
              alt="Subject Property"
              data-hc-name={`${dataHcName}-photo`}
              className={styles.SubjectPhotoImg}
              src={photoUrl}
            />
            {showPersonalization && <Personalization />}
            {customContent}
          </div>
          <div className={styles.SubjectDetails}>
            <strong data-hc-name={`${dataHcName}-location-title`}>
              Property Location
            </strong>
            <p>
              <span data-hc-name={`${dataHcName}-street-address`}>
                {formatStreetAddress(location)}
              </span>
              <br />
              <span data-hc-name={`${dataHcName}-city-state-zip`}>
                {formatCityStateZip(location)}
              </span>
            </p>
            {originalAddressInputted && (
              <p className={styles.OriginalAddress}>
                <strong data-hc-name={`${dataHcName}-location-title`}>
                  Original Address Inputted
                </strong>
                <p>
                  <span data-hc-name={`${dataHcName}-street-address`}>
                    {formatStreetAddress(location)}{' '}
                    {formatCityStateZip(location)}
                  </span>
                </p>
              </p>
            )}
            {metadata?.map((metadataItem) => {
              return (
                <React.Fragment
                  key={`${dataHcName}-${metadataItem.dataHcName}`}
                >
                  <strong
                    data-hc-name={`${dataHcName}-${metadataItem.dataHcName}-label`}
                  >
                    {metadataItem.label}
                  </strong>
                  <p
                    data-hc-name={`${dataHcName}-${metadataItem.dataHcName}-value`}
                  >
                    {metadataItem.value}
                  </p>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </section>
      <HouseCanaryLogoFullColor
        dataHcName={`${dataHcName}-logo`}
        className={styles.Logo}
      />
    </Page>
  );
};
