import React from 'react';

import { Section } from '@hcs/pdf/pdf-service';

import { AppraisalSubjectDetailsSection } from '../..//features/AppraisalSubjectDetailsSection';
import { AppraisedValueComparison } from '../..//features/AppraisedValueComparison';
import { NearbyPropertiesChartsSection } from '../..//features/NearbyPropertiesCharts';
import { AppraisalReviewPage } from '../..//pdf-pages/AppraisalReviewReports/AppraisalReviewPage';

const dataHcName = 'appraisal-review-subject-page';
export const AppraisalReviewSubjectPage = () => {
  return (
    <AppraisalReviewPage dataHcName={dataHcName}>
      <Section dataHcName={dataHcName}>
        <AppraisedValueComparison />
      </Section>
      <AppraisalSubjectDetailsSection />
      <NearbyPropertiesChartsSection />
    </AppraisalReviewPage>
  );
};
