import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { HouseCanaryLogoFullColor } from '@hcs/design-system';

import { CobrandedLogo } from '../../features';

import styles from './PageHeader.module.css';

export interface HeaderDetail {
  dataHcName: string;
  label: ReactNode;
  value: ReactNode;
}
export interface PageHeaderProps {
  headerDetails: HeaderDetail[];
  className?: string;
  cobrandLogo?: boolean;
}

const dataHcName = 'page-header';
export const PageHeader = ({
  className,
  cobrandLogo,
  headerDetails,
}: PageHeaderProps) => {
  return (
    <div
      className={classNames(styles.PageHeader, className)}
      style={{
        height: '70px',
        boxSizing: 'border-box',
        padding: '20px 0 10px 0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      data-hc-name={dataHcName}
    >
      {cobrandLogo ? (
        <CobrandedLogo className={styles.CobrandedLogo} />
      ) : (
        <HouseCanaryLogoFullColor
          dataHcName={`${dataHcName}-logo-hc`}
          className={styles.CobrandedLogo}
        />
      )}
      <div className={styles.HeaderDetails}>
        {headerDetails.map((headerDetail) => {
          return (
            <div
              className={styles.HeaderDetail}
              key={`header-detail-${headerDetail.dataHcName}`}
            >
              <div data-hc-name={`${headerDetail.dataHcName}-label`}>
                {headerDetail.label}
              </div>
              <div
                data-hc-name={`${headerDetail.dataHcName}-value`}
                className={styles.HeaderDetailValue}
              >
                {headerDetail.value}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
