import React from 'react';

import { ListingStatus } from '@hcs/types';

import {
  AerialPropertyPhotoPage,
  BpoCompsPages,
  BpoMarketTransactionsPage,
  BpoSubjectDetailsPage,
  ConclusionContinuedPage,
  PexpSubjectCommentsPage,
  PropertyReportCoverPage,
  PropertyReportInspectionConditionPage,
  PropertyReportInspectionDetailsPage,
  PropsPexpSubjectDetailsPage,
  SubjectPhotosPage,
} from '../pdf-pages';
import { AddendumPages } from '../pdf-pages/BpoReports/AddendumPages';
import { BpoGlossaryContinuedPage } from '../pdf-pages/BpoReports/BpoGlossaryContinuedPage';
import { BpoGlossaryPage } from '../pdf-pages/BpoReports/BpoGlossaryPage';
import { BpoNeighborhoodMarketabilityPage } from '../pdf-pages/BpoReports/BpoNeighborhoodMarketabilityPage';
import { CompPhotoPages } from '../pdf-pages/BpoReports/CompPhotoPages';
import { ConclusionPage } from '../pdf-pages/BpoReports/ConclusionPage';
import { ConditionRatingPage } from '../pdf-pages/BpoReports/ConditionRatingPage';
import { DisclaimerPage } from '../pdf-pages/BpoReports/DisclaimerPage';
import { InspectionPhotosPages } from '../pdf-pages/BpoReports/InspectionPhotosPages';
import { LimitingConditionsPage } from '../pdf-pages/BpoReports/LimitingConditionsPage';
import { SignaturePage } from '../pdf-pages/BpoReports/SignaturePage';
import { Top4CompsMapPage } from '../pdf-pages/BpoReports/Top4CompsMapPage';

export interface ReportApiBPOPdfProps {
  propsPropsPexpSubjectDetailsPage?: PropsPexpSubjectDetailsPage;
}

// The customizable component is consumed by other templates
export const ReportApiBPOPdfWithProps = ({
  propsPropsPexpSubjectDetailsPage,
}: ReportApiBPOPdfProps) => {
  return (
    <>
      <PropertyReportCoverPage
        addOriginalAddressInputted
        showCondition
        valueLabel="Broker Price Opinion / Condition"
      />
      <BpoSubjectDetailsPage {...propsPropsPexpSubjectDetailsPage} />
      <BpoMarketTransactionsPage />
      <BpoNeighborhoodMarketabilityPage />
      <PexpSubjectCommentsPage />
      <BpoCompsPages compsStatus={ListingStatus.CLOSED} />
      <BpoCompsPages compsStatus={ListingStatus.ACTIVE} />
      <AerialPropertyPhotoPage />
      <Top4CompsMapPage />
      <ConclusionPage />
      <ConclusionContinuedPage />
      <PropertyReportInspectionDetailsPage />
      <PropertyReportInspectionConditionPage />
      <InspectionPhotosPages />
      <SubjectPhotosPage />
      <CompPhotoPages />
      <SignaturePage />
      <AddendumPages />
      <LimitingConditionsPage />
      <BpoGlossaryPage />
      <BpoGlossaryContinuedPage />
      <ConditionRatingPage />
      <DisclaimerPage />
    </>
  );
};

// The default PDF gets a lazy-import in the index file
export const ReportApiBPOPdf = () => {
  console.log('Rendering reportApi-BPO PDF Lower');
  return <ReportApiBPOPdfWithProps />;
};
