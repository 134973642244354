import { DocumentRoles, MonthsOfSupplyChartDocument } from '@hcs/types';
import { ReportApiReports } from '@hcs/types';

import { useSingleDocumentRole } from './useSingleDocumentRole';

export const useMonthsOfSupplyChartDocument = <
  Data extends ReportApiReports
>() => {
  return useSingleDocumentRole<MonthsOfSupplyChartDocument, Data>(
    DocumentRoles.MonthsOfSupplyChart
  );
};
