import React from 'react';

import { Section } from '@hcs/pdf/pdf-service';
import {
  CompFields,
  CompTypes,
  ListingStatus,
  PropertyStateFields,
} from '@hcs/types';

import {
  CompsCompareComp,
  CompsCompareSubject,
  CompsCompareTable,
} from '../../features/CompsCompareTable/CompsCompareTable';
import { CompsCompareTableValueRows } from '../../features/CompsCompareTable/CompsCompareTableValueRows';
import { useSubjectDocument } from '../../hooks/useSubjectDocument';
import { useSubjectFrontPhoto } from '../../hooks/useSubjectFrontPhoto';
import { CompsStatus, useTop4Comps } from '../../hooks/useTop4Comps';
import { useTop4CompsFrontPhotos } from '../../hooks/useTop4CompsFrontPhotos';

import { BpoReportPage } from './BpoReportPage';

export interface Top4CompsComparePageProps {
  fields?: (
    | CompFields
    | PropertyStateFields
    | CompFields[]
    | PropertyStateFields[]
  )[];
  compsStatus: CompsStatus;
}
export const Top4CompsComparePage = ({
  fields = [
    CompFields.distance,
    CompFields.locationSimilarity,
    PropertyStateFields.saleType,
    PropertyStateFields.lastCloseDate,
    PropertyStateFields.lastClosePrice,
    PropertyStateFields.currentListDate,
    PropertyStateFields.currentListingPrice,
    PropertyStateFields.bedrooms,
    PropertyStateFields.bathrooms,
    PropertyStateFields.livingArea,
    PropertyStateFields.lotSize,
    PropertyStateFields.yearBuilt,
    PropertyStateFields.currentStatus,
    PropertyStateFields.subdivisionName,
    PropertyStateFields.pool,
    PropertyStateFields.basementHas,
    PropertyStateFields.condition,
  ],
  compsStatus,
}: Top4CompsComparePageProps) => {
  // Do not render saleType field if comp listings are not closed because it's not relevant
  const configFields =
    compsStatus === ListingStatus.CLOSED
      ? fields
      : fields.filter((field) => field !== PropertyStateFields.saleType);
  const { comps } = useTop4Comps({ compType: CompTypes.Sold, compsStatus });
  const dataHcName = `bpo-${
    compsStatus === ListingStatus.ACTIVE ? `active` : 'sold'
  }-comps-page`;
  const title = `HouseCanary ${
    compsStatus === ListingStatus.ACTIVE ? `Active` : 'Sold'
  } Comparables`;
  const subjectDocument = useSubjectDocument();
  const subjectPhoto = useSubjectFrontPhoto();
  const compPhotos = useTop4CompsFrontPhotos(compsStatus);
  // Do not render page if no comps
  if (!comps?.length) return null;

  const subject: CompsCompareSubject = {
    type: 'subject',
    schema: subjectDocument.data,
    userPropertyAdjustments: subjectDocument.userPropertyAdjustments,
  };

  const comparedComps: CompsCompareComp[] = comps.map((comp) => ({
    type: 'comp',
    schema: comp.compSchema,
  }));
  return (
    <BpoReportPage dataHcName={dataHcName} key={dataHcName}>
      <Section
        sectionHeaderProps={{
          title,
        }}
        dataHcName={`${dataHcName}-section`}
      >
        <CompsCompareTable
          dataHcName={`${dataHcName}-table`}
          compType={CompTypes.Sold}
          subject={subject}
          comps={comparedComps}
          subjectPhotoUrl={subjectPhoto}
          compPhotoUrls={compPhotos}
          fields={configFields}
        />
        <CompsCompareTableValueRows
          subject={subject}
          comps={comparedComps}
          isSuggestedComps={true}
          compType={CompTypes.Sold}
        />
      </Section>
    </BpoReportPage>
  );
};
