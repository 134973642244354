import React from 'react';

import { Section } from '@hcs/pdf/pdf-service';
import { TwoColTable } from '@hcs/pdf/pdf-service';
import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import { PropertyStateFields } from '@hcs/types';
import { formatList, formatMissing } from '@hcs/utils';

import { useMlsListingSheetData } from '../../hooks/useMlsListingSheetData';

const dataHcName = 'listing-details-section';

export const ListingDetailsSection = () => {
  const { rentalListing, propertyStateCore, propertyStateMedia, reso } =
    useMlsListingSheetData();
  const { listingId } =
    (rentalListing
      ? propertyStateCore.propertyState?.listingDetailsRental
      : propertyStateCore.propertyState?.listingDetailsSale) || {};
  return (
    <Section
      dataHcName={dataHcName}
      sectionHeaderProps={{ title: 'List Details' }}
      compact
    >
      <TwoColTable
        smallLabels
        compact
        dataHcName={`${dataHcName}-table`}
        data={[
          {
            dataHcName: 'mls-number',
            label: 'MLS #',
            value: formatMissing(listingId),
          },
          {
            dataHcName: 'mls-name',
            label: 'MLS Name',
            value: formatMissing(propertyStateMedia.mls?.name),
          },
          PropertyStateFields.currentStatus,
          PropertyStateFields.currentListDate,
          PropertyStateFields.currentListingPrice,
          rentalListing
            ? PropertyStateFields.lastClosePriceRental
            : PropertyStateFields.lastClosePrice,
          rentalListing
            ? PropertyStateFields.daysOnMarketMlsRental
            : PropertyStateFields.daysOnMarketMls,
          PropertyStateFields.currentDistressed,
          {
            dataHcName: 'finance-terms',
            label: 'Finance Terms',
            value: formatList(reso?.listingDetails?.listingTerms),
          },
        ].map((field) => {
          if (typeof field === 'string') {
            const { Display, label } = PROPERTY_STATE_FIELD_CONFIGS[field];
            return {
              dataHcName: `${dataHcName}-${field}`,
              value: <Display propertyStateArgs={propertyStateCore} />,
              label,
            };
          } else {
            return field;
          }
        })}
      />
    </Section>
  );
};
