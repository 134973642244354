import { AppraisalCompDocument, DocumentRoles } from '@hcs/types';
import { ReportApiReports } from '@hcs/types';

import { useDocumentRole } from './useDocumentRole';

export const useAppraisalCompDocuments = <Data extends ReportApiReports>() => {
  const compDocuments = useDocumentRole<AppraisalCompDocument, Data>(
    DocumentRoles.AppraisalComp
  );
  return compDocuments
    .map((c) => {
      if (!c.data.similarity.levelAdjusted && c.data.similarity.level) {
        c.data.similarity.levelAdjusted = c.data.similarity.level;
        c.data.similarity.scoreAdjusted = c.data.similarity.score;
      }
      return c;
    })
    .sort((a, b) => {
      // Similarity is Primary Sort
      if (
        (a.data.similarity.scoreAdjusted || 0) >
        (b.data.similarity.scoreAdjusted || 0)
      ) {
        return -1;
      } else if (
        (a.data.similarity.scoreAdjusted || 0) <
        (b.data.similarity.scoreAdjusted || 0)
      ) {
        return 1;
      }
      // Distance is secondary sort
      if ((a.data.distance || 0) > (b.data.distance || 0)) {
        return -1;
      } else if ((a.data.distance || 0) < (b.data.distance || 0)) {
        return -1;
      }

      return 0;
    });
};
