import { useMemo } from 'react';

import {
  AppraisalCompDocument,
  CompDocument,
  CompId,
  HcSuggestedCompDocument,
  SimilarityLevel,
} from '@hcs/types';

import { useAppraisalCompDocuments } from '../hooks/useAppraisalCompDocuments';
import { useCompsForAppraisalCompare } from '../hooks/useCompsForAppraisalCompare';

const calcOverlap = (
  compsA: (CompDocument | HcSuggestedCompDocument | AppraisalCompDocument)[],
  compsB: (CompDocument | HcSuggestedCompDocument | AppraisalCompDocument)[]
) => {
  const lookupA: Record<CompId, boolean> = {};
  const overlap: Record<CompId, boolean> = {};
  compsA.forEach((comp) => {
    lookupA[comp.data.compID] = true;
  });
  compsB.forEach((comp) => {
    if (lookupA[comp.data.compID]) {
      overlap[comp.data.compID] = true;
    }
  });
  return overlap;
};
const calcStats = (
  compDocs: (CompDocument | HcSuggestedCompDocument | AppraisalCompDocument)[]
) => {
  let simSum = 0;
  let distSum = 0;
  let highSimComps = 0;
  compDocs.forEach((compDoc) => {
    if (compDoc.data.similarity.levelAdjusted === SimilarityLevel.High) {
      highSimComps++;
    }
    simSum += compDoc.data.similarity.scoreAdjusted || 0;
    distSum += compDoc.data.distance || 0;
  });
  return {
    highSimComps,
    avgSimilarity: simSum / compDocs.length,
    avgDistance: distSum / compDocs.length,
  };
};

export const useAppraisalCompStats = () => {
  const hcSuggestedCompDocs = useCompsForAppraisalCompare();
  const appraisalCompDocs = useAppraisalCompDocuments();
  return useMemo(() => {
    return {
      hc: calcStats(hcSuggestedCompDocs),
      appraisal: calcStats(appraisalCompDocs),
      overlap: calcOverlap(hcSuggestedCompDocs, appraisalCompDocs),
    };
  }, [hcSuggestedCompDocs, appraisalCompDocs]);
};
