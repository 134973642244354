import React from 'react';

import { Section } from '@hcs/pdf/pdf-service';

import { AppraisalReviewPage } from '../..//pdf-pages/AppraisalReviewReports/AppraisalReviewPage';
import { AppraisalReviewCompsMap } from '../../features/AppraisalReviewCompsMap';

const dataHcName = `appraisal-review-comps-map-page`;
export const AppraisalReviewMapPage = () => {
  return (
    <AppraisalReviewPage dataHcName={dataHcName}>
      <Section
        sectionHeaderProps={{
          title: 'Comp Selection',
        }}
        dataHcName={`${dataHcName}-section`}
      >
        <AppraisalReviewCompsMap />
      </Section>
    </AppraisalReviewPage>
  );
};
