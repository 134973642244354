import { AppraisalCompPhotosDocument, DocumentRoles } from '@hcs/types';
import { ReportApiReports } from '@hcs/types';

import { useDocumentRole } from './useDocumentRole';

export const useAppraisalCompPhotosDocuments = <
  Data extends ReportApiReports
>() => {
  return useDocumentRole<AppraisalCompPhotosDocument, Data>(
    DocumentRoles.AppraisalCompPhotos
  );
};
