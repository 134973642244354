import React from 'react';

import { HomeCircleIcon } from '@hcs/design-system';

import styles from './SimilarPropertiesChart.module.css';

const dataHcName = 'similar-properties-chart-legend';

export const SimilarPropertiesChartLegend = () => {
  return (
    <div className={styles.Legend}>
      <div className={styles.LegendItem}>
        <HomeCircleIcon
          height={20}
          width={20}
          className={styles.AppraisalIcon}
          dataHcName={`${dataHcName}-subject`}
        />{' '}
        HouseCanary Value
      </div>
    </div>
  );
};
