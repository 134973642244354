import React from 'react';

import { Section } from '@hcs/pdf/pdf-service';

import { BpoReportPage } from './BpoReportPage';

export const DisclaimerPage = () => {
  return (
    <BpoReportPage dataHcName="disclaimer-page">
      <Section
        sectionHeaderProps={{
          title: 'Limitations & Disclaimer',
        }}
        dataHcName="disclaimer-section"
      >
        <p>
          1. This Broker Price Opinion Report (BPO) is merely an estimate of the
          probable selling price of the specified property in its condition as
          of the date of preparation of the BPO by the broker. This BPO is based
          on then-available market data and property condition as determined by
          the indicated property “inspection type” (either exterior only or
          interior) and the broker’s professional judgment. In preparing this
          BPO, the broker has relied on readily-available information, such as
          photos of the exterior or interior of the home as determined by the
          property “inspection type” specified at the time of the order, and has
          not conducted a detailed inspection of the property, a title search,
          or a survey. The accuracy of this BPO may vary significantly based on
          changing market conditions and other factors.
        </p>
        <p>
          2. This BPO is not intended for use in as any guarantee of value or
          condition of the subject property, and it is provided solely to assist
          the client in evaluating the subject property. This BPO is not an
          appraisal or certified valuation developed by a licensed appraiser
          under the Uniform Standards of Professional Appraisal Practice
          (USPAP), and it should not be construed, considered, or used as such.
          This BPO should not be used in lieu of an appraisal for any purpose
          for which an appraisal is required by law or regulation. This BPO
          should not be used as the primary basis to determine the value of a
          parcel of or interest in real property for a mortgage loan origination
          decision. This BPO does not guarantee that the property will sell for
          the opinion of its price or within any specific period.
        </p>
        <p>
          3. This BPO is intended solely for the use of the client identified
          herein for its general business informational purposes. This BPO
          should not be used by any other party. If you are not the identified
          client, you are not authorized to access or use this BPO.
        </p>
        <p>
          4. The broker has used reasonable efforts to verify the accuracy of
          the information but makes no representations or warranties as to its
          completeness or accuracy. Neither House Canary nor the broker
          providing this BPO provides any opinion about the title to the
          specified property, including whether such title is good or
          marketable. The broker is not responsible for any errors, omissions,
          or inaccuracies in the information provided in this BPO. This BPO does
          not account for certain factors that could influence the actual market
          value, including without limitation needed repairs, deterioration,
          toxic substances, environmental hazards, structural defects, or
          pending disputes or litigation.{' '}
        </p>
        <p>
          5. This BPO is not intended to provide legal, financial, investing or
          tax advice, or to recommend any course of action by the client or any
          other person or party. The client should consult with appropriate
          professionals for any such advice. This BPO and its acceptance or use
          by client creates no advisory, fiduciary or other relationship between
          client and House Canary or the broker providing this BPO. Neither
          House Canary nor the broker have any obligation nor are under any
          expectation to provide any testimony or statement, or appear in any
          proceeding that would be related to an appraisal of the specified
          property, or for any other purpose, except as required by law.
        </p>
        <p>
          6 This BPO comprises the confidential information of House Canary and
          is intended to be disclosed to and used solely by the specified
          client. It may not be reproduced, distributed, or used for any other
          purpose without the express written consent of House Canary.{' '}
        </p>
        <p>
          7. HOUSE CANARY AND THE BROKER PROVIDING THIS BPO EACH EXPRESSLY
          DISCLAIM ANY AND ALL LIABILITY FOR ANY DAMAGES, LOSSES, OR CLAIMS
          ARISING FROM USE OF OR RELIANCE ON THIS BPO. THIS INCLUDES, BUT IS NOT
          LIMITED TO, ANY ECONOMIC LOSS, LOSS OF PROFITS, OR ANY OTHER
          CONSEQUENTIAL, INDIRECT, SPECIAL, OR PUNITIVE DAMAGES. HOUSE CANARY
          AND THE BROKER EACH MAKE NO WARRANTIES, EITHER EXPRESS OR IMPLIED,
          REGARDING THE ACCURACY, COMPLETENESS, OR RELIABILITY OF THE
          INFORMATION PROVIDED IN THIS BPO. ALL INFORMATION HEREIN IS PROVIDED
          “AS IS.”
        </p>
        <p>
          8. THE CLIENT AGREES TO DEFEND, INDEMNIFY, AND HOLD HARMLESS EACH OF
          HOUSE CANARY AND THE BROKER FROM AND AGAINST ANY AND ALL CLAIMS,
          LIABILITIES, DAMAGES, LOSSES, OR EXPENSES (INCLUDING REASONABLE
          ATTORNEYS’ FEES) ARISING FROM OR IN CONNECTION WITH ANY USE OF THE BPO
          BY CLIENT OR ANY THIRD PARTY TO WHOM CLIENT MAY PROVIDE ACCESS TO THE
          BPO OR ANY PORTION OF ITS CONTENTS.
        </p>
      </Section>
    </BpoReportPage>
  );
};
