import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { NoContent, NoImagesIcon } from '@hcs/design-system';
import { useComponentDidMount, useVisibleInOverflow } from '@hcs/hooks';
import { PhotoItem } from '@hcs/types';

import styles from './PhotosList.module.css';

type PhotoListItemProps = {
  item: PhotoItem;
  pageIndex: number;
  itemIndex: number;
  onItemOverflow?: (nextPageStartIndex: number) => void;
};

export type PhotosListProps = {
  list: PhotoItem[];
  pageIndex: number;
  onItemOverflow?: (nextPageStartIndex: number) => void;
};

export const PhotosList = ({
  list = [],
  pageIndex,
  onItemOverflow,
}: PhotosListProps) => {
  return (
    <>
      {list.map((item, idx) => (
        <PhotoListItem
          key={idx}
          item={item}
          pageIndex={pageIndex}
          itemIndex={idx}
          onItemOverflow={onItemOverflow}
        />
      ))}
    </>
  );
};

const PhotoListItem = ({
  item,
  itemIndex,
  onItemOverflow,
}: PhotoListItemProps) => {
  const PhotoListItemRef = useRef<HTMLDivElement>(null);
  const { visibleFull } = useVisibleInOverflow(PhotoListItemRef);
  const [isMounted, setIsMounted] = useState<boolean>(false);

  useComponentDidMount(() => setIsMounted(true));
  useEffect(() => {
    if (!visibleFull && isMounted) {
      onItemOverflow?.(itemIndex);
    }
  }, [visibleFull, isMounted, itemIndex, onItemOverflow]);

  return (
    <div
      className={classNames(styles.PhotoListItemContainer, {
        [styles.hidden]: !visibleFull && isMounted,
      })}
      ref={PhotoListItemRef}
      key={itemIndex}
    >
      <div className={styles.Photo}>
        {item?.imgUrl ? (
          <img src={item?.imgUrl} alt="House" className={styles.PhotoImg} />
        ) : (
          <NoContent dataHcName="inspection-photo-null" Icon={NoImagesIcon}>
            <span>No Photos Provided</span>
          </NoContent>
        )}
      </div>
      <div className={styles.Details}>
        <div>{item?.predictionDescription}</div>
        <div className={styles?.DateContainer}>
          <div>Date</div>
          <div className={styles.Date}>{item?.date}</div>
        </div>
      </div>
    </div>
  );
};
