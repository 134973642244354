import React, { useCallback, useMemo, useState } from 'react';

import { PhotosList, Section } from '@hcs/pdf/pdf-service';
import { PhotoItem } from '@hcs/types';

import { useInspectionPhotosDocument } from '../../hooks/useInspectionPhotosDocument';

import { BpoReportPage } from './BpoReportPage';

import styles from './InspectionPhotosPages.module.css';

const dataHcName = 'inspection-photos-page';

interface Props {
  pageIndex: number;
  photosList: PhotoItem[];
}

const InspectionPhotosPage = ({ pageIndex, photosList }: Props) => {
  const [nextPageStartItemIndex, setNextPageStartItemIndex] = useState<
    number | null
  >(null);

  const onItemOverflow = useCallback(
    (itemIndex: number) => {
      if (
        nextPageStartItemIndex === null ||
        (itemIndex > 0 && itemIndex < nextPageStartItemIndex)
      ) {
        setNextPageStartItemIndex(itemIndex);
      }
    },
    [nextPageStartItemIndex]
  );

  return (
    <>
      <BpoReportPage dataHcName={dataHcName}>
        <Section
          className={styles.InspectionPhotosSection}
          sectionHeaderProps={{
            title: `Inspection Photos${pageIndex > 0 ? ' Continued' : ''}`,
          }}
          dataHcName="inspection-section"
        >
          <PhotosList
            list={photosList}
            pageIndex={pageIndex}
            onItemOverflow={onItemOverflow}
          />
        </Section>
      </BpoReportPage>
      {nextPageStartItemIndex !== null && (
        <InspectionPhotosPage
          pageIndex={pageIndex + 1}
          photosList={photosList.slice(nextPageStartItemIndex)}
        />
      )}
    </>
  );
};

export const InspectionPhotosPages = () => {
  const inspectionPhotosDocument = useInspectionPhotosDocument();
  const listingPhoto = inspectionPhotosDocument?.data?.listingPhotos?.[0];
  const images = listingPhoto?.media?.images;
  const photosList: PhotoItem[] = useMemo(() => {
    const imagesList = images?.map((image) => ({
      imgUrl: image?.url,
      predictionDescription:
        image?.derived?.classification?.predictionDescription,
      date: image?.date,
    }));
    return imagesList || [];
  }, [images]);

  return <InspectionPhotosPage pageIndex={0} photosList={photosList} />;
};
