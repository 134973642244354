import React, { ReactNode } from 'react';

import { HouseCanaryLogomarkFullColor } from '@hcs/design-system';

import styles from './PageHeaderProduct.module.css';

interface Props {
  leftContentLabel?: ReactNode;
  leftContentValue?: ReactNode;
  children?: ReactNode;
  productName: string;
}

const dataHcName = 'page-header-product';
export const PageHeaderProduct = ({
  leftContentLabel,
  leftContentValue,
  children,
  productName,
}: Props) => {
  return (
    <div data-hc-name={dataHcName} className={styles.PageHeaderProduct}>
      <div data-hc-name={`${dataHcName}-content-left`}>
        {leftContentLabel && (
          <span
            data-hc-name={`${dataHcName}-left-content-label`}
            className={styles.ContentLabel}
          >
            {leftContentLabel}
          </span>
        )}
        {leftContentValue && (
          <span
            data-hc-name={`${dataHcName}-left-content-value`}
            className={styles.ContentValue}
          >
            {leftContentValue}
          </span>
        )}
        {children}
      </div>
      <div className={styles.ContentRight}>
        <HouseCanaryLogomarkFullColor
          dataHcName={`${dataHcName}-logo-mark`}
          className={styles.Logo}
        />
        <h1
          data-hc-name={`${dataHcName}-product-name`}
          className={styles.ProductName}
        >
          {productName}
        </h1>
      </div>
    </div>
  );
};
