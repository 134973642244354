import React, { ReactNode } from 'react';

import { CoverMetadata, CoverPage } from '@hcs/pdf/pdf-service';
import { reportFeaturesSupportedAny } from '@hcs/report-api';
import { ReportFeatures } from '@hcs/types';
import { PropertyReports } from '@hcs/types';
import { formatDateShort, formatMissing, formatMoney } from '@hcs/utils';

import { useReport } from '../..//hooks/useReport';
import { useReportConfig } from '../..//hooks/useReportConfig';
import { useReportValue } from '../..//hooks/useReportValue';
import { useInspectionDocument } from '../../hooks/useInspectionDocument';
import { useSubjectDocument } from '../../hooks/useSubjectDocument';
import { useSubjectFrontPhoto } from '../../hooks/useSubjectFrontPhoto';

export interface PropertyReportCoverPageProps {
  metadata?: CoverMetadata[];
  customContent?: ReactNode;
  valueLabel?: ReactNode;
  addOriginalAddressInputted?: boolean;
  showCondition?: boolean;
}

const dataHcName = 'property-report-cover-page';
export const PropertyReportCoverPage = <D extends PropertyReports>({
  metadata: metadataProp,
  customContent,
  valueLabel = 'Value Estimate',
  addOriginalAddressInputted,
  showCondition,
}: PropertyReportCoverPageProps) => {
  const reportConfig = useReportConfig();
  const { productName, reportName } = reportConfig;
  const report = useReport();
  const reportValue = useReportValue();
  const subjectFrontPhoto = useSubjectFrontPhoto<D>();
  const subjectDocument = useSubjectDocument<D>();
  const inspectionDoc = useInspectionDocument();
  const conditionValue = formatMissing(inspectionDoc?.data.condition);
  const metadata: CoverMetadata[] = [
    {
      dataHcName: 'value',
      label: valueLabel,
      value: (
        <>
          {formatMoney(reportValue?.selectedValue?.value.value)}
          {showCondition && <span> / {conditionValue}</span>}
        </>
      ),
    },
    {
      label: 'Effective Date',
      value: formatDateShort(report.effectiveDate),
      dataHcName: 'effective-date',
    },
  ];
  if (report.clientID) {
    metadata.push({
      dataHcName: 'client-file-id',
      label: 'Client File ID',
      value: report.clientID,
    });
  }
  if (metadataProp?.length) {
    metadata.concat(metadataProp);
  }

  return (
    <CoverPage
      dataHcName={dataHcName}
      customContent={customContent}
      title={productName}
      description={reportName}
      // todo: originalAddressInputted is not defined
      originalAddressInputted={
        addOriginalAddressInputted
          ? subjectDocument.data.propertyState.location
          : undefined
      }
      location={subjectDocument.data.propertyState.location}
      photoUrl={subjectFrontPhoto}
      metadata={metadata}
      showPersonalization={reportFeaturesSupportedAny(reportConfig, [
        ReportFeatures.CobrandedReport,
      ])}
    />
  );
};
