import React from 'react';

import { Cobranding } from '../../components';
import { usePdfPersonalization } from '../../hooks';

const dataHcName = 'personalization';
export const Personalization = () => {
  const personalization = usePdfPersonalization();
  return (
    personalization && (
      <Cobranding dataHcName={dataHcName} {...personalization} />
    )
  );
};
