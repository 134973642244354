import { DaysOnMarketChartDocument, DocumentRoles } from '@hcs/types';
import { ReportApiReports } from '@hcs/types';

import { useSingleDocumentRole } from './useSingleDocumentRole';

export const useDaysOnMarketChartDocument = <
  Data extends ReportApiReports
>() => {
  return useSingleDocumentRole<DaysOnMarketChartDocument, Data>(
    DocumentRoles.DaysOnMarketChart
  );
};
