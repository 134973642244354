import React, { ReactNode } from 'react';

import { Page } from '@hcs/pdf/pdf-service';

import { AppraisalReviewPageHeader } from '../..//pdf-pages/AppraisalReviewReports/AppraisalReviewPageHeader';
import { PropertyReportPageFooter } from '../PropertyReports';

interface Props {
  dataHcName: string;
  children: ReactNode;
}
export const AppraisalReviewPage = ({ dataHcName, children }: Props) => {
  return (
    <Page
      dataHcName={dataHcName}
      header={<AppraisalReviewPageHeader />}
      footer={<PropertyReportPageFooter simpleFooter />}
    >
      {children}
    </Page>
  );
};
