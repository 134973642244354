import React from 'react';

import {
  InspectionDetailsSection,
  InspectionPropertyDetailsSection,
} from '../../features';
import { BpoReportPage } from '../BpoReports';

export const PropertyReportInspectionDetailsPage = () => {
  return (
    <BpoReportPage dataHcName="inspection-ext">
      <InspectionDetailsSection />
      <InspectionPropertyDetailsSection />
    </BpoReportPage>
  );
};
