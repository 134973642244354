import React from 'react';

import { Section } from '@hcs/pdf/pdf-service';
import { NULL_VALUE } from '@hcs/utils';

import { useSubjectDocument } from '../../hooks/useSubjectDocument';

const dataHcName = 'subject-comments-section';
export const SubjectCommentsSection = () => {
  const subjectDoc = useSubjectDocument();

  if (!subjectDoc) {
    throw new Error('Missing Subject Document');
  }

  return (
    <Section
      dataHcName={dataHcName}
      sectionHeaderProps={{
        title: 'Comments',
      }}
    >
      {subjectDoc.data.comments
        ? subjectDoc.data.comments.split('\n').map((str, i) => {
            if (!str.length) return <br key={i} />;
            return <div key={i}>{str}</div>;
          })
        : NULL_VALUE}
    </Section>
  );
};
