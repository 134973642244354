import { sortCompDocuments } from '@hcs/report-api';
import { CompDocument, DocumentRoles } from '@hcs/types';
import { ReportApiReports } from '@hcs/types';

import { useDocumentRole } from './useDocumentRole';

export const useCompDocuments = <Data extends ReportApiReports>() => {
  const compDocuments = useDocumentRole<CompDocument, Data>(DocumentRoles.Comp);
  compDocuments.sort(sortCompDocuments);
  return compDocuments;
};
