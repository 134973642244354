import React from 'react';

import styles from './BpoSignatureDisclosureSection.module.css';

export const BpoSignatureDisclosureSection = () => {
  return (
    <div className={styles.DisclaimerContainer}>
      <p>
        <strong>Disclosure</strong>
      </p>
      <p>
        By confirming the above and submitting the report, the above signed
        hereby certifies and agrees that:
      </p>
      <p>
        1) I personally selected comparables, and determined the price
        conclusion;
      </p>
      <p>
        2) To the best of my knowledge, the statements of fact contained in this
        report are true and correct.
      </p>
      <p>
        3) The reported analyses, opinions, and conclusions are my personal,
        impartial, and unbiased professional analyses, opinions, and
        conclusions.
      </p>
      <p>
        4) I have no present or prospective interest in the property that is the
        subject of this report and no personal interest with respect to the
        parties involved.
      </p>
      <p>
        5) I have no bias with respect to the property that is the subject of
        this report or to the parties involved with this assignment.
      </p>
      <p>
        6) My compensation for completing this assignment is not contingent upon
        the development or reporting of a predetermined price point.
      </p>
      <p>
        7) I did not base, either partially or completely, my analysis and/or
        opinion and conclusions in this report on race, color, religion, sex,
        age, marital status, handicap, familial status, or national origin of
        either the prospective owners or occupants of the subject property or of
        the present owners or occupants of the properties in the vicinity of the
        subject property or on any other basis prohibited by law.
      </p>
      <p>
        8) I maintain errors and omissions insurance, to the extent required by
        state law, for all liability associated with the preparation of this
        report. HouseCanary forms or their contents shall, under no
        circumstances, be published, copied, replicated, or mimicked. The
        intended purpose of this report is to assist the account holder in
        performing required due diligence and in making decisions within the
        scope of applicable statutory and regulatory requirements. This document
        is provided only for the use by the account holder and not any other
        party, is not intended as any guarantee of value or price opinion and/or
        condition of the subject property and should not be relied on as such.
        If this document is determined to be negligently prepared, incorrect, or
        unfit for its authorized use, the sole liability of HouseCanary shall be
        the promptly refund of the total fee expended by the account holder for
        this report or to replace it at no charge to the account holder. In no
        event shall HouseCanary be responsible to the account holder for any
        indirect or consequential damages whatsoever. This warranty is in lieu
        of all other warranties, express or implied, except where otherwise
        required by law. The account holder shall notify HouseCanary within
        thirty (30) days of this report's delivery to the account holder if it
        believes that this document is negligently prepared, incorrect, or unfit
        for its authorized use. This report is not an appraisal. Unless the
        licensee who prepared this report is also a licensed appraiser, the
        report is not intended to meet the requirements of the Uniform Standards
        of Appraisal Practice (USPAP). The report is considered a competitive
        market analysis or letter opinion and is not intended as an appraisal.
      </p>
    </div>
  );
};
