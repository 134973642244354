import React from 'react';

import { SubjectCommentsSection } from '../../features/SubjectCommentsSection';
import { useSubjectDocument } from '../../hooks/useSubjectDocument';
import { PropertyReportPage } from '../PropertyReports/PropertyReportPage';

const dataHcName = 'pexp-subject-comments-page';
export const PexpSubjectCommentsPage = () => {
  const subjectDoc = useSubjectDocument();

  if (!subjectDoc) {
    throw new Error('Missing Subject Document');
  }

  if (!subjectDoc.data.comments) return null;

  return (
    <PropertyReportPage dataHcName={dataHcName}>
      <SubjectCommentsSection />
    </PropertyReportPage>
  );
};
