import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { HouseCanaryLogoFullColor } from '@hcs/design-system';

import styles from './PageFooter.module.css';

interface Props {
  className?: string;
  title: string;
  leftContent: ReactNode;
  simpleFooter?: boolean;
}

const dataHcName = 'page-footer';
export const PageFooter = ({
  className,
  title,
  leftContent,
  simpleFooter,
}: Props) => {
  return (
    <div
      className={classNames(styles.PageFooter, className, {
        [styles.simple]: simpleFooter,
      })}
      data-hc-name={dataHcName}
    >
      <span
        data-hc-name={`${dataHcName}-left-content`}
        className={styles.LeftContent}
      >
        {leftContent}
      </span>
      {!simpleFooter && (
        <span className={styles.CenterContent}>
          <span data-hc-name={`${dataHcName}-title`}>{title} powered by</span>
          <HouseCanaryLogoFullColor
            width={'134px'}
            dataHcName={`${dataHcName}-footer-logo`}
          />
        </span>
      )}
      <span
        data-hc-name={`${dataHcName}-right`}
        className={styles.RightContent}
      >
        Pg.&nbsp;
      </span>
    </div>
  );
};
