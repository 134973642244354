import React, { ReactNode } from 'react';

import { Page } from '@hcs/pdf/pdf-service';

import { BpoReportPageFooter, BpoReportPageHeader } from '.';

interface Props {
  dataHcName: string;
  children: ReactNode;
}
export const BpoReportPage = ({ dataHcName, children }: Props) => {
  return (
    <Page
      dataHcName={dataHcName}
      header={<BpoReportPageHeader />}
      footer={<BpoReportPageFooter />}
    >
      {children}
    </Page>
  );
};
