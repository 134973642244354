import React from 'react';

import { BpoSubjectDetailsSection } from '../../features/BpoSubjectDetailsSection';
import { SubjectConditionSection } from '../../features/SubjectConditionSection';
import { SubjectDetailsSectionProps } from '../../features/SubjectDetailsSection';
import { SubjectValueConclusion } from '../../features/SubjectValueConclusion';
import { SubjectValueSectionProps } from '../../features/SubjectValueSection/SubjectValueSection';

import { BpoReportPage } from './BpoReportPage';

export interface PropsBpoSubjectDetailsPage {
  propsSubjectDetailsSection?: SubjectDetailsSectionProps;
  propsSubjectValueSection?: SubjectValueSectionProps;
}
const dataHcName = 'bpo-subject-details-page';
export const BpoSubjectDetailsPage = ({
  propsSubjectValueSection,
}: PropsBpoSubjectDetailsPage) => {
  return (
    <BpoReportPage dataHcName={dataHcName}>
      <SubjectValueConclusion {...propsSubjectValueSection} />
      <BpoSubjectDetailsSection />
      <SubjectConditionSection />
    </BpoReportPage>
  );
};
