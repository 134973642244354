import React from 'react';

import { Section } from '../Section';

export interface DataSourcesProps {
  productName: string;
  className?: string;
}

const dataHcName = 'data-sources';
export const DataSources = ({ className, productName }: DataSourcesProps) => {
  return (
    <Section
      dataHcName={dataHcName}
      sectionHeaderProps={{
        title: 'Data Sources',
      }}
      className={className}
    >
      <p data-hc-name={`${dataHcName}-text`}>
        HouseCanary accesses up-to-date data from county recorders and local
        Multiple Listing Service (MLS). Recency of certain data is reflected by
        the effective date on the report. We use this data combined with
        HouseCanary proprietary analytics to bring you the most comprehensive,
        simple and accurate {productName} for every property.
      </p>
    </Section>
  );
};
