import React from 'react';

import { DataSources, Section } from '@hcs/pdf/pdf-service';

import { BpoReportPage } from './BpoReportPage';

export const LimitingConditionsPage = () => {
  return (
    <BpoReportPage dataHcName="limiting-conditions-page">
      <Section
        sectionHeaderProps={{
          title: 'Limiting Conditions',
        }}
        dataHcName="limiting-conditions-section"
      >
        <p>
          HouseCanary will not be responsible for matters of a legal nature that
          affect either the property being valued or the title to it, assumes
          that the title is good and marketable and will not render any opinions
          about the title.
        </p>
        <p>
          HouseCanary has relied on information for MLS and/or Public Records to
          report the approximate physical characteristics of the subject
          improvements. The physical characteristics, as reported, are assumed
          to be accurate for the purposes of this analysis and conclusions.
        </p>
        <p>
          Neither HouseCanary nor the inspector is a surveyor; as such make no
          guarantee, express or implied, regarding the flood determination
          presented in this report.
        </p>
        <p>
          Neither HouseCanary nor the inspector will give testimony or appear in
          court related to this valuation of the property in question
        </p>
        <p>
          HouseCanary has noted in this valuation report any adverse conditions
          (such as needed repairs, deterioration, the presence of hazardous
          wastes, toxic substances, etc.) observed during the inspection of the
          subject property or that HouseCanary became aware of during the
          research involved in performing this valuation. Unless otherwise
          stated in this report, HouseCanary has no knowledge of any hidden or
          unapparent physical deficiencies or adverse conditions of the property
          (such as, but not limited to, needed repairs, deterioration, the
          presence of hazardous wastes, toxic substances, adverse environmental
          conditions, etc.) that would make the property less valuable, and has
          assumed that there are no such conditions and makes no guarantees or
          warranties, express or implied. HouseCanary will not be responsible
          for any such conditions that do exist or for any engineering or
          testing that might be required to discover whether such conditions
          exist. Because HouseCanary is not an expert in the field of
          environmental hazards, this valuation report must not be considered as
          an environmental assessment of the property
        </p>
      </Section>
      <DataSources productName="Broker Price Opinion" />
    </BpoReportPage>
  );
};
