import React from 'react';

import { ListingStatus } from '@hcs/types';

import { CompPhotosPage } from './CompPhotosPage';

export const CompPhotoPages = () => {
  return (
    <>
      <CompPhotosPage compsStatus={ListingStatus.ACTIVE} />
      <CompPhotosPage compsStatus={ListingStatus.CLOSED} />
    </>
  );
};
