import React from 'react';

import { BpoConclusionContinuedSection } from '../../features/BpoConclusionContinuedSection';

import { BpoReportPage } from './BpoReportPage';

const dataHcName = 'conclusion-continued-page';
export const ConclusionContinuedPage = () => {
  return (
    <BpoReportPage dataHcName={dataHcName}>
      <BpoConclusionContinuedSection />
    </BpoReportPage>
  );
};
