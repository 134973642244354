import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { SectionHeader, SectionHeaderProps } from '../SectionHeader';

import styles from './Section.module.css';

export interface SectionProps {
  dataHcName: string;
  className?: string;
  children: ReactNode;
  sectionHeaderProps?: Omit<SectionHeaderProps, 'dataHcName'>;
  compact?: boolean;
}

export const Section = ({
  dataHcName,
  className,
  children,
  sectionHeaderProps,
  compact,
}: SectionProps) => {
  return (
    <section
      className={classNames(styles.Section, className, {
        [styles.compact]: compact,
      })}
      data-hc-name={dataHcName}
    >
      {sectionHeaderProps && (
        <SectionHeader
          dataHcName={`${dataHcName}-header`}
          {...sectionHeaderProps}
        />
      )}
      {children}
    </section>
  );
};
