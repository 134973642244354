import React, { ReactNode } from 'react';

import { Page } from '@hcs/pdf/pdf-service';

import { PropertyReportPageFooter, PropertyReportPageHeader } from '.';

interface Props {
  dataHcName: string;
  children: ReactNode;
}
export const PropertyReportPage = ({ dataHcName, children }: Props) => {
  return (
    <Page
      dataHcName={dataHcName}
      header={<PropertyReportPageHeader />}
      footer={<PropertyReportPageFooter />}
    >
      {children}
    </Page>
  );
};
