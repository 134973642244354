import React from 'react';

import { PageFooter } from '@hcs/pdf/pdf-service';
import { PropertyReports } from '@hcs/types';
import { formatDateShort } from '@hcs/utils';

import { useReportConfig } from '../..//hooks/useReportConfig';
import { useReport } from '../../hooks/useReport';

const dataHcName = 'property-report-page-footer';
export const PropertyReportPageFooter = <D extends PropertyReports>({
  simpleFooter,
}: {
  simpleFooter?: boolean;
}) => {
  const { productName } = useReportConfig();
  const report = useReport<D>();
  return (
    <PageFooter
      title={productName}
      leftContent={
        <>
          <div data-hc-name={`${dataHcName}-order-date-label`}>
            Order Date:&nbsp;
          </div>
          <div data-hc-name={`${dataHcName}-order-date-value`}>
            {formatDateShort(report.createdAt)}
          </div>
        </>
      }
      simpleFooter={simpleFooter}
    />
  );
};
