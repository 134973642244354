import React from 'react';
import classNames from 'classnames';

import { Section } from '@hcs/pdf/pdf-service';

import { APPRAISAL_REVIEW_COMP_FIELDS } from '../..//constants/appraisalReview.constants';
import { AppraisalReviewCompStats } from '../..//features/AppraisalReviewCompStats';
import { CompsTable } from '../..//features/CompsTable';
import { useAppraisalCompDocuments } from '../..//hooks/useAppraisalCompDocuments';
import { useAppraisalCompStats } from '../..//hooks/useAppraisalCompStats';
import { useCompsForAppraisalCompare } from '../..//hooks/useCompsForAppraisalCompare';
import { useSubjectDocument } from '../..//hooks/useSubjectDocument';
import { AppraisalReviewPage } from '../..//pdf-pages/AppraisalReviewReports/AppraisalReviewPage';

import styles from './AppraisalReviewCompsSummaryPage.module.css';

const dataHcName = `appraisal-review-comps-summary-page`;
export const AppraisalReviewCompsSummaryPage = () => {
  const subjectDoc = useSubjectDocument();
  const hcComps = useCompsForAppraisalCompare();
  const appraisalCompDocs = useAppraisalCompDocuments();
  const { overlap } = useAppraisalCompStats();
  return (
    <AppraisalReviewPage dataHcName={dataHcName}>
      <AppraisalReviewCompStats />
      <Section
        sectionHeaderProps={{
          title: 'HouseCanary Suggested Comps',
        }}
        dataHcName={`${dataHcName}-section`}
      >
        <CompsTable
          highlight="hc"
          dataHcName={`${dataHcName}-table`}
          fields={APPRAISAL_REVIEW_COMP_FIELDS}
          subjectSchema={subjectDoc.data}
          rows={hcComps.map((compDoc) => {
            return {
              className: classNames({
                [styles.overlapComp]: overlap[compDoc.data.compID],
              }),
              compSchema: compDoc.data,
            };
          })}
        />
      </Section>
      <Section
        sectionHeaderProps={{
          title: 'Appraisal Selected Comps',
        }}
        dataHcName={`${dataHcName}-section`}
      >
        <CompsTable
          highlight="appraisal"
          dataHcName={`${dataHcName}-table`}
          fields={APPRAISAL_REVIEW_COMP_FIELDS}
          subjectSchema={subjectDoc.data}
          rows={appraisalCompDocs.map((compDoc) => {
            return {
              className: classNames({
                [styles.overlapComp]: overlap[compDoc.data.compID],
              }),
              compSchema: compDoc.data,
            };
          })}
        />
      </Section>
    </AppraisalReviewPage>
  );
};
