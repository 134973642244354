import React from 'react';

import { PropertyTwoColTableProps, TwoColTable } from '@hcs/pdf/pdf-service';
import { TwoColSection } from '@hcs/pdf/pdf-service';
import {
  ASSOCIATION_FEE_CONFIG,
  LIVING_AREA_CONFIG,
  LOT_SIZE_CONFIG,
  PROPERTY_STATE_FIELD_CONFIGS,
  PROPERTY_TYPE_CONFIG,
  ZONING_CONFIG,
} from '@hcs/property-state';
import {
  PropertyStateArgsCore,
  PropertyStateFields,
  PropertyStateType,
  PropertyTypeEnum,
} from '@hcs/types';
import {
  formatBoolYesNoUnknown,
  formatMissing,
  formatPropertyType,
  NULL_VALUE,
} from '@hcs/utils';

import { useSubjectDataSourcesDocument } from '../../hooks';
import { useInspectionDocument } from '../../hooks/useInspectionDocument';
import { useSubjectDocument } from '../../hooks/useSubjectDocument';

import styles from './BpoSubjectDetailsSection.module.css';

export interface BpoSubjectDetailsSectionProps {
  columnConfigsLeft?: PropertyTwoColTableProps['fields'];
  columnConfigsRight?: PropertyTwoColTableProps['fields'];
}

const dataHcName = 'bpo-subject-details-section';
export const BpoSubjectDetailsSection = () => {
  const inspectionDoc = useInspectionDocument();
  const subjectDoc = useSubjectDocument();
  const subjectDataSourcesDoc = useSubjectDataSourcesDocument();
  const propertyStateArgs: PropertyStateArgsCore = {
    propertyStateType: PropertyStateType.Core,
    propertyState: subjectDoc.data.propertyState,
  };
  const subjectPropertyType =
    PROPERTY_TYPE_CONFIG.formatValue(propertyStateArgs);
  const inspectPropertyType = formatPropertyType(
    inspectionDoc?.data?.propertyType as PropertyTypeEnum
  );
  const mlsPropertyType = formatPropertyType(
    subjectDataSourcesDoc?.data.mls?.propertyDetails?.propertyType
  );

  const beds = formatMissing(
    subjectDoc?.data.propertyState.propertyDetails?.bedrooms
  );
  const baths = formatMissing(
    subjectDoc?.data.propertyState.propertyDetails?.bathrooms?.totalProjected
  );
  const gla = LIVING_AREA_CONFIG.formatValue(propertyStateArgs);
  const lotSize = LOT_SIZE_CONFIG.formatValue(propertyStateArgs);
  const propertyViewable = formatBoolYesNoUnknown(
    inspectionDoc?.data.propertyViewable
  );
  const evidenceOfOccupancy = formatMissing(
    inspectionDoc?.data.occupiedEvidence.join(', ')
  );
  const occupied = formatMissing(inspectionDoc?.data.occupied);
  const occupancyType = formatMissing(inspectionDoc?.data.occupancyType);
  const zoning = ZONING_CONFIG.formatValue(propertyStateArgs);
  const commonElements = inspectionDoc?.data.commonElements || NULL_VALUE;
  const associationFee = ASSOCIATION_FEE_CONFIG.formatValue(propertyStateArgs);

  const legalDescription = formatMissing(
    subjectDoc?.data?.propertyState?.propertyDetails?.tax?.legalDescription
  );
  const specialAmenities = formatMissing(
    inspectionDoc?.data.specialAmenities?.join(', ')
  );
  const otherStructuresOnProperty =
    inspectionDoc?.data.otherStructuresOnProperty || NULL_VALUE;
  const nonResidentialUse = formatBoolYesNoUnknown(
    inspectionDoc?.data.nonResidentialUse.value
  );
  const otherNonResidentialUse = formatMissing(
    inspectionDoc?.data.otherNonResidentialUse
  );
  const viewFactorsValue = inspectionDoc?.data.viewFactors.value || NULL_VALUE;
  const viewFactorsImpact = formatMissing(
    inspectionDoc?.data.viewFactors.impact
  );
  const locationalInfluencesValue =
    inspectionDoc?.data.locationalInfluences.value || NULL_VALUE;
  const locationalInfluencesImpact = formatMissing(
    inspectionDoc?.data.locationalInfluences.impact
  );
  const adverseSiteConditions =
    inspectionDoc?.data.adverseSiteConditions || NULL_VALUE;
  const conformToNeighborhood = formatBoolYesNoUnknown(
    inspectionDoc?.data.conformToNeighborhood
  );
  const conformToNeighborhoodComment = formatMissing(
    inspectionDoc?.data.comment.conformToNeighborhood
  );

  const { Display: Apn, label: apnLabel } =
    PROPERTY_STATE_FIELD_CONFIGS[PropertyStateFields.apn];

  return (
    <>
      <TwoColSection
        dataHcName={dataHcName}
        sectionHeaderProps={{
          title: 'Subject',
          rightContent: {
            label: apnLabel,
            value: <Apn propertyStateArgs={propertyStateArgs} />,
          },
        }}
        contentLeft={
          <TwoColTable
            dataHcName={`${dataHcName}-table-right`}
            data={[
              {
                label: 'Property Type',
                value: (
                  <>
                    {subjectPropertyType && (
                      <div>{`(HC) ${subjectPropertyType}`}</div>
                    )}
                    {inspectPropertyType && (
                      <div>{`(Inspect) ${inspectPropertyType}`}</div>
                    )}
                    {mlsPropertyType && <div>{`(MLS) ${mlsPropertyType}`}</div>}
                  </>
                ),
                dataHcName: 'property-type',
              },
              {
                label: 'Beds / Baths',
                value: `${beds} Bd / ${baths} Ba`,
                dataHcName: 'beds-baths',
              },
              {
                label: 'GLA / Lot Size',
                value: `${gla} sqft / ${lotSize} sqft`,
                dataHcName: 'gla-log-size',
              },
              {
                label: 'Property Viewable',
                value: propertyViewable,
                dataHcName: 'property-viewable',
              },
              {
                label: 'Evidence of Occupancy',
                value: `${occupied} ${evidenceOfOccupancy}`,
                dataHcName: 'evidence-of-occupancy',
              },
              {
                label: 'Occupancy Type',
                value: occupancyType,
                dataHcName: 'occupancy-type',
              },
              {
                label: 'Zoning Classification/Description',
                value: zoning,
                dataHcName: 'zoning-classification-description',
              },
              {
                label: 'Common Elements',
                value: commonElements,
                dataHcName: 'common-elements',
              },
              {
                label: 'HOA Fees',
                value: associationFee,
                dataHcName: 'hoa-fees',
              },
            ]}
          />
        }
        contentRight={
          <TwoColTable
            dataHcName={`${dataHcName}-table-left`}
            data={[
              {
                label: 'Legal Description',
                value: legalDescription,
                dataHcName: 'legal-description',
              },
              {
                label: 'Special Amenities',
                value: specialAmenities,
                dataHcName: 'special-amenities',
              },
              {
                label: 'Other Structures on Property',
                value: otherStructuresOnProperty,
                dataHcName: 'other-structures-on-property',
              },
              {
                label: 'Current Use',
                value: subjectPropertyType,
                dataHcName: 'current-use',
              },
              {
                label: 'Projected Use',
                value: subjectPropertyType,
                dataHcName: 'projected-use',
              },
              {
                label: 'Non-Residential Use',
                value: `${nonResidentialUse} / ${otherNonResidentialUse}`,
                dataHcName: 'non-residential-use',
              },
              {
                label: 'View Factors / Impact on Value',
                value: `${viewFactorsValue} / ${viewFactorsImpact}`,
                dataHcName: 'view-factors-impact-on-value',
              },
              {
                label: 'Locational Influences / Impact / Comment',
                value: `${locationalInfluencesValue} / ${locationalInfluencesImpact}`,
                dataHcName: 'locational-influences-impact-comment',
              },
              {
                label: 'Adverse Site Conditions',
                value: adverseSiteConditions,
                dataHcName: 'adverse-site-conditions',
              },
              {
                label: 'Conform to Neighborhood',
                value: `${conformToNeighborhood} / ${conformToNeighborhoodComment}`,
                dataHcName: 'other-structures-on-property',
              },
            ]}
          />
        }
      />
      <p className={styles.Disclaimer}>
        This property's zoning information is derived from land use code from
        Public Record
      </p>
    </>
  );
};
