import { DocumentRoles, ReconciliationCommentsDocument } from '@hcs/types';
import { ReportApiReports } from '@hcs/types';

import { useDocumentRole } from './useDocumentRole';

export const useReconciliationCommentsDocument = <
  Data extends ReportApiReports
>() => {
  return useDocumentRole<ReconciliationCommentsDocument, Data>(
    DocumentRoles.ReconciliationComments
  );
};
