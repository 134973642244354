import React from 'react';

import { Section } from '@hcs/pdf/pdf-service';

import { MarketRiskStats } from '../MarketRiskStats';

const dataHcName = 'market-risk-section';
export const MarketRiskSection = () => {
  return (
    <Section
      dataHcName={dataHcName}
      sectionHeaderProps={{ title: 'Market Risk' }}
    >
      <MarketRiskStats />
    </Section>
  );
};
