import React, { ReactElement } from 'react';
import Baselayer from 'hcmaps-leaflet/dist/map-baselayer';
import Layer from 'hcmaps-leaflet/dist/map-layer';
import Map from 'hcmaps-leaflet/dist/maps';

import { SimilarityLevelLabel } from '@hcs/pdf/pdf-service';
import { SubjectMarker } from '@hcs/pdf/pdf-service';
import { SimilarityLevel } from '@hcs/types';
import { VECTILES_URL } from '@hcs/urls';

import { AppraisalReviewCompsMapMarkers } from '../../features/AppraisalReviewCompsMap/AppraisalReviewCompsMapMarkers';
import { useReportValue } from '../../hooks/useReportValue';
import { useSubjectDocument } from '../../hooks/useSubjectDocument';
import { formatPricePerSqftColorTableLabel } from '../CompsMap';

import 'leaflet/dist/leaflet.css';
import './AppraisalReviewCompsMap.css';
import styles from './AppraisalReviewCompsMap.module.css';

type PaletteType = [string, string, string, string, string, string, string];
const colorTableLookup = (
  colorTable: Array<[number, number, string]>,
  value: number
) => {
  const tableMatch = colorTable.find(
    ([min, max]) => min <= value && value < max
  );
  return tableMatch ? tableMatch[2] : null;
};
const PRICE_BLOCK_COLORS: PaletteType = [
  '#bce7f5',
  '#79cfec',
  '#1b97c0',
  '#5a7882',
  '#fc8e0f',
  '#fdb768',
  '#fee7cd',
];

enum PRICE_BLOCK_EDGES {
  'first' = 0.75,
  'second' = 0.85,
  'third' = 0.95,
  'fourth' = 1.05,
  'fifth' = 1.15,
  'sixth' = 1.25,
}

// Will round number to the specified precision before truncating
// (default: 8 digits precision)
// Suitable for dollar values
function precisionTrunc(number: number, precision = 8) {
  const epsilon = Math.pow(10, precision);
  return Math.trunc(Math.round(number * epsilon) / epsilon);
}
function getPricePerSqftColorTable(
  basePrice: number,
  palette = PRICE_BLOCK_COLORS
): [number, number, string][] {
  return [
    [
      Number.NEGATIVE_INFINITY,
      precisionTrunc(basePrice * PRICE_BLOCK_EDGES['first']),
      palette[0],
    ],
    [
      precisionTrunc(basePrice * PRICE_BLOCK_EDGES['first']) + 1,
      precisionTrunc(basePrice * PRICE_BLOCK_EDGES['second']),
      palette[1],
    ],
    [
      precisionTrunc(basePrice * PRICE_BLOCK_EDGES['second']) + 1,
      precisionTrunc(basePrice * PRICE_BLOCK_EDGES['third']),
      palette[2],
    ],
    [
      precisionTrunc(basePrice * PRICE_BLOCK_EDGES['third']) + 1,
      precisionTrunc(basePrice * PRICE_BLOCK_EDGES['fourth']),
      palette[3],
    ],
    [
      precisionTrunc(basePrice * PRICE_BLOCK_EDGES['fourth']) + 1,
      precisionTrunc(basePrice * PRICE_BLOCK_EDGES['fifth']),
      palette[4],
    ],
    [
      precisionTrunc(basePrice * PRICE_BLOCK_EDGES['fifth']) + 1,
      precisionTrunc(basePrice * PRICE_BLOCK_EDGES['sixth']),
      palette[5],
    ],
    [
      precisionTrunc(basePrice * PRICE_BLOCK_EDGES['sixth']) + 1,
      Number.POSITIVE_INFINITY,
      palette[6],
    ],
  ];
}
function featureStyle(
  this: ReactElement,
  layerInfo: Record<string, string>,
  feature: { properties: { prices: { SFD: number } } }
) {
  const { basePricePerSqft } = this.props;
  const { prices } = feature.properties;

  if (!basePricePerSqft || !prices) {
    return null;
  }

  const price = prices.SFD;
  const priceBucket = getPricePerSqftColorTable(
    basePricePerSqft,
    this.props.palette
  );
  const color = colorTableLookup(priceBucket, price);
  return !color
    ? null
    : {
        fill: color,
        opacity: 0.5,
      };
}

const dataHcName = 'appraisal-review-comps-map';
export const AppraisalReviewCompsMap = () => {
  const subjectDoc = useSubjectDocument();
  const reportValue = useReportValue();
  // Used as fallback if no AVM exists for property
  const basePricePerSqFtValue =
    reportValue?.selectedValue?.calculatedFields.valuePerSqFt;
  const subjectLatLon = [
    subjectDoc.data.propertyState.location?.latitude,
    subjectDoc.data.propertyState.location?.longitude,
  ];

  return (
    <>
      <div
        style={{
          position: 'relative',
          height: 800,
          width: '100%',
        }}
      >
        <Map
          geotileApiUrl={VECTILES_URL}
          apiToken="pk.eyJ1IjoibWF0dC1oYyIsImEiOiJ4ZHRkby1jIn0.6AE5Ob825Mlcaf_lIU66eA"
          mapPosition={subjectLatLon}
          leafletOptions={{
            zoomControl: false,
            zoomAnimation: false,
            fadeAnimation: false,
            markerZoomAnimation: false,
            preferCanvas: true,
            maxZoom: 15,
            attributionControl: false,
            scale: false,
            dragging: false,
            scrollWheelZoom: false,
          }}
        >
          <AppraisalReviewCompsMapMarkers />
          <Layer
            endpoint={`${VECTILES_URL}/tiles/blocks/{z}/{x}/{y}.json`}
            palette={PRICE_BLOCK_COLORS}
            featureStyle={featureStyle}
            zIndex={10}
            basePricePerSqft={basePricePerSqFtValue}
          />
          <Baselayer />
        </Map>
      </div>
      <div className={styles.Legend}>
        <div className={styles.LegendCell}>
          <div className={styles.LegendLabel}>Similarity</div>
          <div className={styles.LegendContent}>
            <div className={styles.SimilarityItem}>
              <SimilarityLevelLabel
                dataHcName={`${dataHcName}-legend-sim-high`}
                similarityLevel={SimilarityLevel.High}
              />
              High
            </div>
            <div className={styles.SimilarityItem}>
              <SimilarityLevelLabel
                dataHcName={`${dataHcName}-legend-sim-moderate`}
                similarityLevel={SimilarityLevel.Moderate}
              />
              Moderate
            </div>
            <div className={styles.SimilarityItem}>
              <SimilarityLevelLabel
                dataHcName={`${dataHcName}-legend-sim-low`}
                similarityLevel={SimilarityLevel.Low}
              />
              Low
            </div>
            <div className={styles.SimilarityItem}>
              <SimilarityLevelLabel
                dataHcName={`${dataHcName}-legend-hc-comp`}
                highlight="hc"
              />
              HC Comparable
            </div>
            <div className={styles.SimilarityItem}>
              <SimilarityLevelLabel
                dataHcName={`${dataHcName}-legend-appraisal-comp`}
                highlight="appraisal"
              />
              Appraisal Comp.
            </div>
            <div className={styles.SimilarityItem}>
              <SubjectMarker />
              Subject
            </div>
          </div>
        </div>
        {basePricePerSqFtValue && (
          <div className={styles.LegendCell}>
            <div className={styles.LegendLabel}>Sales $/ft²</div>
            <div className={styles.LegendContent}>
              <div
                style={{
                  display: 'flex',
                  height: 14,
                  paddingTop: 3,
                }}
              >
                {getPricePerSqftColorTable(
                  basePricePerSqFtValue,
                  PRICE_BLOCK_COLORS
                ).map(([, , color]) => (
                  <div
                    key={color}
                    style={{
                      width: 50,
                      height: '100%',
                      backgroundColor: color,
                    }}
                  />
                ))}
              </div>
              <div style={{ display: 'flex' }}>
                {getPricePerSqftColorTable(
                  basePricePerSqFtValue,
                  PRICE_BLOCK_COLORS
                ).map(([low, high, color]) => (
                  <div
                    key={color}
                    style={{
                      width: 50,
                      marginTop: 5,
                      fontSize: 8,
                      lineHeight: '10px',
                      textAlign: 'center',
                    }}
                  >
                    {formatPricePerSqftColorTableLabel(low, high)}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
