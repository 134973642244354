import React, { useMemo } from 'react';

import { Appreciation } from '@hcs/design-system';
import { NullState } from '@hcs/pdf/pdf-service';
import { Section } from '@hcs/pdf/pdf-service';
import { Table } from '@hcs/pdf/pdf-service';
import { ListingStatusCerberus } from '@hcs/types';
import { ValueTypes } from '@hcs/types';
import {
  formatDateShort,
  formatMissing,
  formatMoney,
  formatNumber,
} from '@hcs/utils';

import { useSubjectTransactionHistoryEvents } from '../../hooks';

const dataHcName = 'subject-transaction-history';

export type TransactionHistoryDisplayField =
  | 'date'
  | 'status'
  | 'type'
  | 'price'
  | 'appreciation'
  | 'daysOnMarket'
  | 'source';

interface Props {
  valueType?: ValueTypes;
  displayFields?: TransactionHistoryDisplayField[];
  sliceStartIndex?: number;
  hideHeader?: boolean;
}

export const SUBJECT_TRANSACTION_LAST_IDX_ON_PAGE = 5;
export const SubjectTransactionHistory = ({
  valueType,
  hideHeader,
  displayFields = [
    'date',
    'status',
    'type',
    'price',
    'appreciation',
    'daysOnMarket',
    'source',
  ],
  sliceStartIndex,
}: Props) => {
  const transactionHistoryEvents =
    useSubjectTransactionHistoryEvents(valueType);
  const transactionHistoryEventsSliced = useMemo(() => {
    return transactionHistoryEvents && sliceStartIndex
      ? transactionHistoryEvents?.slice(sliceStartIndex)
      : transactionHistoryEvents?.slice(
          0,
          SUBJECT_TRANSACTION_LAST_IDX_ON_PAGE + 1
        );
  }, [transactionHistoryEvents, sliceStartIndex]);

  if (!transactionHistoryEvents || transactionHistoryEvents?.length === 0) {
    return (
      <Section
        sectionHeaderProps={{ title: 'Transaction History' }}
        dataHcName={dataHcName}
      >
        <NullState dataHcName={`${dataHcName}-null-state`} />
      </Section>
    );
  }

  const TransactionTableContent = () => {
    return (
      <Table dataHcName={`${dataHcName}-table`}>
        <thead>
          <tr>
            {displayFields.includes('date') && (
              <th data-hc-name={`${dataHcName}-table-header-date`}>Date</th>
            )}
            {displayFields.includes('status') && (
              <th data-hc-name={`${dataHcName}-table-header-event`}>Event</th>
            )}
            {displayFields.includes('type') && (
              <th data-hc-name={`${dataHcName}-table-header-type`}>Type</th>
            )}
            {displayFields.includes('price') && (
              <th data-hc-name={`${dataHcName}-table-header-price`}>Price</th>
            )}
            {displayFields.includes('appreciation') && (
              <th data-hc-name={`${dataHcName}-table-header-appreciation`}>
                Appreciation
              </th>
            )}
            {displayFields.includes('daysOnMarket') && (
              <th data-hc-name={`${dataHcName}-table-header-cdom`}>CDOM</th>
            )}
            {displayFields.includes('source') && (
              <th data-hc-name={`${dataHcName}-table-header-source`}>Source</th>
            )}
          </tr>
        </thead>
        <tbody>
          {transactionHistoryEventsSliced?.length &&
            transactionHistoryEventsSliced.map((transactionHistoryEvent, i) => {
              const isRental =
                ('isRental' in transactionHistoryEvent &&
                  transactionHistoryEvent.isRental) ||
                valueType === 'RENTAL';
              const isActiveEvent =
                transactionHistoryEvent.status === ListingStatusCerberus.Active;
              const isClosedEvent =
                transactionHistoryEvent.status === ListingStatusCerberus.Closed;
              const transactionType = isRental
                ? 'Rental'
                : transactionHistoryEvent.distressed
                ? 'Distressed'
                : transactionHistoryEvent.reo
                ? 'REO'
                : transactionHistoryEvent.armsLength === false && isClosedEvent
                ? 'Non-Arms-Length'
                : transactionHistoryEvent.armsLength && isClosedEvent
                ? 'Arms-Length'
                : null;
              const isPriceChangeEvent =
                isActiveEvent &&
                !!Math.abs(transactionHistoryEvent.priceVariance || 0);
              if (isPriceChangeEvent) {
                return null;
              }
              const daysOnMarketCumulative =
                // Present '--' Days on Market in the UI for the initial event
                !isActiveEvent ||
                !!transactionHistoryEvent.daysOnMarketCumulative
                  ? transactionHistoryEvent.daysOnMarketCumulative
                  : null;
              return (
                <tr key={`${transactionHistoryEvent.listingId}-${i}`}>
                  {displayFields.includes('date') && (
                    <td data-hc-name={`${dataHcName}-table--value-date`}>
                      {formatDateShort(transactionHistoryEvent.date)}
                    </td>
                  )}
                  {displayFields.includes('status') && (
                    <td data-hc-name={`${dataHcName}-table--value-event`}>
                      {formatMissing(transactionHistoryEvent.status)}
                    </td>
                  )}
                  {displayFields.includes('type') && (
                    <td data-hc-name={`${dataHcName}-table--value-type`}>
                      {formatMissing(transactionType)}
                    </td>
                  )}
                  {displayFields.includes('price') && (
                    <td data-hc-name={`${dataHcName}-table--value-price`}>
                      {formatMoney(transactionHistoryEvent.price)}
                    </td>
                  )}
                  {displayFields.includes('appreciation') && (
                    <td>
                      <Appreciation
                        dataHcName={`${dataHcName}-table-header-appreciation`}
                        appreciation={
                          isClosedEvent &&
                          transactionHistoryEvent.priceChangeLastClose &&
                          transactionHistoryEvent.priceChangePctLastClose
                            ? {
                                absoluteValue:
                                  transactionHistoryEvent.priceChangeLastClose,
                                rate: transactionHistoryEvent.priceChangePctLastClose,
                              }
                            : undefined
                        }
                      />
                    </td>
                  )}
                  {displayFields.includes('daysOnMarket') && (
                    <td data-hc-name={`${dataHcName}-table-header-adom`}>
                      {formatNumber(daysOnMarketCumulative)}
                    </td>
                  )}
                  {displayFields.includes('source') && (
                    <td data-hc-name={`${dataHcName}-table--value-source`}>
                      {formatMissing(transactionHistoryEvent.sourceType)}
                    </td>
                  )}
                </tr>
              );
            })}
        </tbody>
      </Table>
    );
  };

  return hideHeader ? (
    <TransactionTableContent />
  ) : (
    <Section
      sectionHeaderProps={{ title: 'Transaction History' }}
      dataHcName={dataHcName}
    >
      <TransactionTableContent />
    </Section>
  );
};
