// object-fit CSS Property causes images to be converted to PNG, resulting in large file sizes.
// This component should be used instead of object-fit
import React, { ImgHTMLAttributes, useRef, useState } from 'react';
import classNames from 'classnames';

import styles from './ImgFit.module.css';

const useImgDimensions = () => {
  const ref = useRef<HTMLImageElement | null>(null);
  const [dimensions, setDimensions] = useState<{
    height: number;
    width: number;
  } | null>(null);
  const onLoad = () => {
    if (ref.current) {
      setDimensions({
        height: ref.current.height,
        width: ref.current.width,
      });
    }
  };
  return { ref, dimensions, onLoad };
};

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  dataHcName: string;
  height: number;
  width: number;
  src: string;
  /** Defines how to fit the image in the area.
   * Cover will fill the area, but may crop some of the image
   * Contain will show the entire image but may not cover the entire area */
  objectFit: 'cover' | 'contain';
}
export const ImgCover = ({
  src,
  height,
  width,
  className,
  dataHcName,
  ...props
}: Omit<Props, 'objectFit'>) => {
  const { ref, dimensions, onLoad } = useImgDimensions();
  const imgAspectRatio = !!dimensions && dimensions.width / dimensions.height;
  const frameAspectRatio = width / height;
  return (
    <div
      className={classNames(styles.ImgCover, className)}
      style={{
        height: `${height}px`,
        width: `${width}px`,
      }}
    >
      <img
        alt={dataHcName}
        data-hc-name={dataHcName}
        src={src}
        {...props}
        ref={ref}
        style={{
          width:
            typeof imgAspectRatio === 'number' &&
            frameAspectRatio > imgAspectRatio
              ? '100%'
              : 'auto',
          height:
            typeof imgAspectRatio === 'number' &&
            frameAspectRatio > imgAspectRatio
              ? 'auto'
              : '100%',
        }}
        onLoad={onLoad}
      />
    </div>
  );
};

export const ImgContain = ({
  src,
  height,
  width,
  className,
  dataHcName,
  ...props
}: Omit<Props, 'objectFit'>) => {
  const { ref, dimensions, onLoad } = useImgDimensions();
  const imgAspectRatio = !!dimensions && dimensions.width / dimensions.height;
  const frameAspectRatio = width / height;

  return (
    <div className={classNames(className)}>
      <img
        alt={dataHcName}
        src={src}
        {...props}
        ref={ref}
        style={{
          width:
            !dimensions ||
            (typeof imgAspectRatio === 'number' &&
              frameAspectRatio > imgAspectRatio)
              ? 'auto'
              : `${Math.min(width, dimensions.width)}px`,
          height:
            dimensions &&
            typeof imgAspectRatio === 'number' &&
            frameAspectRatio > imgAspectRatio
              ? `${Math.min(width, dimensions.height)}px`
              : 'auto',
        }}
        onLoad={onLoad}
      />
    </div>
  );
};

export const ImgFit = ({ objectFit, ...props }: Props) => {
  if (objectFit === 'contain') {
    return <ImgContain {...props} />;
  } else {
    return <ImgCover {...props} />;
  }
};
