import React from 'react';

import { Section } from '../Section';

export interface DisclaimerProps {
  productName: string;
  className?: string;
}

const dataHcName = 'disclaimer';
export const Disclaimer = ({ className, productName }: DisclaimerProps) => {
  return (
    <Section
      dataHcName={dataHcName}
      sectionHeaderProps={{
        title: 'Disclaimer',
      }}
      className={className}
    >
      <p data-hc-name={`${dataHcName}-text`}>
        This {productName} is provided solely for general business information
        purposes. No advisory, fiduciary or other relationship is created by any
        acceptance or use of this {productName}. The inclusion of this{' '}
        {productName} with any other materials does not constitute an
        endorsement by HouseCanary of any third party or any third party’s
        products or services. The projected market, valuation and financial
        information, conclusions and other information contained in this{' '}
        {productName} are based upon tested methodologies for accuracy. However,
        such information and conclusions are not definitive forecasts,
        appraisals or opinions of valuations. All such information and
        conclusions are stated in terms of probability of likelihood based on
        market factors and information submitted to HouseCanary, and such
        information and conclusions are not guaranteed by HouseCanary and should
        not be construed as a certified appraisal or valuation, or investment
        advice, or relied upon for critical decision making. HouseCanary uses or
        has used public and/or confidential data and assumptions provided to
        HouseCanary by third parties, and HouseCanary has not independently
        verified the data and assumptions used in these analyses or data sets.
        Attributes for properties may be inaccurate because county assessor and
        MLS data does not always include recent additions and/or modifications
        to property structure. Changes in the underlying data or operating
        assumptions, or any loss of access to any one or more sources will
        clearly impact the analyses, information and conclusions set forth in
        this {productName} Report.
      </p>
    </Section>
  );
};
