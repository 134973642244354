import React from 'react';

import { NearbyPropertiesChartsSection } from '../../features/NearbyPropertiesCharts';
import {
  SubjectDetailsSection,
  SubjectDetailsSectionProps
} from '../../features/SubjectDetailsSection';
import { SubjectTransactionHistory } from '../../features/SubjectTransactionHistorySection/SubjectTransactionHistorySection';
import {
  SubjectValueSection,
  SubjectValueSectionProps
} from '../../features/SubjectValueSection/SubjectValueSection';
import { PropertyReportPage } from '../PropertyReports/PropertyReportPage';

export interface PropsPexpSubjectDetailsPage {
  propsSubjectDetailsSection?: SubjectDetailsSectionProps;
  propsSubjectValueSection?: SubjectValueSectionProps;
}
const dataHcName = 'pexp-subject-details-page';
export const PexpSubjectDetailsPage = ({
  propsSubjectDetailsSection,
  propsSubjectValueSection
}: PropsPexpSubjectDetailsPage) => {
  return (
    <PropertyReportPage dataHcName={dataHcName}>
      <SubjectValueSection {...propsSubjectValueSection} />
      <SubjectDetailsSection {...propsSubjectDetailsSection} />
      <SubjectTransactionHistory />
      <NearbyPropertiesChartsSection />
    </PropertyReportPage>
  );
};
