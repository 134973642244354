import React from 'react';

import styles from './NearbyPropertiesCharts.module.css';

const dataHcName = 'nearby-properties-charts-legend';
export const NearbyPropertiesChartsLegend = () => {
  return (
    <div data-hc-name={dataHcName} className={styles.ChartLegend}>
      <div className={styles.LegendTitle}>Legend</div>
      <div className={styles.LegendRow}>
        <span className={styles.LegendSubject} />
        <span className={styles.LegendLabel}>Subject Property</span>
      </div>
      <div className={styles.LegendRow}>
        <span className={styles.LegendOther} />
        <span className={styles.LegendLabel}>Nearby properties</span>
      </div>
    </div>
  );
};
