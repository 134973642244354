import React from 'react';

import { ReportApiReports } from '@hcs/types';
import { formatDateShort, formatMissing } from '@hcs/utils';

import { useAppraisalInputDocument } from '../..//hooks/useAppraisalInputDocument';
import { useReport } from '../../hooks/useReport';
import { PropertyReportPageHeader } from '../PropertyReports/PropertyReportPageHeader';

const dataHcName = 'appraisal-review-page-header';
export const AppraisalReviewPageHeader = <D extends ReportApiReports>() => {
  const report = useReport<D>();
  const appraisalInputsDoc = useAppraisalInputDocument();
  const headerDetails = [
    {
      dataHcName: `${dataHcName}-appraiser`,
      label: 'Appraiser',
      value: formatMissing(appraisalInputsDoc?.data.Appraiser.Name),
    },
    {
      dataHcName: `${dataHcName}-amc`,
      label: 'AMC',
      value: formatMissing(appraisalInputsDoc?.data.Appraiser.Company),
    },
    {
      dataHcName: `${dataHcName}-lender`,
      label: 'Lender',
      value: formatMissing(appraisalInputsDoc?.data.LenderName),
    },
    {
      dataHcName: `${dataHcName}-effective-date`,
      label: 'Effective Date',
      value: formatDateShort(report.effectiveDate),
    },
  ];
  if (report.clientID) {
    headerDetails.unshift({
      dataHcName: `${dataHcName}-client-file-id`,
      label: 'Client File ID',
      value: report.clientID,
    });
  }
  return <PropertyReportPageHeader headerDetails={headerDetails} cobrandLogo />;
};
