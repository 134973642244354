import React from 'react';

import { Section, SubContent, TwoColSection } from '@hcs/pdf/pdf-service';

import { BrokerPriceOpinion } from '../../features/BrokerPriceOpinion';
import { ReconciliationComments } from '../../features/ReconciliationComments';
import { SaleListPriceTrendChart } from '../../features/SaleListPriceTrendChart';
import { Top4CompsRemarkSection } from '../../features/Top4CompsRemarkSection';

import { BpoReportPage } from './BpoReportPage';

import styles from './ConclusionPage.module.css';

const dataHcName = 'conclusion-page';
export const ConclusionPage = () => {
  return (
    <BpoReportPage dataHcName={dataHcName}>
      <Section
        sectionHeaderProps={{
          title: 'Conclusion',
        }}
        dataHcName={`${dataHcName}-section`}
      >
        <>
          <SubContent>MLS Comments (Sold & Listings)</SubContent>
          <Top4CompsRemarkSection />
        </>
        <TwoColSection
          dataHcName={dataHcName}
          contentLeft={<ReconciliationComments />}
          contentRight={
            <>
              <SaleListPriceTrendChart />
              <div className={styles.BrokerPriceOpinion}>
                <BrokerPriceOpinion horizontal />
              </div>
            </>
          }
        />
      </Section>
    </BpoReportPage>
  );
};
