import React, { ReactNode } from 'react';

import { TwoColSection } from '@hcs/pdf/pdf-service';

import { SubjectValue } from '../SubjectValue/SubjectValue';

export interface SubjectValueSectionProps {
  contentLeft?: ReactNode;
  contentRight?: ReactNode;
}
const dataHcName = 'subject-value-section';
export const SubjectValueSection = (props: SubjectValueSectionProps) => {
  return (
    <TwoColSection
      dataHcName={dataHcName}
      contentLeft={<SubjectValue />}
      contentRight={<SubjectValue showRentalValue />}
      {...props}
    />
  );
};
