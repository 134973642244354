import React from 'react';

import { AppraisalInputsInfo } from '../features';
import { AppraisalReviewCompsDataVerificationPages } from '../pdf-pages';
import { AppraisalReviewCompsSummaryPage } from '../pdf-pages';
import { AppraisalReviewCoverPage } from '../pdf-pages';
import { AppraisalReviewGlossaryPage } from '../pdf-pages';
import { AppraisalReviewMapPage } from '../pdf-pages';
import { AppraisalReviewSubjectPage } from '../pdf-pages';
import { AppraisalReviewSummaryPage } from '../pdf-pages';

// The customizable component is consumed by other templates
export const ReportApiAppraisalReviewPdfWithProps = () => {
  return (
    <>
      <AppraisalReviewCoverPage customContent={<AppraisalInputsInfo />} />
      <AppraisalReviewSummaryPage />
      <AppraisalReviewSubjectPage />
      <AppraisalReviewMapPage />
      <AppraisalReviewCompsSummaryPage />
      <AppraisalReviewCompsDataVerificationPages />
      <AppraisalReviewGlossaryPage />
    </>
  );
};

// The default PDF gets a lazy-import in the index file
export const ReportApiAppraisalReviewPdf = () => {
  return <ReportApiAppraisalReviewPdfWithProps />;
};
