import React from 'react';

import { RISK_FACTORS_CONFIGS } from '@hcs/report-api';
import { AppraisalCompDocument } from '@hcs/types';

import { BiasRisk } from '../..//component/BiasRisk/BiasRisk';
import { useAppraisalRiskCompProperty } from '../..//hooks/useAppraisalRiskCompProperty';

import styles from './AppraisalReviewCompVerification.module.css';

interface Props {
  comp: AppraisalCompDocument;
}
const dataHcName = 'apprailal-review-comp-verification';
export const AppraisalReviewCompVerificationFactors = ({ comp }: Props) => {
  const { riskFactors } =
    useAppraisalRiskCompProperty({
      compId: comp.data.compID,
    }) || {};
  return (
    <div data-hc-name={dataHcName} className={styles.CompRiskFactors}>
      {riskFactors?.map(({ riskFactor, riskLevel }) => {
        const { label } = RISK_FACTORS_CONFIGS[riskFactor];
        return (
          <BiasRisk
            key={riskFactor}
            riskType="comparables"
            riskLevel={riskLevel}
            iconProps={{
              height: 15,
            }}
            includeIcon
          >
            {label}
          </BiasRisk>
        );
      })}
    </div>
  );
};
