import React from 'react';

import { GlossaryTerms } from '@hcs/types';
import { CompFields, PropertyStateFields } from '@hcs/types';

import { useReportValue } from '../hooks';
import {
  PexpCompsPages,
  PexpGlossaryContinuedPage,
  PexpGlossaryPage,
  PexpSubjectDetailsPage,
  PropertyReportCoverPage,
} from '../pdf-pages';

export const ReportApiEffectiveDatePdf = () => {
  const reportValue = useReportValue();
  return (
    <>
      <PropertyReportCoverPage />
      <PexpSubjectDetailsPage
        propsSubjectValueSection={{ contentRight: null }}
      />
      <PexpCompsPages
        compsCompareFields={[
          CompFields.similarity,
          CompFields.distance,
          PropertyStateFields.lastCloseDate,
          PropertyStateFields.lastClosePrice,
          PropertyStateFields.lastClosePricePerSqFt,
          PropertyStateFields.currentListDate,
          PropertyStateFields.currentListingPrice,
          PropertyStateFields.currentListingPricePerSqFt,
          PropertyStateFields.bedrooms,
          PropertyStateFields.bathrooms,
          PropertyStateFields.livingArea,
          PropertyStateFields.lotSize,
          PropertyStateFields.propertyType,
          PropertyStateFields.yearBuilt,
          PropertyStateFields.currentStatus,
          PropertyStateFields.subdivisionName,
          PropertyStateFields.daysOnMarketActive,
          PropertyStateFields.daysOnMarketCumulative,
          PropertyStateFields.pool,
          PropertyStateFields.garageSpaces,
          PropertyStateFields.stories,
          PropertyStateFields.basement,
          PropertyStateFields.currentDistressed,
          PropertyStateFields.currentFlip,
        ]}
      />
      <PexpGlossaryPage
        glossaryTerms={[
          {
            type: 'avm',
            glossaryTerm: GlossaryTerms.HcValue,
            avm: reportValue?.selectedValue,
          },
          GlossaryTerms.Active,
          GlossaryTerms.CompProperties,
          GlossaryTerms.DaysOnMarket,
          GlossaryTerms.ForecastStandardDeviation,
          GlossaryTerms.HcSuggestedComps,
          GlossaryTerms.RiskOfDecline,
          GlossaryTerms.MonthsOfSupply,
        ]}
      />
      <PexpGlossaryContinuedPage />
    </>
  );
};
