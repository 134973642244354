import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './SubContent.module.css';

type Props = {
  children: string | ReactNode;
  theme?: {
    SubContent: string;
  };
};

export const SubContent = ({ children, theme }: Props) => {
  return (
    <h3 className={classNames(styles.SubContent, theme?.SubContent)}>
      {children}
    </h3>
  );
};
