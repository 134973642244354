import React from 'react';

import { Section } from '@hcs/pdf/pdf-service';
import { TwoColTable } from '@hcs/pdf/pdf-service';
import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import { PropertyStateFields } from '@hcs/types';
import { formatMissing } from '@hcs/utils';

import { useMlsListingSheetData } from '../../hooks/useMlsListingSheetData';

const dataHcName = 'listing-agent-section';

export const ListingAgentSection = () => {
  const { rentalListing, propertyStateCore } = useMlsListingSheetData();
  const { getValue } =
    PROPERTY_STATE_FIELD_CONFIGS[
      rentalListing
        ? PropertyStateFields.listingAgentOfficeRental
        : PropertyStateFields.listingAgentOffice
    ];
  const agentInfo = getValue(propertyStateCore);
  return (
    <Section
      dataHcName={dataHcName}
      sectionHeaderProps={{ title: 'Office/Agent Information' }}
      compact
    >
      <TwoColTable
        smallLabels
        compact
        dataHcName={`${dataHcName}-table`}
        data={[
          {
            dataHcName: 'listing-office-name',
            label: 'List Office',
            value: formatMissing(agentInfo?.office),
          },
          {
            dataHcName: 'agent-name',
            label: 'List Agent',
            value: formatMissing(agentInfo?.name),
          },
          {
            dataHcName: 'agent-phone',
            label: 'Phone',
            value: formatMissing(agentInfo?.phone),
          },
        ]}
      />
    </Section>
  );
};
