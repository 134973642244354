import React from 'react';

import { NullState, Section } from '@hcs/pdf/pdf-service';
import { ListingStatus } from '@hcs/types';

import { PhotosSection } from '../../features/PhotosSection';
import { useActiveTop4CompPhotosDocuments } from '../../hooks/useActiveTop4CompPhotosDocuments';
import { useClosedTop4CompPhotosDocuments } from '../../hooks/useClosedTop4CompPhotosDocuments';

import { BpoReportPage } from './BpoReportPage';

export interface PropsCompPhotosPage {
  compsStatus: ListingStatus;
}
export const CompPhotosPage = ({ compsStatus }: PropsCompPhotosPage) => {
  const top4Active = useActiveTop4CompPhotosDocuments();
  const top4Closed = useClosedTop4CompPhotosDocuments();
  const top4Photos =
    compsStatus === ListingStatus.ACTIVE ? top4Active : top4Closed;
  const statusLabel = compsStatus === ListingStatus.ACTIVE ? 'Active' : 'Sold';
  const title = `Photos - MLS - ${statusLabel}`;
  const dataHcName = `${statusLabel}-comp-photos-page`;

  if (!top4Photos || top4Photos.length === 0) {
    return (
      <BpoReportPage dataHcName={dataHcName} key={dataHcName}>
        <Section
          sectionHeaderProps={{
            title: title,
          }}
          dataHcName={dataHcName}
        >
          <NullState dataHcName={dataHcName} />
        </Section>
      </BpoReportPage>
    );
  }

  return (
    <>
      {top4Photos.map((photo, idx) => (
        <BpoReportPage
          dataHcName={`${dataHcName}-${idx + 1}`}
          key={`${dataHcName}-${idx + 1}`}
        >
          <PhotosSection subjectPhotos={photo} title={`${title} ${idx + 1}`} />
        </BpoReportPage>
      ))}
    </>
  );
};
