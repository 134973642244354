import { useDispatch, useSelector } from 'react-redux';

import { mapDispatchToSliceActions } from '@hcs/utils';

import {
  ADDENDUM_REDUCER_KEY,
  AddendumPartialAppState,
  addendumSlice,
  AddendumSliceState,
} from '../rtk';

export const useAddendumSlice = () => {
  const dispatch = useDispatch();
  return {
    state: useSelector<AddendumPartialAppState, AddendumSliceState>(
      (state) => state[ADDENDUM_REDUCER_KEY]
    ),
    actions: mapDispatchToSliceActions(dispatch, addendumSlice),
  };
};
