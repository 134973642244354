import React from 'react';

import {
  PropertyTwoColTable,
  PropertyTwoColTableProps,
} from '@hcs/pdf/pdf-service';
import { TwoColSection } from '@hcs/pdf/pdf-service';
import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import {
  PropertyStateArgsCore,
  PropertyStateFields,
  PropertyStateType,
} from '@hcs/types';

import { useSubjectDocument } from '../../hooks/useSubjectDocument';

export interface SubjectDetailsSectionProps {
  columnConfigsLeft?: PropertyTwoColTableProps['fields'];
  columnConfigsRight?: PropertyTwoColTableProps['fields'];
}

const dataHcName = 'subject-details-section';
export const SubjectDetailsSection = ({
  columnConfigsLeft = [
    PropertyStateFields.propertyType,
    PropertyStateFields.bedrooms,
    PropertyStateFields.bathrooms,
    PropertyStateFields.livingArea,
    PropertyStateFields.condition,
  ],
  columnConfigsRight = [
    PropertyStateFields.yearBuilt,
    PropertyStateFields.lotSize,
    PropertyStateFields.ownerOccupied,
    PropertyStateFields.associationFee,
    PropertyStateFields.associationFeeIncludes,
  ],
}: SubjectDetailsSectionProps) => {
  const subjectDoc = useSubjectDocument();
  const propertyStateArgs: PropertyStateArgsCore = {
    propertyStateType: PropertyStateType.Core,
    propertyState: subjectDoc.data.propertyState,
  };
  const { Display: Apn, label: apnLabel } =
    PROPERTY_STATE_FIELD_CONFIGS[PropertyStateFields.apn];

  return (
    <TwoColSection
      dataHcName={dataHcName}
      sectionHeaderProps={{
        title: 'Subject',
        rightContent: {
          label: apnLabel,
          value: <Apn propertyStateArgs={propertyStateArgs} />,
        },
      }}
      contentLeft={
        <PropertyTwoColTable
          dataHcName={`${dataHcName}-table-right`}
          fields={columnConfigsLeft}
          propertyStateArgs={propertyStateArgs}
          userPropertyAdjustments={subjectDoc.userPropertyAdjustments}
        />
      }
      contentRight={
        <PropertyTwoColTable
          dataHcName={`${dataHcName}-table-left`}
          fields={columnConfigsRight}
          propertyStateArgs={propertyStateArgs}
          userPropertyAdjustments={subjectDoc.userPropertyAdjustments}
        />
      }
    />
  );
};
