import { DexpEndpointTiers } from '../data-explorer';
import { Personalization } from '../huell';
import { PropertyStateListing } from '../property-state/PropertyStateListing.types';
import { ReportApiReports } from '../report-api/reports';

import { MlsListingSheetPdfDataOriginal } from './MlsListingSheetPdf.types';

export enum PdfTemplateIds {
  AgileInsights = 'reportApi-agileInsights',
  ReportApiValue = 'reportApi-value',
  ReportApiLegacy = 'reportApi-legacy',
  ReportApiEffectiveDate = 'reportApi-effectivedate',
  ReportApiAppraisalReview = 'reportApi-appraisal',
  ReportApiBpoReport = 'reportApi-bpoReport',
  MlsListingSheetOld = 'mls-listing-sheet',
  MlsListingSheet = 'reportApi-listingSheet',
  ProductSheetDexp = 'productSheet-dexp',
}

export type PdfTemplate = () => JSX.Element;

export interface ProductSheetDexpData {
  endpointTiers: DexpEndpointTiers;
}

export type PdfServiceReports =
  | ReportApiReports
  | MlsListingSheetPdfDataOriginal
  | ProductSheetDexpData
  // For Listing Sheet
  | PropertyStateListing;

export interface PdfServicePayload<D extends PdfServiceReports> {
  data: D;
  personalization?: Personalization;
}

export interface PdfServiceInputs {
  pdfTemplateId: string;
  pdfServicePayload: PdfServicePayload<PdfServiceReports>;
}
