import React from 'react';

import { WarningIcon } from '../WarningIcon';

import styles from './NullState.module.css';

interface Props {
  dataHcName: string;
}

export const NullState = ({ dataHcName }: Props) => (
  <div data-hc-name={dataHcName} className={styles.NullState}>
    <WarningIcon
      className={styles.Icon}
      dataHcName={`${dataHcName}-warning-icon`}
      circle
    />
    <div className={styles.Content}>
      <div data-hc-name={`${dataHcName}-title`}>Insufficient Data:</div>
      <div data-hc-name={`${dataHcName}-message`} className={styles.Message}>
        HouseCanary data unavailable for this property at this time. We are
        working to improve our data each day.
      </div>
    </div>
  </div>
);
