import React from 'react';

import {
  PexpGlossaryContinuedPage,
  PexpGlossaryPage,
  PexpMarketPage,
  PexpSubjectDetailsPage,
  PropertyReportCoverPage,
} from '../pdf-pages';

// The default PDF gets a lazy-import in the index file
export const ReportApiAgileInsightsPdf = () => {
  return (
    <>
      <PropertyReportCoverPage
        metadata={[
          {
            label: 'Client Name',
            value: 'Where does this come from?',
            dataHcName: 'client-name',
          },
        ]}
      />
      <PexpSubjectDetailsPage />
      {/* CLOSED PAGE HERE, LIMIT 4 */}
      {/* ACTIVE PAGE HERE, LIMIT 4 */}
      <PexpMarketPage />
      {/* CLOSED MAP PAGE HERE */}
      <PexpGlossaryPage />
      <PexpGlossaryContinuedPage />
    </>
  );
};
