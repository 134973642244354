import React from 'react';

import { NoContent } from '@hcs/design-system';
import { PropertyDataSourcesTable } from '@hcs/pdf/pdf-service';
import { Section } from '@hcs/pdf/pdf-service';
import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import {
  PropertyStateArgsCore,
  PropertyStateFields,
  PropertyStateSources,
  PropertyStateType,
} from '@hcs/types';

import { APPRAISAL_REVIEW_PROPERTY_STATE_FIELDS } from '../..//constants/appraisalReview.constants';
import { useAppraisalComparisonDocument } from '../..//hooks/useAppraisalComparisonDocument';
import { useAppraisalSubjectDocument } from '../..//hooks/useAppraisalSubjectDocument';
import { useSubjectDataSourcesDocument } from '../..//hooks/useSubjectDataSourcesDocument';

const dataHcName = 'appraisal-subject-details-section';
export const AppraisalSubjectDetailsSection = () => {
  const biasRiskDocument = useAppraisalComparisonDocument();
  const subjectDataSourcesDoc = useSubjectDataSourcesDocument();
  const appraisalSubjectDoc = useAppraisalSubjectDocument();
  const appraisalArgs: PropertyStateArgsCore = {
    propertyStateType: PropertyStateType.Core,
    propertyState: appraisalSubjectDoc?.data.propertyState,
  };
  const { Display: Apn, label: apnLabel } =
    PROPERTY_STATE_FIELD_CONFIGS[PropertyStateFields.apn];
  return (
    <Section
      dataHcName={dataHcName}
      sectionHeaderProps={{
        title: 'Subject',
        rightContent: {
          label: apnLabel,
          value: (
            <Apn
              propertyStateArgs={{
                propertyStateType: PropertyStateType.Core,
                propertyState: subjectDataSourcesDoc?.data.hc,
              }}
            />
          ),
        },
      }}
    >
      {subjectDataSourcesDoc?.data ? (
        <PropertyDataSourcesTable
          dataHcName={dataHcName}
          propertyStateSources={subjectDataSourcesDoc.data}
          fields={APPRAISAL_REVIEW_PROPERTY_STATE_FIELDS}
          appraisalPropertyStateArgs={appraisalArgs}
          dataVerificationConfig={{
            primarySource: 'appraisal',
            compareSource: PropertyStateSources.HC,
            dataVerification: biasRiskDocument.data.value.dataVerification,
          }}
        />
      ) : (
        <NoContent dataHcName={`${dataHcName}-null`}>
          Insufficient Data
        </NoContent>
      )}
    </Section>
  );
};
