import React from 'react';

import { TwoColSection } from '@hcs/pdf/pdf-service';

import { BrokerPriceOpinion } from '../BrokerPriceOpinion';
import { BrokerPriceOpinionTable } from '../BrokerPriceOpinionTable';
import { SubjectValueSectionProps } from '../SubjectValueSection';

const dataHcName = 'subject-value-conclusion';
export const SubjectValueConclusion = (props: SubjectValueSectionProps) => {
  return (
    <TwoColSection
      dataHcName={dataHcName}
      sectionHeaderProps={{
        title: 'Value Conclusion',
      }}
      contentLeft={<BrokerPriceOpinion {...SubjectValueConclusion} />}
      contentRight={<BrokerPriceOpinionTable />}
    />
  );
};
