import React from 'react';
import classNames from 'classnames';

import { formatFsdAsPercentage, formatMoney } from '@hcs/utils';

import { AppraisedValueComparisonChart } from '../..//features/AppraisedValueComparisonChart';
import { useAppraisalSubjectValueDocument } from '../..//hooks/useAppraisalSubjectValueDocument';
import { useReportValue } from '../..//hooks/useReportValue';

import styles from './AppraisedValueComparison.module.css';

interface Props {
  className?: string;
}
const dataHcName = 'appraised-value-comparison';
export const AppraisedValueComparison = ({ className }: Props) => {
  const { selectedValue, label } = useReportValue(false) || {};
  const appraisalSubjectValueDocument = useAppraisalSubjectValueDocument();
  const fsd = selectedValue?.value.fsd;
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.AppraisedValueComparison, className)}
    >
      <div className={styles.Values}>
        <div className={styles.ValueGroup}>
          <div className={styles.ValueLabel}>{label}</div>
          <div className={styles.Value}>
            {formatMoney(selectedValue?.value.value)}
          </div>
          {fsd && (
            <div className={styles.ValueNote}>
              FSD: {fsd.toFixed(2)} ({formatFsdAsPercentage(fsd)})
            </div>
          )}
        </div>
        <div className={styles.ValueGroup}>
          <div className={styles.ValueLabel}>Appraisal Value</div>
          <div className={styles.Value}>
            {formatMoney(
              appraisalSubjectValueDocument.data.userValue.value.value
            )}
          </div>
        </div>
      </div>
      <div>
        <AppraisedValueComparisonChart />
      </div>
    </div>
  );
};
