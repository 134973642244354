import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { AvmQuality } from '@hcs/types';
import { formatFsdAsPercentage } from '@hcs/utils';
import { capitalizeFirstLetter } from '@hcs/utils';

import { ValueRangeChart, ValueRangeChartProps } from '../ValueRangeChart';

import styles from './ValueSubSection.module.css';

interface Props extends ValueRangeChartProps {
  dataHcName: string;
  title: ReactNode;
  fsd?: number | null;
  avmQuality?: AvmQuality | null;
  showValueRange?: boolean;
  showAvmQuality?: boolean;
  disclaimer?: ReactNode;
}

export const ValueSubSection = ({
  dataHcName,
  title,
  fsd,
  avmQuality,
  showValueRange,
  showAvmQuality,
  disclaimer,
  ...valueRaneChartProps
}: Props) => {
  return (
    <div data-hc-name={dataHcName} className={styles.ValueSubSection}>
      <div className={styles.FlexContent}>
        <div className={styles.TitleContainer}>
          <div data-hc-name={`${dataHcName}-label`} className={styles.Title}>
            {title}
          </div>
          {showAvmQuality && (
            <div data-hc-name={`${dataHcName}-avm-quality-section`}>
              <div>
                {avmQuality && (
                  <span
                    data-hc-name={`${dataHcName}-avm-quality`}
                    className={classNames(
                      styles.Confidence,
                      styles[
                        avmQuality.toLowerCase() as 'high' | 'average' | 'low'
                      ]
                    )}
                  >
                    {capitalizeFirstLetter(avmQuality)} Confidence
                  </span>
                )}{' '}
                {fsd != null ? (
                  <span
                    data-hc-name={`${dataHcName}-fsd-percentage`}
                    className={styles.Fsd}
                  >
                    {`(${formatFsdAsPercentage(fsd)})`}
                  </span>
                ) : null}
              </div>
              {fsd != null ? (
                <div data-hc-name={`${dataHcName}-fsd`} className={styles.Fsd}>
                  FSD: {fsd.toFixed(2)}
                </div>
              ) : null}
            </div>
          )}
        </div>

        <div className={styles.ChartContainer}>
          <ValueRangeChart
            {...valueRaneChartProps}
            dataHcName={`${dataHcName}-chart`}
            valueOnly={!showValueRange}
          />
        </div>
      </div>
      {disclaimer && (
        <div
          data-hc-name={`${dataHcName}-disclaimer`}
          className={styles.Disclaimer}
        >
          {disclaimer}
        </div>
      )}
    </div>
  );
};
