import {
  DocumentRoles,
  PricingMarketStrategyCommentsDocument,
} from '@hcs/types';
import { ReportApiReports } from '@hcs/types';

import { useDocumentRole } from './useDocumentRole';

export const usePricingMarketStrategyComments = <
  Data extends ReportApiReports
>() => {
  return useDocumentRole<PricingMarketStrategyCommentsDocument, Data>(
    DocumentRoles.PricingMarketStrategyComments
  );
};
