import React from 'react';

import { Glossary } from '@hcs/pdf/glossary';
import { Section } from '@hcs/pdf/pdf-service';
import { GlossaryTerms, GlossaryTermsProp } from '@hcs/types';

import { AppraisalReviewPage } from '../..//pdf-pages/AppraisalReviewReports/AppraisalReviewPage';

interface Props {
  glossaryTerms?: GlossaryTermsProp;
}
const dataHcName = 'appraisal-review-glossary-page';
export const AppraisalReviewGlossaryPage = (props: Props) => {
  const glossaryTerms: GlossaryTermsProp = props.glossaryTerms || [
    GlossaryTerms.CompProperties,
    GlossaryTerms.CurrentValue,
    GlossaryTerms.ForecastStandardDeviation,
    GlossaryTerms.HcSuggestedComps,
    GlossaryTerms.HcValue,
    GlossaryTerms.NearbyProperties,
    GlossaryTerms.Similarity,
  ];
  return (
    <AppraisalReviewPage dataHcName={dataHcName}>
      <Section
        dataHcName={`${dataHcName}-section`}
        sectionHeaderProps={{
          title: `Glossary`,
        }}
      >
        <Glossary glossaryTerms={glossaryTerms} />
      </Section>
    </AppraisalReviewPage>
  );
};
