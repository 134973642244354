import React, { Fragment } from 'react';

import { NullState } from '@hcs/pdf/pdf-service';
import { BlockSuperFund, BlockSuperFundWithinMiles, Maybe } from '@hcs/types';

import { useSubjectDocument } from '../../hooks';

import styles from './SuperFundDesc.module.css';

const getSuperFundLabel = (key: keyof BlockSuperFund): string | undefined => {
  switch (key) {
    case 'withinMiles0': {
      return 'Superfund sites where the property is located';
    }
    case 'withinMiles1': {
      return 'Superfund sites within 1 mile of the property';
    }
    case 'withinMiles4': {
      return 'Superfund sites within 4 miles of the property';
    }
    default: {
      return '';
    }
  }
};

export const SuperFundDesc = () => {
  const subjectDoc = useSubjectDocument();
  const superFund = subjectDoc?.data?.propertyState?.blockDetails?.superFund;

  if (!superFund) {
    return <NullState dataHcName={'super-fund-null-state'} />;
  }

  return (
    superFund &&
    Object.keys(superFund).map((key) => {
      const data = superFund[
        key as keyof BlockSuperFund
      ] as Maybe<BlockSuperFundWithinMiles>;
      const superFundLabel = getSuperFundLabel(key as keyof BlockSuperFund);

      return (
        <Fragment key={key}>
          <div>
            <span className={styles.SuperFundKey}>{superFundLabel}: </span>
            <span>{data?.count || 0}</span>
          </div>
          {data?.details &&
            data?.details?.map((detail, idx) => (
              <p key={idx}>
                <span className={styles.Detail}>{`Detail ${idx + 1}:`}</span>
                {detail?.epsSiteid && (
                  <span
                    className={styles.DetailItem}
                  >{`- EPA Site ID: ${detail?.epsSiteid}`}</span>
                )}
                {detail?.link && (
                  <span
                    className={styles.DetailItem}
                  >{`- Link: ${detail?.link}`}</span>
                )}
                {detail?.nplStatus && (
                  <span
                    className={styles.DetailItem}
                  >{`- NPL Status: ${detail?.nplStatus}`}</span>
                )}
                {detail?.siteName && (
                  <span
                    className={styles.DetailItem}
                  >{`- Site Name: ${detail?.siteName}`}</span>
                )}
                {detail?.updatedDate && (
                  <span
                    className={styles.DetailItem}
                  >{`- Updated: ${detail?.updatedDate}`}</span>
                )}
              </p>
            ))}
        </Fragment>
      );
    })
  );
};
