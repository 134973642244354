import React from 'react';
import classNames from 'classnames';

import styles from './WarningIcon.module.css';

interface Props {
  dataHcName: string;
  circle?: boolean;
  className?: string;
}

export const WarningIcon = ({
  dataHcName,
  className,
  circle = false,
}: Props) => (
  <svg
    data-hc-name={dataHcName}
    className={classNames(styles.WarningIcon, className)}
    height="19"
    width="16"
  >
    {circle ? (
      <circle
        data-hc-name={`${dataHcName}-circle`}
        cx="8"
        cy="11"
        r="8"
        fill="#FDB813"
      />
    ) : (
      <polygon
        data-hc-name={`${dataHcName}-polygon`}
        points="8,3 0,17 16,17"
        strokeLinejoin="round"
        stroke="#FDB813"
        fill="#FDB813"
      />
    )}
    <text
      x="50%"
      y="50%"
      dx="-1.2"
      dy="5.5"
      style={{ fill: 'white', font: '500 10px Avenir' }}
    >
      {'!'}
    </text>
  </svg>
);
