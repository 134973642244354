import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './RangeBar.module.css';

export interface RangeBarProps {
  dataHcName: string;
  /** A percentage value for positioning the tick mark, must be between 0 and 100 */
  value?: number;
  /** Labels to be placed beneath the bar */
  bottomLabels?: ReactNode[];
  /** Labels to be placed above the bar */
  topLabels?: ReactNode[];
  /** Colors with which to populate the bar's gradient fill */
  colors?: string[];
  /** Color of the tick */
  tickColor?: string;
  /** Class Name */
  className?: string;
  /** Render small bar */
  small?: boolean;
}

/**
 * A simple bar with a colored gradient and a tick to represent a given value
 */

export const RangeBar = ({
  dataHcName,
  topLabels,
  bottomLabels,
  value,
  colors = ['#c0ee83', '#11ddb3'],
  className,
  tickColor = '#70d48a',
  small,
}: RangeBarProps) => {
  if ((!value && value !== 0) || value > 100 || value < 0) {
    new Error(`value must be of type Number and must be between 0 and 100`);
  }
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.RangeBar, className, {
        [styles.small]: small,
      })}
    >
      {typeof value === 'number' && (
        <div className={styles.TickWrapper}>
          <div
            className={styles.Tick}
            style={{
              left: `${value}%`,
              borderRightColor: tickColor,
            }}
            data-hc-name={`${dataHcName}-tick`}
          />
          {!!topLabels?.length && (
            <div
              className={styles.TopLabels}
              data-hc-name={`${dataHcName}-top-labels`}
            >
              {topLabels.map((label, i) => (
                <span
                  key={`top-labels-${i}`}
                  className={styles.BarLabel}
                  data-hc-name={`${dataHcName}-top-label`}
                >
                  {label}
                </span>
              ))}
            </div>
          )}
        </div>
      )}
      <div
        data-hc-name={`${dataHcName}-bar`}
        className={styles.Bar}
        style={{
          backgroundImage: `linear-gradient(to right, ${colors.join(',')})`,
        }}
      />
      {!!bottomLabels?.length && (
        <div
          className={styles.BottomLabels}
          data-hc-name={`${dataHcName}-bottom-labels`}
        >
          {bottomLabels.map((label, i) => (
            <span
              key={`label-${i}`}
              className={styles.BarLabel}
              data-hc-name={`${dataHcName}-bottom-label`}
            >
              {label}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};
