import { useMemo } from 'react';

import { sortTransactionHistoryEvents } from '@hcs/property-state';
import { ValueTypes } from '@hcs/types';

import { useSubjectTransactionHistoryDocument } from './useSubjectTransactionHistoryDocument';

export const useSubjectTransactionHistoryEvents = (valueType?: ValueTypes) => {
  const { data: propertyTransactionHistory } =
    useSubjectTransactionHistoryDocument() || {};

  return useMemo(() => {
    if (valueType === 'RENTAL') {
      return propertyTransactionHistory?.eventHistoryRental;
    } else if (valueType === 'SOLD') {
      return propertyTransactionHistory?.eventHistorySale;
    } else {
      return [
        ...(propertyTransactionHistory?.eventHistoryRental?.map((e) => {
          return {
            ...e,
            isRental: true,
          };
        }) || []),
        ...(propertyTransactionHistory?.eventHistorySale || []),
      ].sort(sortTransactionHistoryEvents);
    }
  }, [propertyTransactionHistory, valueType]);
};
