import React from 'react';

import { NearbyPropertiesChartsSection } from '../../features/NearbyPropertiesCharts';
import { SubjectDetailsSectionProps } from '../../features/SubjectDetailsSection';
import { SubjectTransactionHistory } from '../../features/SubjectTransactionHistorySection/SubjectTransactionHistorySection';
import { SubjectValueSectionProps } from '../../features/SubjectValueSection/SubjectValueSection';

import { BpoReportPage } from './BpoReportPage';

export interface PropsBpoMarketTransactionsPage {
  propsSubjectDetailsSection?: SubjectDetailsSectionProps;
  propsSubjectValueSection?: SubjectValueSectionProps;
}
const dataHcName = 'bpo-market-transactions-page';
export const BpoMarketTransactionsPage = () => {
  return (
    <BpoReportPage dataHcName={dataHcName}>
      <SubjectTransactionHistory />
      <NearbyPropertiesChartsSection />
    </BpoReportPage>
  );
};
