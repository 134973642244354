import type { ReactNode } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { useComponentDidMount, useVisibleInOverflow } from '@hcs/hooks';
import { AddendumDescriptionType } from '@hcs/types';

import {
  SUBJECT_TRANSACTION_LAST_IDX_ON_PAGE,
  SubjectTransactionHistory,
} from '../SubjectTransactionHistorySection';
import { SuperFundDesc } from '../SuperFundDesc';

import styles from './DescList.module.css';

export type DescItemType = {
  term: ReactNode;
  description: ReactNode;
  source?: ReactNode;
};

type DescListItemProps = {
  item: DescItemType;
  pageIndex: number;
  itemIndex: number;
  onItemOverflow?: (nextPageStartIndex: number) => void;
};

export type DescListProps = {
  list: DescItemType[];
  pageIndex: number;
  onItemOverflow?: (nextPageStartIndex: number) => void;
};

export const DescList = ({
  list = [],
  pageIndex,
  onItemOverflow,
}: DescListProps) => {
  return (
    <>
      {list.map((item, idx) => (
        <DescListItem
          key={idx}
          item={item}
          pageIndex={pageIndex}
          itemIndex={idx}
          onItemOverflow={onItemOverflow}
        />
      ))}
    </>
  );
};

const DescListItem = ({
  item,
  itemIndex,
  onItemOverflow,
}: DescListItemProps) => {
  const descListRef = useRef<HTMLDivElement>(null);
  const { visibleFull } = useVisibleInOverflow(descListRef);
  const [isMounted, setIsMounted] = useState<boolean>(false);
  useComponentDidMount(() => setIsMounted(true));
  useEffect(() => {
    if (!visibleFull && isMounted) {
      onItemOverflow?.(itemIndex);
    }
  }, [visibleFull, isMounted, itemIndex, onItemOverflow]);

  return (
    <div
      className={classNames(styles.DescriptionContainer, {
        [styles.hidden]: !visibleFull && isMounted,
      })}
      ref={descListRef}
    >
      <div className={styles.Title}>{item.term}</div>
      <div className={styles.Description}>
        {item.description === AddendumDescriptionType.SuperFund ? (
          <SuperFundDesc />
        ) : item.description === AddendumDescriptionType.TransactionHistory ? (
          <SubjectTransactionHistory
            hideHeader
            displayFields={[
              'date',
              'status',
              'status',
              'source',
              'price',
              'type',
            ]}
            sliceStartIndex={SUBJECT_TRANSACTION_LAST_IDX_ON_PAGE + 1}
          />
        ) : (
          <p>{item.description}</p>
        )}
        {item.source && (
          <div className={styles?.Source}>Source: {item.source}</div>
        )}
      </div>
    </div>
  );
};
