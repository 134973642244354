import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { RangeBar, RangeBarProps } from '../RangeBar/RangeBar';

import styles from './ValueRangeChart.module.css';

const DEFAULT_LABELS = {
  LOW: 'Low',
  HIGH: 'High',
};

interface Value {
  label: ReactNode;
  value: ReactNode;
  secondary: ReactNode;
}
export type ValueRangeChartProps = {
  dataHcName: string;
  /** A component to be displayed next to the value to provide more information
   * about the value */
  ValueBadge?: ReactNode;
  // Text content or a component functioning as a label for each value
  valuesLow: Value;
  valuesMiddle: Value;
  valuesHigh: Value;
  /** Colors with which to populate the bar's gradient fill */
  colors?: RangeBarProps['colors'];
  /** Class name */
  className?: string;
  /** Only show the middle values */
  valueOnly?: boolean;
};

/**
 * Displays a simple chart with low, middle, and high values and optional corresponding labels
 */

export const ValueRangeChart = ({
  valuesLow,
  valuesMiddle,
  valuesHigh,
  className,
  ValueBadge,
  colors = ['#c0ee83', '#11ddb3'],
  dataHcName,
  valueOnly,
}: ValueRangeChartProps) => {
  return (
    <div
      className={classNames(styles.ValueRangeChart, className)}
      data-hc-name={dataHcName}
    >
      <div className={styles.MiddleValueContainer}>
        <div>
          {valuesMiddle.label && (
            <div
              className={styles.MiddleValueLabel}
              data-hc-name={`${dataHcName}-middle-label`}
            >
              {valuesMiddle.label}
            </div>
          )}
          <div
            className={styles.MiddleValue}
            data-hc-name={`${dataHcName}-middle-value`}
          >
            {valuesMiddle.value}
            {ValueBadge && (
              <div
                className={styles.ValueBadgeContainer}
                data-hc-name={`${dataHcName}-value-badge`}
              >
                {ValueBadge}
              </div>
            )}
          </div>
          {valuesMiddle.secondary && (
            <div
              data-hc-name={`${dataHcName}-middle-secondary`}
              className={styles.MiddleValueSecondary}
            >
              {valuesMiddle.secondary}
            </div>
          )}
        </div>
      </div>
      {!valueOnly && (
        <>
          <RangeBar
            dataHcName={`${dataHcName}-range-bar`}
            value={50}
            topLabels={[
              valuesLow.label || DEFAULT_LABELS.LOW,
              valuesHigh.label || DEFAULT_LABELS.HIGH,
            ]}
            colors={colors}
            tickColor={colors[colors.length - 1]}
            small
          />
          <div className={styles.LowHighValueContainer}>
            <div>
              <div
                data-hc-name={`${dataHcName}-low-value`}
                className={styles.LowValue}
              >
                {valuesLow.value}
              </div>
              {valuesLow.secondary && (
                <div
                  data-hc-name={`${dataHcName}-low-secondary`}
                  className={styles.LowValueSecondary}
                >
                  {valuesLow.secondary}
                </div>
              )}
            </div>
            <div>
              <div
                data-hc-name={`${dataHcName}-high-value`}
                className={styles.HighValue}
              >
                {valuesHigh.value}
              </div>
              {valuesHigh.secondary && (
                <div
                  data-hc-name={`${dataHcName}-high-secondary`}
                  className={styles.HighValueSecondary}
                >
                  {valuesHigh.secondary}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
