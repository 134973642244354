import React from 'react';
import { AxisTickProps } from '@nivo/axes/dist/types/types';

import { Section } from '@hcs/pdf/pdf-service';
import { Tick } from '@hcs/report-api';

import { usePricingMarketStrategyComments } from '../../hooks/usePricingMarketStrategyComments';
import { ForecastChart, SimilarPropertiesChart } from '..';

import styles from './BpoConclusionContinuedSection.module.css';

const dataHcName = 'conclusion-continued';
export const BpoConclusionContinuedSection = () => {
  const PriceMarketStrategy = usePricingMarketStrategyComments();
  const comment =
    PriceMarketStrategy[0]?.data.comment ??
    'Market strategy comment is currently unavailable.';

  const axisLeftRenderTick = (props: AxisTickProps<number>) => {
    const { value } = props;
    if (value % 2 === 0) {
      return <Tick {...props} />;
    } else {
      return <span />;
    }
  };

  return (
    <Section
      dataHcName={`${dataHcName}-section`}
      sectionHeaderProps={{ title: 'Conclusion Continued' }}
    >
      <ForecastChart chartSize="large" />
      <SimilarPropertiesChart
        axisLeft={{
          legend: '# of Properties',
          renderTick: axisLeftRenderTick,
        }}
        axisBottom={{
          legend: 'Price',
        }}
      />
      <p>
        <strong className={styles.CommentHeader}>
          Pricing Market Strategy Comments
        </strong>
      </p>
      <p>{comment}</p>
    </Section>
  );
};
