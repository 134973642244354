import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './Stats.module.css';

export interface StatsProps {
  className?: string;
  dataHcName: string;
  stats: { label: ReactNode; value: ReactNode; dataHcName: string }[];
}

export const Stats = ({ dataHcName, className, stats }: StatsProps) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.Stats, className)}
    >
      {stats.map((stat, i) => {
        const dataHcNameStat = `${dataHcName}-${stat.dataHcName}-${i}`;
        return (
          <div
            key={dataHcNameStat}
            data-hc-name={dataHcNameStat}
            className={styles.Stat}
          >
            <div data-hc-name={`${dataHcNameStat}`} className={styles.Label}>
              {stat.label}
            </div>
            <div data-hc-name={`${dataHcNameStat}`} className={styles.Value}>
              {stat.value}
            </div>
          </div>
        );
      })}
    </div>
  );
};
