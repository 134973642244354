import {
  PdfServicePayload,
  PdfServiceReports,
  PdfTemplateIds,
} from '@hcs/types';

export const usePdfState = <D extends PdfServiceReports>() => {
  return {
    pdfTemplateId: window.PDF_TEMPLATE_ID as PdfTemplateIds | undefined,
    data: window.PDF_DATA as PdfServicePayload<D>,
    pdfOptions: window.PDF_OPTIONS,
  };
};
