import { DocumentRoles, MarketAnalysisDocument } from '@hcs/types';
import { ReportApiReports } from '@hcs/types';

import { useSingleDocumentRole } from './useSingleDocumentRole';

export const useMarketAnalysisDocument = <Data extends ReportApiReports>() => {
  const marketAnalysisDocument = useSingleDocumentRole<
    MarketAnalysisDocument,
    Data
  >(DocumentRoles.MarketAnalysis);
  return marketAnalysisDocument;
};
