import { DocumentRoles, SimilarPropertiesChartDocument } from '@hcs/types';
import { ReportApiReports } from '@hcs/types';

import { useSingleDocumentRole } from './useSingleDocumentRole';

export const useSimilarPropertiesChartDocument = <
  Data extends ReportApiReports
>() => {
  return useSingleDocumentRole<SimilarPropertiesChartDocument, Data>(
    DocumentRoles.SimilarPropertiesChart
  );
};
