import { computeRiskSchema } from '@hcs/report-api';
import {
  BiasRiskDocument,
  DocumentRoles,
  SchemaIds,
  ValuationMethod,
} from '@hcs/types';

import { useAppraisalCompDataSourcesDocuments } from '../hooks/useAppraisalCompDataSourcesDocuments';
import { useAppraisalCompDocuments } from '../hooks/useAppraisalCompDocuments';
import { useAppraisalSubjectDocument } from '../hooks/useAppraisalSubjectDocument';
import { useAppraisalSubjectValueDocument } from '../hooks/useAppraisalSubjectValueDocument';
import { useCompsForAppraisalCompare } from '../hooks/useCompsForAppraisalCompare';
import { useSubjectDataSourcesDocument } from '../hooks/useSubjectDataSourcesDocument';
import { useSubjectDocument } from '../hooks/useSubjectDocument';
import { useSubjectValueDocument } from '../hooks/useSubjectValueDocument';

export const useAppraisalComparisonDocument = () => {
  const subjectDoc = useSubjectDocument();
  const hcSubject = useSubjectDataSourcesDocument()?.data.hc;
  const subjectValueDoc = useSubjectValueDocument();
  const appraisalSubjectDoc = useAppraisalSubjectDocument();
  const compDocs = useCompsForAppraisalCompare();
  const appraisalCompDocs = useAppraisalCompDocuments();
  const appraisalCompsDataSourcesDocs = useAppraisalCompDataSourcesDocuments();
  const appraisalSubjectValueDoc = useAppraisalSubjectValueDocument();

  const biasRiskDocument: BiasRiskDocument = {
    ...subjectDoc,
    role: DocumentRoles.AppraisalComparison,
    schemaId: SchemaIds.BiasRisk,
    data: {
      ...computeRiskSchema({
        hcSubject,
        hcSubjectValue: subjectValueDoc?.data,
        appraisalSubject: appraisalSubjectDoc?.data.propertyState,
        appraisalSubjectValue: appraisalSubjectValueDoc?.data,
        appraisalCompDocs,
        appraisalCompsDataSourcesDocs,
        compDocs,
      }),
      // Comparison of appraisal value to HC values
      valueComparison: {
        [ValuationMethod.Avm]: {
          // (appraisal_subject_value.userValue.value.value - subject_value.avm.value.value) / subject_value.avm.value.value
          diffPct: 0.4232,
          // appraisal_subject_value.userValue.value.value - subject_value.avm.value.value
          diffVal: 100,
        },
        [ValuationMethod.Adjusted]: {
          // (appraisal_subject_value.userValue.value.value - subject_value.adjustedAvm.value.value) / subject_value.adjustedAvm.value.value
          diffPct: 0.4232,
          // appraisal_subject_value.userValue.value.value - subject_value.adjustedAvm.value.value
          diffVal: 100,
        },
        [ValuationMethod.Comps]: {
          // (appraisal_subject_value.userValue.value.value - subject_value.comparableValue.value.value) / subject_value.comparableValue.value.value
          diffPct: 0.4232,
          // appraisal_subject_value.userValue.value.value - subject_value.comparableValue.value.value
          diffVal: 100,
        },
      },
    },
  };
  return biasRiskDocument;
};
