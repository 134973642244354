import React from 'react';

import { SubContent } from '@hcs/pdf/pdf-service';

import { useReconciliationCommentsDocument } from '../../hooks/useReconciliationCommentsDocument';

import styles from './ReconciliationComments.module.css';
export const ReconciliationComments = () => {
  const reconciliationComments = useReconciliationCommentsDocument();
  const comment =
    reconciliationComments?.length > 0
      ? reconciliationComments[0]?.data.comment
      : 'Reconciliation comment is currently unavailable.';

  return (
    <div className={styles.ReconciliationComments}>
      <SubContent>Reconciliation Comments</SubContent>
      <p>{comment}</p>
    </div>
  );
};
