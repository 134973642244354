import React from 'react';

import { NoContent } from '@hcs/design-system';
import { Section } from '@hcs/pdf/pdf-service';
import { SimilarPropertiesChartLegend } from '@hcs/report-api';

import { SimilarPropertiesChart } from '../..//features/SimilarPropertiesChart';
import { useSimilarPropertiesChartDocument } from '../..//hooks/useSimilarPropertiesChartDocument';

const dataHcName = 'similar-properties-section';
export const SimilarPropertiesSection = () => {
  const similarPropsDoc = useSimilarPropertiesChartDocument();
  return (
    <Section
      dataHcName={dataHcName}
      sectionHeaderProps={{ title: 'Similar Properties' }}
    >
      {similarPropsDoc?.data.chart?.buckets.length ? (
        <>
          <SimilarPropertiesChartLegend />
          <SimilarPropertiesChart />
        </>
      ) : (
        <div style={{ width: '50%', margin: '150px auto' }}>
          <NoContent dataHcName={`${dataHcName}-null`}>
            Not Enough Highly Similar Comparables
          </NoContent>
        </div>
      )}
    </Section>
  );
};
