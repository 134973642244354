import React from 'react';

import { Section } from '@hcs/pdf/pdf-service';
import { formatMissing } from '@hcs/utils';

import { useMlsListingSheetData } from '../../hooks/useMlsListingSheetData';

const dataHcName = 'listing-remarks-section';

export const ListingRemarksSection = () => {
  const { rentalListing, propertyStateCore } = useMlsListingSheetData();
  const publicRemarks = propertyStateCore.propertyState?.[
    rentalListing ? 'listingDetailsRental' : 'listingDetailsSale'
  ]?.remarks?.find((r) => r?.type === 'Public');
  return (
    <Section
      sectionHeaderProps={{ title: 'Remarks' }}
      dataHcName={dataHcName}
      compact
    >
      <p data-hc-name={`${dataHcName}-public-remarks`}>
        {formatMissing(publicRemarks?.value)}
      </p>
    </Section>
  );
};
