import { DocumentRoles, HcSuggestedCompPhotosDocument } from '@hcs/types';
import { ReportApiReports } from '@hcs/types';

import { useDocumentRole } from './useDocumentRole';

export const useHcSuggestedCompPhotosDocuments = <
  Data extends ReportApiReports
>() => {
  return useDocumentRole<HcSuggestedCompPhotosDocument, Data>(
    DocumentRoles.HcSuggestedCompPhotos
  );
};
