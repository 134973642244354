import React from 'react';

import { NullState } from '@hcs/pdf/pdf-service';
import { Stats } from '@hcs/pdf/pdf-service';
import { formatMarketRiskLevel, NULL_VALUE } from '@hcs/utils';

import { useMarketAnalysisDocument } from '../../hooks/useMarketAnalysisDocument';

interface Props {
  className?: string;
}
const dataHcName = 'market-risk-stats';
export const MarketRiskStats = ({ className }: Props) => {
  const marketAnalysisDoc = useMarketAnalysisDocument();
  if (
    !marketAnalysisDoc?.data?.risk.current ||
    !marketAnalysisDoc.data.risk.score
  ) {
    return <NullState dataHcName={`${dataHcName}-error`} />;
  }
  const { score, lastYear, current } = marketAnalysisDoc.data.risk;
  return (
    <Stats
      className={className}
      dataHcName={dataHcName}
      stats={[
        {
          label: 'Risk of Decline',
          value: `${(current * 100).toFixed(1)}%`,
          dataHcName: 'year-current',
        },
        {
          label: 'Risk Level',
          value: formatMarketRiskLevel(score),
          dataHcName: 'risk-level',
        },
        {
          label: 'This month last year',
          value: `${lastYear ? `${(lastYear * 100).toFixed(1)}%` : NULL_VALUE}`,
          dataHcName: 'year-prev',
        },
      ]}
    />
  );
};
