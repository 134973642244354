import { sortCompDocuments } from '@hcs/report-api';
import { CompTypes, ListingStatus, Top4Comp } from '@hcs/types';

import { useActiveTop4CompDocuments } from './useActiveTop4CompDocuments';
import { useClosedTop4CompDocuments } from './useClosedTop4CompDocuments';

export type CompsStatus = ListingStatus.ACTIVE | ListingStatus.CLOSED;

export interface Top4CompData {
  comps: Top4Comp[];
}

interface Props {
  compsStatus?: CompsStatus;
  compType: CompTypes;
}
export const useTop4Comps = ({
  compsStatus,
  compType,
}: Props): Top4CompData => {
  const closedTop4CompDocuments = useClosedTop4CompDocuments();
  const activeTop4CompDocuments = useActiveTop4CompDocuments();
  const compDocuments =
    compsStatus === ListingStatus.ACTIVE
      ? activeTop4CompDocuments
      : compsStatus === ListingStatus.CLOSED
      ? closedTop4CompDocuments
      : // return all top 4 active and closed comps if no status is provided
        [...activeTop4CompDocuments, ...closedTop4CompDocuments];
  const comps: Top4Comp[] =
    compDocuments.sort(sortCompDocuments).map((compDocument) => ({
      documentId: compDocument.documentId,
      compSchema: compDocument.data,
      compIdentifier: {
        type: 'compId',
        compId: compDocument.data.compID,
        compType,
      },
    })) || [];

  return {
    comps,
  };
};
