import React from 'react';

import { Section, TwoColSection, TwoColTable } from '@hcs/pdf/pdf-service';
import { formatMoney } from '@hcs/utils';

import { useMarketAnalysisDocument } from '../../hooks';
import { useInspectionDocument } from '../../hooks/useInspectionDocument';
import { useSaleToListPriceRatioChartDocument } from '../../hooks/useSaleToListPriceRatioChartDocument';
import { useSubjectFlood } from '../../hooks/useSubjectFlood';
import { DaysOnMarketChart } from '../DaysOnMarketChart';
import { MonthsOfSupplyChart } from '../MonthsOfSupplyChart';

import styles from './BpoNeighborhoodMarketabilitySection.module.css';

const getPercentage = (value: number | undefined) =>
  value ? `${(value * 100).toFixed(1)}%` : '';
const dataHcName = 'bpo-neighborhood-marketability-section';
export const BpoNeighborhoodMarketabilitySection = () => {
  const marketAnalysisDoc = useMarketAnalysisDocument();
  const subjectFlood = useSubjectFlood();
  const saleToList = useSaleToListPriceRatioChartDocument();
  const inspectionDoc = useInspectionDocument();
  const neighborhoodDescription =
    inspectionDoc?.data?.neighborhoodDescription || 'N/A';

  const { effectiveDate, floodRisk, zone, mapId } = subjectFlood[0].data || {
    effectiveDate: '',
    floodRisk: '',
    zone: '',
    mapId: '',
  };
  const { min, max } = saleToList?.data?.chart?.stats
    ?.neighborhoodPriceRange || {
    min: 0,
    max: 0,
  };
  const { current, score } = marketAnalysisDoc?.data?.risk || {
    current: 0,
    score: '',
  };

  return (
    <Section
      dataHcName={dataHcName}
      sectionHeaderProps={{
        title: 'Neighborhood & Subject Marketability',
      }}
    >
      <TwoColSection
        dataHcName={`${dataHcName}-tables`}
        className={styles.MarketabilitySection}
        contentLeft={
          <TwoColTable
            dataHcName={`${dataHcName}-left`}
            data={[
              {
                label: 'Urban/Suburban/Rural',
                value: neighborhoodDescription,
                dataHcName: 'urban-suburban-rural',
              },
              {
                label: 'FEMA Flood',
                value: (
                  <>
                    <p>Effective Date: {effectiveDate}</p>
                    <p>Flood Risk: {floodRisk}</p>
                    <p>Flood Zone: {zone}</p>
                    <p>Map #: {mapId}</p>
                  </>
                ),
                dataHcName: 'fema-flood',
              },
            ]}
          />
        }
        contentRight={
          <>
            <TwoColTable
              dataHcName={`${dataHcName}-right`}
              data={[
                {
                  label: 'Neighborhood Price Range',
                  value: `${formatMoney(min)} - ${formatMoney(max)}`,
                  dataHcName: 'neighborhood-price-range',
                },
                {
                  label: 'MSA 1 Year Risk of Decline',
                  value: `${
                    current
                      ? `${getPercentage(current)} ${
                          score && score.toLowerCase()
                        }`
                      : 'N/A'
                  }`,
                  dataHcName: 'msa-1-year-risk-of-decline',
                },
              ]}
            />
            <p id={styles.Disclaimer}>
              Probability this market's median home prices will be lower 12
              months from the current market median price
            </p>
          </>
        }
      />
      <TwoColSection
        dataHcName={`${dataHcName}-charts`}
        contentLeft={<MonthsOfSupplyChart />}
        contentRight={<DaysOnMarketChart />}
      />
    </Section>
  );
};
