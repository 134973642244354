import { ReactNode } from 'react';

import { HouseCanaryLogomarkFullColor } from '@hcs/design-system';

import styles from './Title.module.css';

interface Props {
  children: ReactNode;
  withLogoMark?: boolean;
  description?: ReactNode;
}
export const Title = ({
  children,
  description,
  withLogoMark = true,
}: Props) => {
  return (
    <>
      <div className={styles.Title}>
        {withLogoMark && (
          <HouseCanaryLogomarkFullColor
            dataHcName={`logomark`}
            height="50px"
            width="50px"
          />
        )}
        <h1 className={styles.TitleText}>{children}</h1>
      </div>
      {description && <div className={styles.Description}>{description}</div>}
    </>
  );
};
