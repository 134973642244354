import React from 'react';

import { PropertyStateFields } from '@hcs/types';

import { ReportApiValuePdfWithProps } from './ReportApiValuePdf';

export const ReportApiLegacyPdf = () => {
  return (
    <ReportApiValuePdfWithProps
      propsPropsPexpSubjectDetailsPage={{
        propsSubjectDetailsSection: {
          columnConfigsLeft: [
            PropertyStateFields.propertyType,
            PropertyStateFields.bedrooms,
            PropertyStateFields.bathrooms,
            PropertyStateFields.livingArea,
          ],
          columnConfigsRight: [
            PropertyStateFields.yearBuilt,
            PropertyStateFields.lotSize,
            PropertyStateFields.ownerOccupied,
            PropertyStateFields.condition,
          ],
        },
      }}
    />
  );
};
