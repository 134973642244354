import React from 'react';

import { Section } from '@hcs/pdf/pdf-service';
import { CompFields, CompTypes, PropertyStateFields } from '@hcs/types';

import { CompsMap } from '../../features/CompsMap';
import { CompsTable } from '../../features/CompsTable';
import { useSubjectDocument } from '../../hooks/useSubjectDocument';
import { useTop4Comps } from '../../hooks/useTop4Comps';

import { BpoReportPage } from './BpoReportPage';

const FIELDS = [
  CompFields.distance,
  PropertyStateFields.currentListingPrice,
  PropertyStateFields.lastClosePrice,
  PropertyStateFields.currentStatus,
  PropertyStateFields.currentStatusDate,
  PropertyStateFields.saleType,
  PropertyStateFields.livingArea,
  PropertyStateFields.bedrooms,
  PropertyStateFields.bathrooms,
  PropertyStateFields.age,
  PropertyStateFields.lotSize,
];

export const Top4CompsMapPage = () => {
  const { comps } = useTop4Comps({ compType: CompTypes.Sold });
  const subjectDocument = useSubjectDocument();
  const dataHcName = 'top-4-comps-map-page';
  const subjectSchema = subjectDocument.data;
  // Do not render page if no comps
  if (!comps.length) return null;
  const compSchemas = comps.map((compDoc) => compDoc.compSchema);

  return (
    <BpoReportPage dataHcName={dataHcName} key={dataHcName}>
      <Section
        sectionHeaderProps={{
          title: 'Sold & Listing Comparables Map',
        }}
        dataHcName={`${dataHcName}-section`}
      >
        <CompsMap compType={CompTypes.Sold} comps={comps} />
        <CompsTable
          dataHcName={`${dataHcName}-table`}
          subjectSchema={subjectSchema}
          rows={compSchemas.map((compSchema) => ({ compSchema }))}
          fields={FIELDS}
        />
      </Section>
    </BpoReportPage>
  );
};
