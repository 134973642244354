import React from 'react';

import { ChartSizes } from '@hcs/types';

import { NearbyPropertiesChart } from './NearbyPropertyChart';

const label = 'Age';
interface Props {
  className?: string;
  chartSize?: ChartSizes;
}
export const NearbyPropertiesChartAge = (props: Props) => (
  <NearbyPropertiesChart
    {...props}
    xAxisLabel="Ages"
    nearbyChartKey="age"
    label={label}
  />
);
