import React, { ReactNode } from 'react';

import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import { PropertyStateArgsProps, PropertyStateFields } from '@hcs/types';
import { UserPropertyAdjustments } from '@hcs/types';
import { MULTI_FIELD_DELIMITER } from '@hcs/utils';

import { UserPropertyAdjustedBadge } from '../PropertyCompareTable';
import { TwoColTable, TwoColTableDataItem } from '../TwoColTable';

import styles from './PropertyTwoColTable.module.css';

export interface PropertyTwoColTableProps extends PropertyStateArgsProps {
  dataHcName: string;
  fields: (PropertyStateFields | PropertyStateFields[])[];
  userPropertyAdjustments?: UserPropertyAdjustments;
}

export const PropertyTwoColTable = ({
  dataHcName,
  fields,
  propertyStateArgs,
  userPropertyAdjustments,
}: PropertyTwoColTableProps) => {
  return (
    <TwoColTable
      dataHcName={dataHcName}
      data={fields.map((item): TwoColTableDataItem => {
        if (Array.isArray(item)) {
          const result: {
            dataHcName: string;
            label: string[];
            value: ReactNode[];
          } = {
            dataHcName: `${dataHcName}`,
            label: [],
            value: [],
          };

          item.forEach((field) => {
            const { labelShort, Display } = PROPERTY_STATE_FIELD_CONFIGS[field];
            const fieldUserAdjustment =
              userPropertyAdjustments?.adjustments?.[field];
            result.dataHcName += `-${field}`;
            result.label.push(labelShort);
            result.value.push(
              <span className={styles.FieldContainer}>
                <span className={styles.DisplayContainer}>
                  <Display propertyStateArgs={propertyStateArgs} />
                </span>
                {fieldUserAdjustment && (
                  <span className={styles.BadgeContainer}>
                    <UserPropertyAdjustedBadge
                      dataHcName={`${dataHcName}-adjustment-field`}
                    />
                  </span>
                )}
              </span>
            );
          });
          return {
            dataHcName: dataHcName,
            label: result.label.join(MULTI_FIELD_DELIMITER),
            value: (
              <>
                {result.value.map((r, i) => {
                  const keystr = `${dataHcName}-f-${i}-${r?.toString()}`;
                  if (i < result.value.length - 1) {
                    return (
                      <React.Fragment key={keystr}>
                        {r} {MULTI_FIELD_DELIMITER}
                      </React.Fragment>
                    );
                  } else {
                    return <React.Fragment key={keystr}>{r}</React.Fragment>;
                  }
                })}
              </>
            ),
          };
        } else {
          // Just for consistency w/ above logic since we now know this is a PropertyStateFields field
          const field = item;
          const { labelShort, Display } = PROPERTY_STATE_FIELD_CONFIGS[field];
          const fieldUserAdjustment =
            userPropertyAdjustments?.adjustments?.[field];
          return {
            dataHcName: `${dataHcName}-${field}`,
            label: labelShort,
            value: (
              <span className={styles.FieldContainer}>
                <span className={styles.DisplayContainer}>
                  <Display propertyStateArgs={propertyStateArgs} />
                </span>
                {fieldUserAdjustment && (
                  <span className={styles.BadgeContainer}>
                    <UserPropertyAdjustedBadge
                      dataHcName={`${dataHcName}-adjustment-field`}
                    />
                  </span>
                )}
              </span>
            ),
          };
        }
      })}
    />
  );
};
