import React from 'react';

import { Section } from '@hcs/pdf/pdf-service';
import { TwoColTable } from '@hcs/pdf/pdf-service';
import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import { PropertyStateFields } from '@hcs/types';
import {
  formatBoolYesNo,
  formatList,
  formatListingFireplace,
} from '@hcs/utils';

import { useMlsListingSheetData } from '../../hooks/useMlsListingSheetData';

const dataHcName = 'listing-features-section';
export const ListingFeaturesSection = () => {
  const { reso, propertyStateCore } = useMlsListingSheetData();
  return (
    <Section
      dataHcName={dataHcName}
      sectionHeaderProps={{ title: 'Features' }}
      compact
    >
      <TwoColTable
        smallLabels
        compact
        dataHcName={`${dataHcName}-table`}
        data={[
          {
            dataHcName: 'heating',
            label: 'Heating',
            value: formatBoolYesNo(reso?.propertyDetails?.heatingYN),
          },
          {
            dataHcName: 'cooling',
            label: 'Cooling',
            value: formatBoolYesNo(reso?.propertyDetails?.coolingYN),
          },
          {
            dataHcName: 'appliances',
            label: 'Appliances',
            value: formatList(reso?.propertyDetails?.appliances),
          },
          {
            dataHcName: 'interior-description',
            label: 'Interior Description',
            value: formatList(reso?.propertyDetails?.interiorFeatures),
          },
          {
            dataHcName: 'exterior-description',
            label: 'Exterior Description',
            value: formatList(reso?.propertyDetails?.exteriorFeatures),
          },
          PropertyStateFields.basement,
          {
            dataHcName: 'fireplace',
            label: 'Fireplace',
            value: formatListingFireplace(reso?.propertyDetails),
          },
          PropertyStateFields.parking,
          PropertyStateFields.associationAmenities,
          {
            dataHcName: 'energy-efficient-features',
            label: 'Energy Efficient Features',
            value: formatList(reso?.propertyDetails?.greenEnergyEfficient),
          },
        ].map((field) => {
          if (typeof field === 'string') {
            const { Display, label } = PROPERTY_STATE_FIELD_CONFIGS[field];
            return {
              dataHcName: `${dataHcName}-${field}`,
              value: <Display propertyStateArgs={propertyStateCore} />,
              label,
            };
          } else {
            return field;
          }
        })}
      />
    </Section>
  );
};
