import {
  ActiveTop4CompPhotosDocument,
  ClosedTop4CompPhotosDocument,
  PredictionGroupType,
  SubjectPhotosDocument,
} from '@hcs/types';

export const getPredictionGroupPhoto = (
  type: PredictionGroupType,
  photos:
    | SubjectPhotosDocument
    | ActiveTop4CompPhotosDocument
    | ClosedTop4CompPhotosDocument
    | undefined
) => {
  return (
    photos?.data?.listingPhotos?.[0]?.media?.images?.find(
      (photo) => photo.derived?.classification?.predictionGroup === type
    )?.url || null
  );
};
