import React, { useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import L from 'leaflet';
import PropTypes from 'prop-types';

import { forceLayoutMarkerPositions, MarkerProperty } from '@hcs/maps';
import { SubjectMarker } from '@hcs/pdf/pdf-service';
import { Comp, SimilarityLevel } from '@hcs/types';
import { locationToGeoLocation } from '@hcs/utils';

import { useSubjectDocument } from '../../hooks/useSubjectDocument';

type LabelSvgPropTypes = {
  label?: number | string | null;
  similarityLevel?: 'high' | 'moderate' | 'low' | null;
  radius?: number;
};

const getFill = (similarityLevel: LabelSvgPropTypes['similarityLevel']) =>
  ({
    HIGH: '#41D782',
    MODERATE: '#FEB913',
    LOW: '#FF8253',
  }[(similarityLevel || '').toUpperCase()]);

const LabelSvg = ({
  label,
  similarityLevel,
  radius = 12,
}: LabelSvgPropTypes) => {
  const largeLabel =
    typeof label === 'string'
      ? label.length < 2
      : typeof label === 'number'
      ? label < 10
      : '';
  return (
    <svg
      width={radius * 2}
      height={radius * 2}
      viewBox={`0 0 ${radius * 2} ${radius * 2}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        id="svg_4"
        ry={radius}
        rx={radius}
        cy={radius}
        cx={radius}
        fill={getFill(similarityLevel)}
        opacity="0.3"
      />
      <ellipse
        id="svg_5"
        ry={radius * 0.6667}
        rx={radius * 0.6667}
        cy={radius}
        cx={radius}
        fill={getFill(similarityLevel)}
      />
      {label && (
        <text
          x={radius * 0.58}
          y={radius * 1.25}
          dx={largeLabel ? radius * 0.208 : 0}
          style={{
            fontSize: radius * 0.75,
            fill: '#FFF',
          }}
        >
          {label}
        </text>
      )}
    </svg>
  );
};
const tmpLegacySimilarityLevel = (
  similarityLevel?: SimilarityLevel | null | ''
): 'high' | 'moderate' | 'low' | undefined =>
  similarityLevel
    ? (similarityLevel.toLowerCase() as 'high' | 'moderate' | 'low')
    : undefined;
type FeatureGroupContext = {
  map: unknown;
};

interface Props {
  comps: Comp[];
}
export const CompsMapMarkers = (
  { comps }: Props,
  context: FeatureGroupContext
) => {
  const subjectDoc = useSubjectDocument();
  const geoLocation = locationToGeoLocation(
    subjectDoc.data.propertyState.location
  );
  const compMarkerInputs: MarkerProperty[] = [];
  comps.forEach((comp, idx) => {
    const compGeoLocation = locationToGeoLocation(
      comp.compSchema.propertyState.location
    );
    if (compGeoLocation) {
      compMarkerInputs.push({
        geoLocation: compGeoLocation,
        similarityLevel: tmpLegacySimilarityLevel(
          comp.compSchema.similarity.levelAdjusted
        ),
        order: idx,
        listingStatus: 'Sold' as 'Sold' | 'Active' | null,
      });
    }
  });
  const compMarkers = geoLocation
    ? forceLayoutMarkerPositions([
        {
          geoLocation,
        },
        ...compMarkerInputs,
      ])
    : [];
  const PointLayers = compMarkers.map(({ propertyPosition }) =>
    L.marker(propertyPosition, {
      icon: L.divIcon({
        className: 'marker__container',
        iconSize: [6, 6],
        iconAnchor: [3, 3],
      }),
    })
  );
  const LineLayers = compMarkers.map(({ labelPosition, propertyPosition }) =>
    L.polyline([propertyPosition, labelPosition], {
      weight: 1,
      color: 'black',
    })
  );
  const LabelLayers = compMarkers.map(
    ({ labelPosition, similarityLevel, id }) =>
      id === 0
        ? L.marker(labelPosition, {
            icon: L.divIcon({
              className: 'label__container',
              iconSize: [22, 22],
              iconAnchor: [11, 11],
              // SECURITY: only rendering static SVG
              // eslint-disable-next-line xss/no-mixed-html
              html: renderToString(
                <SubjectMarker
                  style={{
                    height: '22px',
                    width: '22px',
                  }}
                />
              ),
            }),
          })
        : L.marker(labelPosition, {
            icon: L.divIcon({
              className: 'label__container',
              iconSize: [22, 22],
              iconAnchor: [11, 11],
              // SECURITY: only rendering static SVG
              // eslint-disable-next-line xss/no-mixed-html
              html: renderToString(
                <LabelSvg label={id} similarityLevel={similarityLevel} />
              ),
            }),
          })
  );
  useEffect(() => {
    const featureGroup = L.featureGroup([
      ...PointLayers,
      ...LineLayers,
      ...LabelLayers,
    ]).addTo(context.map as L.Map);
    // hack to fix DNV-488 https://housecanary.atlassian.net/browse/DNV-448
    // the hcmaps-leaflet sets the position using a timeout which sets the bounds
    // we want to reset them here, so we also have to use setTimeout
    // better solution would be to do Layers how the library expects/intends (as children that the Map component renders)
    setTimeout(() => {
      (context.map as L.Map).fitBounds(featureGroup.getBounds());
    });
  }, []);
  return null;
};
// Note: Relies on the deprecated context api since hcmaps-leaflet uses this api
// to provide the map context. This api will be removed in React v17
// https://reactjs.org/docs/legacy-context.html
CompsMapMarkers.contextTypes = {
  map: PropTypes.object,
};
