import React from 'react';

import {
  PdfTemplateRenderer,
  PdfTemplateRendererProps,
} from './PdfTemplateRenderer';

// For rending a PDF template outside of a typical HCS Web App
export const PdfTemplateRendererPdf = (props: PdfTemplateRendererProps) => {
  return <PdfTemplateRenderer logDebugInfo {...props} />;
};
