import { DocumentRoles, SubjectDataSourcesDocument } from '@hcs/types';
import { ReportApiReports } from '@hcs/types';

import { useSingleDocumentRole } from '../hooks/useSingleDocumentRole';

export const useSubjectDataSourcesDocument = <
  Data extends ReportApiReports
>() => {
  return useSingleDocumentRole<SubjectDataSourcesDocument, Data>(
    DocumentRoles.SubjectDataSources
  );
};
