import React from 'react';
import classNames from 'classnames';

import styles from './Table.module.css';

type Props = {
  dataHcName: string;
  className?: string;
} & React.DetailedHTMLProps<
  React.TableHTMLAttributes<HTMLTableElement>,
  HTMLTableElement
>;

export const Table = ({ dataHcName, className, children, ...props }: Props) => (
  <table
    data-hc-name={dataHcName}
    className={classNames(styles.Table, className)}
    {...props}
  >
    {children}
  </table>
);
