import React from 'react';
import classNames from 'classnames';

import { useGlossaryDefinitions } from '@hcs/glossary';
import {
  GlossaryTermAvm,
  GlossaryTermCompValue,
  GlossaryTerms,
} from '@hcs/types';

import styles from './Glossary.module.css';

interface Props {
  glossaryTerms: (GlossaryTerms | GlossaryTermAvm | GlossaryTermCompValue)[];
  className?: string;
}
const dataHcName = 'glossary';
const glossaryItem = `${dataHcName}-item`;

export const Glossary = ({ glossaryTerms, className }: Props) => {
  const glossaryValues = useGlossaryDefinitions(glossaryTerms);
  return (
    <dl
      className={classNames(styles.GlossaryList, className)}
      data-hc-name={dataHcName}
    >
      {glossaryValues.map((term, i) => {
        return (
          <div
            key={`glossary-${i}`}
            data-hc-name={glossaryItem}
            className={styles.GlossaryTerm}
          >
            {term.title && (
              <dt
                data-hc-name={`${glossaryItem}-dt`}
                className={styles.GlossaryTitle}
              >
                <h3
                  className={styles.Header}
                  data-hc-name={`${glossaryItem}-title`}
                >
                  {term.title}
                </h3>
              </dt>
            )}

            <dd
              data-hc-name={`${glossaryItem}-content`}
              className={styles.GlossaryDescription}
            >
              {term.content.map((paragraph: string, paraIndex: number) => (
                <div
                  key={`${dataHcName}-content-para-${paraIndex}`}
                  data-hc-name={`${glossaryItem}-content-paragraph`}
                >
                  {paragraph}
                </div>
              ))}

              {term.source && (
                <div
                  className={styles.Source}
                  data-hc-name={`${glossaryItem}-source`}
                >
                  Source: {term.source}
                </div>
              )}
            </dd>
          </div>
        );
      })}
    </dl>
  );
};
