import { CompId } from '@hcs/types';

import { useAppraisalComparisonDocument } from '../hooks/useAppraisalComparisonDocument';

interface Props {
  compId: CompId;
}
export const useAppraisalRiskCompProperty = ({ compId }: Props) => {
  const biasRiskDoc = useAppraisalComparisonDocument();
  return biasRiskDoc.data.comparables.propertyRisk[compId];
};
