import React, { ReactNode } from 'react';
import classNames from 'classnames';

import {
  COMP_FIELDS_CONFIG,
  PROPERTY_STATE_FIELD_CONFIGS,
} from '@hcs/property-state';
import { getPropertyStateFieldValue } from '@hcs/property-state';
import {
  CompTypes,
  PropertyStateArgsCore,
  PropertyStateFields,
  PropertyStateType,
} from '@hcs/types';
import { CompFieldConfig, CompFields, CompFieldsArgs } from '@hcs/types';
import { formatMoney, NULL_VALUE } from '@hcs/utils';

import { useSubjectValueDocument } from '../../hooks/useSubjectValueDocument';

import { Props as CompsCompareTableProps } from './CompsCompareTable';

import styles from './CompsCompareTable.module.css';

const dataHcName = 'comps-compare-table-value-rows';
interface Props
  extends Pick<CompsCompareTableProps, 'subject' | 'comps' | 'compType'> {
  isSuggestedComps: boolean;
}

interface RowProps {
  label: ReactNode;
  comps: Props['comps'];
  Display: CompFieldConfig<CompFields>['Display'];
  subject: ReactNode;
}

const MarketPriceConfig =
  PROPERTY_STATE_FIELD_CONFIGS[PropertyStateFields.currentPrice];
const DisplayMarketPrice = ({ comp }: CompFieldsArgs) => {
  return (
    <MarketPriceConfig.Display
      propertyStateArgs={{
        propertyStateType: PropertyStateType.Core,
        propertyState: comp?.propertyState,
      }}
    />
  );
};

const Row = ({ label, subject, comps, Display }: RowProps) => {
  return (
    <tr>
      <th className={styles.Th}>{label}</th>
      <td className={classNames(styles.Td, styles.subject)}>{subject}</td>
      {comps.map((comp, i) => {
        const keyStr = `${
          comp.schema.compID || comp.schema.propertyState.hcAddressId
        }-${i}-cell`;
        return (
          <td key={keyStr} className={classNames(styles.Td, styles.comp)}>
            <Display comp={comp.schema} />
          </td>
        );
      })}
    </tr>
  );
};
export const CompsCompareTableValueRows = ({
  subject,
  comps,
  compType,
  isSuggestedComps,
}: Props) => {
  const subjectValueDoc = useSubjectValueDocument(
    compType === CompTypes.Rental
  );
  const { comparableValue } = subjectValueDoc?.data || {};
  const { hcAdjustmentDate, hcAdjustmentPropertyDetails } =
    comparableValue?.hcAdjustments || {};
  const propertyStateArgs: PropertyStateArgsCore = {
    propertyStateType: PropertyStateType.Core,
    propertyState: subject.schema.propertyState,
  };
  return (
    <table data-hc-name={dataHcName} className={styles.Table}>
      <tbody>
        <Row
          label="Market Price"
          subject={
            <MarketPriceConfig.Display propertyStateArgs={propertyStateArgs} />
          }
          comps={comps}
          Display={DisplayMarketPrice}
        />
        {(comparableValue?.hcAdjustments.hcAdjustmentDate ||
          isSuggestedComps) && (
          <Row
            label="HPI Adjustment"
            subject={NULL_VALUE}
            comps={comps}
            Display={COMP_FIELDS_CONFIG[CompFields.hcAdjustmentDate].Display}
          />
        )}
        {(comparableValue?.hcAdjustments.hcAdjustmentPropertyDetails ||
          isSuggestedComps) && (
          <Row
            label="HC Adjustment"
            subject={NULL_VALUE}
            comps={comps}
            Display={
              COMP_FIELDS_CONFIG[CompFields.hcAdjustmentPropertyDetails].Display
            }
          />
        )}
        {!isSuggestedComps && (
          <Row
            label="User Adjustment"
            subject={NULL_VALUE}
            Display={
              COMP_FIELDS_CONFIG[CompFields.userTotalDollarAdjustment].Display
            }
            comps={comps}
          />
        )}
        <Row
          label="Adjusted Value"
          subject={NULL_VALUE}
          comps={comps}
          Display={({ comp }: CompFieldsArgs) => {
            const marketPrice = getPropertyStateFieldValue(
              PropertyStateFields.currentPrice,
              {
                propertyStateType: PropertyStateType.Core,
                propertyState: comp?.propertyState,
              }
            );
            const hcHpiAdjustment =
              hcAdjustmentDate && comp?.hcAdjustments?.hcAdjustmentDate;
            const hcPhysicalAdjustment =
              hcAdjustmentPropertyDetails &&
              comp?.hcAdjustments?.hcAdjustmentPropertyDetails;
            const userDollarAdjustment =
              comp?.userAdjustments?.totalDollarAdjustment;
            const totalPrice =
              (marketPrice || 0) +
              (hcHpiAdjustment || 0) +
              (hcPhysicalAdjustment || 0) +
              (userDollarAdjustment || 0);
            return <>{formatMoney(totalPrice)}</>;
          }}
        />
      </tbody>
    </table>
  );
};
