import { usePdfData } from '@hcs/pdf/pdf-service';
import { ReportApiDocument } from '@hcs/types';
import { ReportApiReports } from '@hcs/types';

export const useDocumentRole = <
  DocType extends ReportApiDocument,
  Data extends ReportApiReports
>(
  documentRole: DocType['role']
): DocType[] => {
  const data = usePdfData<Data>();
  return data.documents.filter((d) => d.role === documentRole) as DocType[];
};
