import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './SectionHeader.module.css';

export interface SectionHeaderProps {
  dataHcName: string;
  className?: string;
  title: ReactNode;
  titleBorder?: boolean;
  rightContent?: {
    label: ReactNode;
    value: ReactNode;
  };
}

export const SectionHeader = ({
  dataHcName,
  title,
  titleBorder,
  rightContent,
}: SectionHeaderProps) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.SectionHeader, {
        [styles.noTitleBorder]: titleBorder === false,
      })}
    >
      <h2 className={styles.Title} data-hc-name={`${dataHcName}-title`}>
        {title}
      </h2>
      {rightContent && (
        <div
          data-hc-name={`${dataHcName}-right-content`}
          className={styles.RightContent}
        >
          <span
            data-hc-name={`${dataHcName}-right-content-label`}
            className={styles.RightContentLabel}
          >
            {rightContent.label}
          </span>
          {rightContent.value}
        </div>
      )}
    </div>
  );
};
