import React, { ReactNode } from 'react';

import { Section, SectionProps } from '../Section';

import styles from './TwoColSection.module.css';

interface Props extends Omit<SectionProps, 'children'> {
  contentLeft: ReactNode;
  contentRight: ReactNode;
}

export const TwoColSection = ({
  contentLeft,
  contentRight,
  ...sectionProps
}: Props) => {
  return (
    <Section {...sectionProps}>
      <div className={styles.FlexContent}>
        <div
          className={styles.ColLeft}
          data-hc-name={`${sectionProps.dataHcName}-content-right`}
        >
          {contentLeft}
        </div>
        <div
          className={styles.ColRight}
          data-hc-name={`${sectionProps.dataHcName}-content-right`}
        >
          {contentRight}
        </div>
      </div>
    </Section>
  );
};
