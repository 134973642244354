import { buildStaticStreetViewUrlForPdf } from '@hcs/street-view';
import { CompId, CompTypes, DocumentRoles, ListingStatus } from '@hcs/types';
import { CompIdToPhotoUrlMapping } from '@hcs/types';
import { locationToGeoLocation } from '@hcs/utils';

import { useActiveTop4CompPhotosDocuments } from './useActiveTop4CompPhotosDocuments';
import { useClosedTop4CompPhotosDocuments } from './useClosedTop4CompPhotosDocuments';
import { CompsStatus, useTop4Comps } from './useTop4Comps';

export const useTop4CompsFrontPhotos = (
  compsStatus: CompsStatus = ListingStatus.CLOSED
): CompIdToPhotoUrlMapping => {
  const closedTop4CompPhotosDocuments = useClosedTop4CompPhotosDocuments();
  const activeTop4CompPhotosDocuments = useActiveTop4CompPhotosDocuments();
  const compPhotosDocs =
    compsStatus === ListingStatus.ACTIVE
      ? activeTop4CompPhotosDocuments
      : closedTop4CompPhotosDocuments;
  const { comps } = useTop4Comps({ compsStatus, compType: CompTypes.Sold });
  const results: CompIdToPhotoUrlMapping = {};
  compPhotosDocs.forEach((doc) => {
    const compId = doc.documentId.replace(
      `${
        compsStatus === ListingStatus.ACTIVE
          ? DocumentRoles.ActiveTop4CompPhotos
          : DocumentRoles.ClosedTop4CompPhotos
      }-`,
      ''
    ) as CompId;
    const comp = comps.find((c) => c.compSchema.compID === compId);
    const compGeoLocation = locationToGeoLocation(
      comp?.compSchema.propertyState.location
    );
    const mlsPhoto = doc.data.listingPhotos?.[0]?.media?.images?.[0]?.url;
    const frontPhoto = mlsPhoto
      ? mlsPhoto
      : compGeoLocation
      ? buildStaticStreetViewUrlForPdf(compGeoLocation)
      : undefined;

    if (compId && frontPhoto) {
      results[compId] = frontPhoto;
    }
  });
  return results;
};
