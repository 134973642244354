import React from 'react';

import { HouseCanaryLogoFullColor } from '@hcs/design-system';
import { SVGProps } from '@hcs/hooks';

import { usePdfPersonalization } from '../../hooks';

import styles from './CobrandedLogo.module.css';

interface Props extends SVGProps {
  logoOnly?: boolean;
}
const dataHcName = 'cobranded-logo';
export const CobrandedLogo = ({ logoOnly = true, ...imgProps }: Props) => {
  const personalization = usePdfPersonalization();
  return !personalization?.includeCompanyLogo ||
    !personalization.companyLogoUrl ? (
    <HouseCanaryLogoFullColor dataHcName={`${dataHcName}-hc`} {...imgProps} />
  ) : (
    <>
      {personalization.includeCompanyLogo && personalization.companyLogoUrl && (
        <img
          {...{
            height: imgProps.height,
            width: imgProps.width,
            className: imgProps.className,
          }}
          alt="Company Logo"
          data-hc-name={`${dataHcName}-client`}
          src={personalization.companyLogoUrl}
        />
      )}
      {!logoOnly && (
        <>
          {personalization.companyName && (
            <h2
              className={styles.ClientCompanyName}
              data-hc-name={`${dataHcName}-company-name`}
            >
              {personalization.companyName}
            </h2>
          )}
          {personalization.websiteUrl && (
            <div data-hc-name={`${dataHcName}-company-website`}>
              {personalization.websiteUrl}
            </div>
          )}
        </>
      )}
    </>
  );
};
