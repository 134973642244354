import React, { ReactElement, useMemo } from 'react';
import merge from 'lodash.merge';
import { VictoryAxis, VictoryBar, VictoryChart } from 'victory';

import { NullState } from '@hcs/design-system';
import { BarChartBucket, ChartSizes } from '@hcs/types';

import {
  AXIS_PROPS_DEFAULT_X,
  AXIS_STYLES_X,
  AXIS_STYLES_Y,
  CHART_DIMENSIONS,
} from '../../constants';
import { buildVictoryChartDataBar } from '../../utils/charts.utils';
import { Chart } from '../Chart';

type Props = {
  title?: string;
  chartSize?: ChartSizes;
  xAxis: {
    label?: string;
    axisLabelPadding?: number;
    tickValues: string[];
    tickLabelComponent?: ReactElement;
  };
  yAxis: {
    label?: string;
    domain?: [number, number];
  };
  chartData: BarChartBucket[];
  highlightedTick?: string;
  dataHcName: string;
  className?: string;
};

const AXIS_RATIO_WIDTH = 0.8907563025210085;
const AXIS_RATIO_HEIGHT = 0.75;

export const BarChart = ({
  dataHcName,
  className,
  title,
  xAxis,
  yAxis,
  chartSize = 'medium',
  chartData,
  highlightedTick,
}: Props) => {
  const chartDimensions = CHART_DIMENSIONS[chartSize];
  const axisDimensions = {
    width: chartDimensions.width * AXIS_RATIO_WIDTH,
    height: chartDimensions.height * AXIS_RATIO_HEIGHT,
  };
  const victoryChartData = useMemo(
    () => buildVictoryChartDataBar(chartData),
    [chartData]
  );
  const SPACE_BETWEEN_BARS = 6;
  return (
    <Chart
      className={className}
      chartSize={chartSize}
      dataHcName={dataHcName}
      title={title}
      yAxis={{
        label: yAxis.label,
      }}
      hideAxisX
    >
      <svg id="hc-svg-defs" height="0" width="0" style={{ display: 'block' }}>
        <defs>
          <linearGradient id="gradient1" x1="0" x2="0" y1="1" y2="0">
            <stop offset="0%" stopColor="#E8E8E8" />
            <stop offset="100%" stopColor="#F5F5F5" />
          </linearGradient>
          <linearGradient id="gradient2" x1="0%" x2="92%" y1="0%" y2="108%">
            <stop offset="0%" stopColor="#6ba0ff" />
            <stop offset="100%" stopColor="#6ba0ff" />
          </linearGradient>
        </defs>
      </svg>
      {!Array.isArray(chartData) || !chartData.length ? (
        <NullState
          dataHcName={`${dataHcName}-no-data`}
          title="Not Enough Data"
        />
      ) : (
        <VictoryChart
          domainPadding={{ x: 20 }}
          width={chartDimensions.width}
          height={chartDimensions.height}
          padding={chartDimensions.padding}
        >
          <VictoryAxis
            {...merge(xAxis, AXIS_PROPS_DEFAULT_X)}
            label={xAxis.label}
            width={axisDimensions.width}
            height={axisDimensions.height}
            style={{
              ...AXIS_STYLES_Y[chartSize],
              ticks: {
                ...AXIS_STYLES_X[chartSize].ticks,
                stroke: ({ tick }: { tick: string | undefined }) =>
                  tick === highlightedTick ? '#6ba0ff' : '#E7E7E7',
                fill: ({ tick }: { tick: string | undefined }) => {
                  return tick === highlightedTick ? '#6ba0ff' : '#4A4A4A';
                },
              },
              tickLabels: {
                ...AXIS_STYLES_X[chartSize].tickLabels,
                fontWeight: ({ tick }: { tick: string | undefined }) =>
                  tick === highlightedTick ? 900 : 400,
              },
            }}
          />
          <VictoryBar
            barWidth={
              (chartDimensions.width -
                chartDimensions.padding.left -
                chartDimensions.padding.right) /
                chartData.length -
              SPACE_BETWEEN_BARS
            }
            cornerRadius={{
              topLeft: ({ data }) => {
                return (data?.length || 0) > 5 ? 3 : 5;
              },
              topRight: ({ data }) => {
                return (data?.length || 0) > 5 ? 3 : 5;
              },
            }}
            style={{
              data: {
                fill: ({ datum }) => {
                  return datum.x.toString() === highlightedTick
                    ? 'url(#gradient2)'
                    : 'url(#gradient1)';
                },
                stroke: ({ datum }) =>
                  datum.x.toString() === highlightedTick
                    ? '#6ba0ff'
                    : '#E5E5E5',
                strokeWidth: '1px',
              },
            }}
            data={victoryChartData}
          />
        </VictoryChart>
      )}
    </Chart>
  );
};
