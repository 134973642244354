import React, { CSSProperties } from 'react';
import classNames from 'classnames';

import styles from './SeeAddendum.module.css';

interface SeeAddendumProps {
  dataHcName: string;
  visible?: boolean;
  style?: CSSProperties;
  isAbsolute?: boolean;
}

export const SeeAddendum = ({
  dataHcName,
  visible = true,
  isAbsolute = true,
  style = {},
}: SeeAddendumProps) => {
  if (!visible) {
    return null;
  }
  return (
    <span
      className={classNames(styles.SeeAddendum, {
        [styles.absolute]: isAbsolute,
      })}
      style={{ ...style }}
      data-hc-name={dataHcName}
    >
      <span>See</span>
      <span>Addendum</span>
    </span>
  );
};
