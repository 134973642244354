import React from 'react';

import { TwoColSection, TwoColTable } from '@hcs/pdf/pdf-service';
import { PROPERTY_TYPE_CONFIG } from '@hcs/property-state';
import { PropertyStateType } from '@hcs/types';
import { formatDate, formatMissing, NULL_VALUE } from '@hcs/utils';

import { InspectionChoiceField } from '../../component';
import { useSubjectDocument } from '../../hooks';
import { useInspectionDocument } from '../../hooks/useInspectionDocument';

const QUALITY_OF_CONSTRUCTION_DESCS: Record<string, string> = {
  Q1: 'Unique structures, individually designed by an architect for a specified user, exceptionally high level of workmanship and materials.',
  Q2: 'Custom designed for construction on an individual site. High quality materials and workmanship.',
  Q3: 'Built from individual/readily available designer plans. Higher quality materials, finishes have been upgraded from "stock" standards.',
  Q4: 'Standard building plans. Materials, workmanship, finish, and equipment are of stock/builder grade and may feature some upgrades.',
  Q5: 'Built using readily available/basic floor plans featuring inexpensive, stock materials with limited refinements.',
  Q6: 'Built with simple plans, utilizing the lowest quality materials. Utilities may be minimal or nonexistent.',
};
const CONDITION_TYPES = [
  'Poor',
  'Fair',
  'Average',
  'Good',
  'Very Good',
  'Excellent',
];
export const InspectionConditionSection = () => {
  const inspectionDoc = useInspectionDocument();
  const subjectDoc = useSubjectDocument();
  return (
    <TwoColSection
      dataHcName="inspection-property-details"
      sectionHeaderProps={{
        title: '3rd Party Exterior Inspection',
        rightContent: {
          value: '',
          label: (
            <>
              {PROPERTY_TYPE_CONFIG.formatValue({
                propertyState: subjectDoc.data.propertyState,
                propertyStateType: PropertyStateType.Core,
              })}{' '}
              | Completed Inspection Date:{' '}
              {inspectionDoc?.data.inspectionDate
                ? formatDate(new Date(inspectionDoc.data.inspectionDate))
                : NULL_VALUE}
            </>
          ),
        },
      }}
      contentLeft={
        <>
          <h5>Exterior Property Condition</h5>
          <TwoColTable
            dataHcName="inspection-details-table"
            data={[
              {
                label: 'Quality of Construction',
                value: formatMissing(inspectionDoc?.data.qualityOfConstruction),
              },
              {
                label: '',
                value: formatMissing(
                  QUALITY_OF_CONSTRUCTION_DESCS[
                    inspectionDoc?.data.qualityOfConstruction || ''
                  ]
                ),
                isSubLabel: true,
              },
              {
                label: 'Roof Condition',
                value: (
                  <InspectionChoiceField
                    choices={CONDITION_TYPES}
                    values={[inspectionDoc?.data.roofCondition || '']}
                  />
                ),
              },
              {
                label: 'Exterior Wall Condition',
                value: (
                  <InspectionChoiceField
                    choices={CONDITION_TYPES}
                    values={[inspectionDoc?.data.exteriorWallsCondition || '']}
                  />
                ),
              },
              {
                label: 'Building damage by any of the following',
                value: (
                  <InspectionChoiceField
                    choices={[
                      'Owner Neglect',
                      'Vandalism',
                      'Fire',
                      'Flood',
                      'Tornado',
                      'Storm',
                      'Wind',
                      'Hail',
                      'Freezing',
                      'Hurricane',
                      'Earthquake',
                      'Mudslide/Landslide',
                      'Other',
                      'None Noted',
                    ]}
                    values={inspectionDoc?.data.exteriorDamages || []}
                  />
                ),
              },
              {
                label: 'Comment',
                value: formatMissing(
                  inspectionDoc?.data.comment.exteriorDamages
                ),
                isSubLabel: true,
              },
              {
                label: 'Adverse Site Conditions',
                value: (
                  <InspectionChoiceField
                    choices={[
                      'Contamination',
                      'Failing secondary structure(s)',
                      'Encroachments',
                      'Significant Junk/Trash',
                      'Sinkhole',
                      'Wetlands',
                      'Extreme Slope',
                      'Other',
                      'None Noted',
                    ]}
                    values={inspectionDoc?.data.adverseSiteConditions || []}
                  />
                ),
              },
              {
                label: 'Comment',
                value: formatMissing(
                  inspectionDoc?.data.comment.adverseSiteConditions
                ),
                isSubLabel: true,
              },
            ]}
          />
        </>
      }
      contentRight={null}
    />
  );
};
