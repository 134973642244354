import React from 'react';

import { TwoColSection, TwoColTable } from '@hcs/pdf/pdf-service';
import { PROPERTY_TYPE_CONFIG } from '@hcs/property-state';
import { PropertyStateType } from '@hcs/types';
import { formatDate, formatMissing, NULL_VALUE } from '@hcs/utils';

import { useInspectionDocument } from '../../hooks/useInspectionDocument';
import { useSubjectDocument } from '../../hooks/useSubjectDocument';

import styles from './InspectionDetailsSection.module.css';

export const InspectionDetailsSection = () => {
  const inspectionDoc = useInspectionDocument();
  const subjectDoc = useSubjectDocument();
  return (
    <>
      <TwoColSection
        dataHcName="inspection-details"
        sectionHeaderProps={{
          title: '3rd Party Exterior Inspection',
          rightContent: {
            value: '',
            label: (
              <>
                {PROPERTY_TYPE_CONFIG.formatValue({
                  propertyState: subjectDoc.data.propertyState,
                  propertyStateType: PropertyStateType.Core,
                })}{' '}
                | Completed Inspection Date:{' '}
                {inspectionDoc?.data.inspectionDate
                  ? formatDate(new Date(inspectionDoc.data.inspectionDate))
                  : NULL_VALUE}
              </>
            ),
          },
        }}
        contentLeft={
          <TwoColTable
            dataHcName="inspection-details-table"
            data={[
              {
                label: 'Inspector Name',
                value: formatMissing(inspectionDoc?.data.inspector.name),
              },
              {
                label: 'Inspector Email',
                value: formatMissing(inspectionDoc?.data.inspector.email),
              },
            ]}
          />
        }
        contentRight={null}
      />
      <div className={styles.Comments}>
        <label className={styles.CommentLabel}>Inspector Comments</label>
        <div className={styles.CommentValue}>
          {formatMissing(inspectionDoc?.data.comment.general)}
        </div>
      </div>
    </>
  );
};
