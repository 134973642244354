import {
  DocumentRoles,
  ReportApiReports,
  SubjectFloodDocument,
} from '@hcs/types';

import { useDocumentRole } from './useDocumentRole';

export const useSubjectFlood = <Data extends ReportApiReports>() => {
  return useDocumentRole<SubjectFloodDocument, Data>(
    DocumentRoles.SubjectFlood
  );
};
