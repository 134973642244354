import React from 'react';

import { Section } from '@hcs/pdf/pdf-service';
import { CompFields, CompTypes, PropertyStateFields } from '@hcs/types';
import { arrayToChunks } from '@hcs/utils';

import { useSelectedOrSuggestedRentalComps } from '../..//hooks/useSelectedOrSuggestedRentalComps';
import { CompsMap } from '../../features/CompsMap/CompsMap';
import { CompsTable } from '../../features/CompsTable';
import { useSubjectDocument } from '../../hooks/useSubjectDocument';
import { PropertyReportPage } from '../PropertyReports/PropertyReportPage';

import { MAX_COMPS_PER_PAGE } from './PexpCompsMapPage';

const FIELDS = [
  CompFields.distance,
  PropertyStateFields.currentStatusRental,
  PropertyStateFields.currentPriceRental,
  PropertyStateFields.currentStatusDateRental,
  PropertyStateFields.bedrooms,
  PropertyStateFields.bathrooms,
  PropertyStateFields.livingArea,
  PropertyStateFields.lotSize,
  PropertyStateFields.propertyType,
  PropertyStateFields.yearBuilt,
];
interface Props {
  pageIndexInit?: number;
  numPagesTotal?: number;
}
const dataHcName = `pexp-selected-rental-comps-map-page`;
export const PexpRentalCompsMapPage = ({ pageIndexInit = 0 }: Props) => {
  const subjectDocument = useSubjectDocument();
  const { comps, isSuggested } = useSelectedOrSuggestedRentalComps();
  const subjectSchema = subjectDocument.data;
  // Do not render page if no comps
  if (!comps.length) return null;
  const compChunks = arrayToChunks(comps, MAX_COMPS_PER_PAGE);
  return (
    <>
      {compChunks.map((compsForPage, pageIndexChunk) => {
        const pageIndex = pageIndexChunk + pageIndexInit;
        const compSchemas = compsForPage.map((comp) => comp.compSchema);
        return (
          <PropertyReportPage
            dataHcName={`${dataHcName}-${pageIndex}`}
            key={`${dataHcName}-${pageIndex}`}
          >
            <Section
              sectionHeaderProps={{
                title: isSuggested
                  ? 'HouseCanary Suggested Rental Comparables'
                  : 'Selected Rental Comparables',
              }}
              dataHcName={`${dataHcName}-section`}
            >
              <CompsMap compType={CompTypes.Rental} comps={comps} />
              <CompsTable
                dataHcName={`${dataHcName}-table`}
                subjectSchema={subjectSchema}
                rows={compSchemas.map((compSchema) => ({ compSchema }))}
                fields={FIELDS}
              />
            </Section>
          </PropertyReportPage>
        );
      })}
    </>
  );
};
