import React from 'react';

import { RISK_FACTORS_CONFIGS } from '@hcs/report-api';
import { RiskSchema, RiskType } from '@hcs/types';

import { BiasRisk } from '../..//component/BiasRisk/BiasRisk';
import { useAppraisalComparisonDocument } from '../..//hooks/useAppraisalComparisonDocument';

import styles from './BiasRiskFactors.module.css';

const ICON_PROPS = {
  height: 15,
};
interface Props {
  risk: RiskSchema['comparables'] | RiskSchema['value'];
  riskType: RiskType;
}
const dataHcName = 'bias-risk-';
export const AppraisalRiskFactors = ({ risk, riskType }: Props) => {
  const { riskLevel, riskFactors } = risk;
  return (
    <div data-hc-name={dataHcName} className={styles.BiasRiskFactors}>
      <div className={styles.Value}>
        <BiasRisk
          riskType={riskType}
          riskLevel={riskLevel}
          includeLevel
          includeType
        >
          {' '}
          - Factors
        </BiasRisk>
      </div>
      <ul className={styles.FactorsList}>
        {riskFactors.map(({ riskFactor, riskLevel: riskLevelFactor }) => {
          const { label } = RISK_FACTORS_CONFIGS[riskFactor];
          return (
            <li key={riskFactor}>
              <BiasRisk
                className={styles.Factor}
                riskType={riskType}
                riskLevel={riskLevelFactor}
                iconProps={ICON_PROPS}
                includeIcon
              >
                {label}
              </BiasRisk>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export const BiasRiskFactors = ({
  biasRiskType,
}: {
  biasRiskType: RiskType;
}) => {
  const biasRiskDocument = useAppraisalComparisonDocument();
  return (
    <AppraisalRiskFactors
      riskType={biasRiskType}
      risk={
        biasRiskType === 'comparables'
          ? biasRiskDocument.data.comparables
          : biasRiskDocument.data.value
      }
    />
  );
};
