import React from 'react';

import { PhotosSection } from '../../features/PhotosSection';
import { useSubjectPhotosDocument } from '../../hooks';

import { BpoReportPage } from './BpoReportPage';

export const SubjectPhotosPage = () => {
  const subjectPhotos = useSubjectPhotosDocument();
  return (
    <BpoReportPage dataHcName="subject-photos-page">
      <PhotosSection
        subjectPhotos={subjectPhotos}
        title="Photos - MLS - Subject"
      />
    </BpoReportPage>
  );
};
