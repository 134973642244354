import { sortCompDocuments } from '@hcs/report-api';
import { CompTypes, DocumentRoles, SelectedOrSuggestedComp } from '@hcs/types';

import { useCompDocuments } from './useCompDocuments';
import { useHcSuggestedCompDocuments } from './useHcSuggestedCompDocuments';

interface SelectedOrSuggestedCompsData {
  isSuggested: boolean;
  isSelected: boolean;
  compType: CompTypes;
  comps: SelectedOrSuggestedComp[];
}

export const useSelectedOrSuggestedComps = (
  compType: CompTypes = CompTypes.Sold
): SelectedOrSuggestedCompsData => {
  const hcSuggestedCompsDocuments = useHcSuggestedCompDocuments();
  const compDocuments = useCompDocuments();
  const isSuggested = !compDocuments?.length;
  const isSelected = !isSuggested;
  // Pick comps from either comp documents or most similar from farm
  // Suggested comps are sorted by similarity
  const comps: SelectedOrSuggestedComp[] =
    (compDocuments?.length ? compDocuments : hcSuggestedCompsDocuments)
      .sort(sortCompDocuments)
      .map((compDocument) => ({
        documentId: compDocument.documentId,
        compSchema: compDocument.data,
        compIdentifier: {
          type: 'compId',
          compId: compDocument.data.compID,
          compType,
        },
        userPropertyAdjustments:
          compDocument.role === DocumentRoles.Comp
            ? compDocument.userPropertyAdjustments
            : undefined,
      })) || [];

  return {
    isSuggested,
    isSelected,
    comps,
    compType,
  };
};
