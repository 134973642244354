import { DocumentRoles, SubjectDocument } from '@hcs/types';
import { ReportApiReports } from '@hcs/types';

import { useSingleDocumentRole } from './useSingleDocumentRole';

export const useSubjectDocument = <Data extends ReportApiReports>() => {
  const subjectDocument = useSingleDocumentRole<SubjectDocument, Data>(
    DocumentRoles.Subject
  );
  if (!subjectDocument) {
    throw new Error('Missing Subject Document');
  }
  return subjectDocument;
};
