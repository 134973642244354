import { AddendumItem } from '@hcs/types';

import { useAddendumSlice } from './useAddendumSlice';

export const usePdfAddendum = (): {
  state: { addendumList: AddendumItem[] };

  actions: {
    addItemToList: (item: {
      term: AddendumItem['term'];
      description: AddendumItem['description'];
      source: AddendumItem['source'];
    }) => void;
    clearList: () => void;
  };
} => {
  const { state, actions } = useAddendumSlice();
  return {
    state: { ...state },
    actions: {
      addItemToList: (item: AddendumItem) => {
        actions.addItemToAddendumList(item);
      },
      clearList: () => {
        actions.clearList();
      },
    },
  };
};
