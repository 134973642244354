import camelcaseKeys from 'camelcase-keys';

import { PdfServiceReports } from '@hcs/types';

import { usePdfPayload } from './usePdfPayload';

export const usePdfPersonalization = <D extends PdfServiceReports>() => {
  const { personalization } = usePdfPayload<D>();
  return personalization
    ? camelcaseKeys(personalization, { deep: true })
    : undefined;
};
