import { ClosedTop4CompPhotosDocument, DocumentRoles } from '@hcs/types';
import { ReportApiReports } from '@hcs/types';

import { useDocumentRole } from './useDocumentRole';

export const useClosedTop4CompPhotosDocuments = <
  Data extends ReportApiReports
>() => {
  return useDocumentRole<ClosedTop4CompPhotosDocument, Data>(
    DocumentRoles.ClosedTop4CompPhotos
  );
};
