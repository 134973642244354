import React from 'react';

import { TwoColSection, TwoColTable } from '@hcs/pdf/pdf-service';
import { formatMissing } from '@hcs/utils';

import { useInspectionDocument } from '../../hooks/useInspectionDocument';

import styles from './SubjectConditionSection.module.css';

export const SubjectConditionSection = () => {
  const inspectionDoc = useInspectionDocument();

  return (
    <>
      <TwoColSection
        dataHcName="subject-condition-section"
        sectionHeaderProps={{
          title: 'Condition',
        }}
        contentLeft={
          <TwoColTable
            dataHcName="subject-condition-section-table-left"
            data={[
              {
                label: 'Roof Condition',
                value: formatMissing(inspectionDoc?.data.roofCondition),
                dataHcName: 'roof-condition',
              },
              {
                label: 'Exterior Walls Condition',
                value: formatMissing(
                  inspectionDoc?.data.exteriorWallsCondition
                ),
                dataHcName: 'exterior-walls-condition',
              },
              {
                label: 'Building Damages',
                value: formatMissing(
                  inspectionDoc?.data.exteriorDamages?.join(', ')
                ),
                dataHcName: 'building-damages',
              },
              {
                label: 'Exterior Damages',
                value: formatMissing(
                  inspectionDoc?.data.comment.exteriorDamages
                ),
                dataHcName: 'exterior-damages',
              },
            ]}
          />
        }
        contentRight={
          <TwoColTable
            dataHcName="subject-condition-section-table-right"
            data={[
              {
                label: 'Condition Rating (C1-C6)',
                value: formatMissing(inspectionDoc?.data.condition),
                dataHcName: 'property-condition',
              },
            ]}
          />
        }
      />
      <p className={styles.Disclaimer}>
        {inspectionDoc?.data.comment.general || ''}
      </p>
    </>
  );
};
