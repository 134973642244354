import React from 'react';

import { Section } from '@hcs/pdf/pdf-service';
import { arrayToChunks } from '@hcs/utils';

import { AppraisalReviewCompVerification } from '../..//features/AppraisalReviewCompVerification';
import { useAppraisalCompDocuments } from '../..//hooks/useAppraisalCompDocuments';
import { useAppraisalCompPhotosDocuments } from '../..//hooks/useAppraisalCompPhotosDocuments';
import { AppraisalReviewPage } from '../..//pdf-pages/AppraisalReviewReports/AppraisalReviewPage';

const MAX_COMPS_PER_PAGE = 2;

const dataHcName = `appraisal-review-comps-data-verification-page`;
export const AppraisalReviewCompsDataVerificationPages = () => {
  // TODO: Replace Hook
  const photoDocs = useAppraisalCompPhotosDocuments();
  const appraisalCompDocs = useAppraisalCompDocuments();
  const compChunks = arrayToChunks(appraisalCompDocs, MAX_COMPS_PER_PAGE);
  return (
    <>
      {compChunks.map((compsForPage, pageIndex) => {
        return (
          <AppraisalReviewPage
            key={`comps-page-${pageIndex}`}
            dataHcName={dataHcName}
          >
            <Section
              sectionHeaderProps={{
                title: `Comp Data Verification (Page ${pageIndex + 1} of ${
                  compChunks.length
                })`,
              }}
              dataHcName={`${dataHcName}-section`}
            >
              {compsForPage.map((comp, i) => {
                const photoDoc = photoDocs.find((d) => {
                  const compId = d.documentId.split('-')[1];
                  return compId === comp.data.compID;
                });
                const photo =
                  photoDoc?.data.listingPhotos?.[0]?.media?.images?.[0]?.url;
                return (
                  <AppraisalReviewCompVerification
                    key={`comp-${comp.data.compID}-${i}`}
                    comp={comp}
                    photo={photo}
                  />
                );
              })}
            </Section>
          </AppraisalReviewPage>
        );
      })}
    </>
  );
};
