import React from 'react';
import classNames from 'classnames';

import { Section } from '@hcs/pdf/pdf-service';
import { PropertyStateCore } from '@hcs/types';
import { RiskSchema } from '@hcs/types';
import { formatRiskDescription, formatRiskLevel } from '@hcs/utils';

import { useAppraisalComparisonDocument } from '../..//hooks/useAppraisalComparisonDocument';
import { useSubjectDocument } from '../..//hooks/useSubjectDocument';

import styles from './BiasRiskOverallSection.module.css';

const dataHcName = 'bias-risk-overall-section';
export const BiasRiskOverallSectionContent = ({
  appraisalRisk,
  className,
}: {
  subject: PropertyStateCore;
  className?: string;
  appraisalRisk: RiskSchema;
}) => {
  const riskOverall = appraisalRisk.overall;
  const riskValue = appraisalRisk.value.riskLevel;
  const riskComps = appraisalRisk.comparables.riskLevel;
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.BiasRiskOverallSection, className, {
        [styles.low]: riskOverall === 'LOW_RISK',
        [styles.med]: riskOverall === 'RISK',
        [styles.high]: riskOverall === 'HIGH_RISK',
      })}
    >
      <div className={styles.Heading}>{formatRiskLevel(riskOverall)}</div>
      <div className={styles.SubHeading}>
        {formatRiskDescription(riskOverall, riskValue, riskComps)}
      </div>
    </div>
  );
};

export const BiasRiskOverallSection = () => {
  const appraisalComparisonDocument = useAppraisalComparisonDocument();
  const subjectDoc = useSubjectDocument();
  return (
    <Section
      dataHcName={`${dataHcName}-section`}
      sectionHeaderProps={{
        title: 'Overall Risk Assessment',
        titleBorder: false,
      }}
    >
      <BiasRiskOverallSectionContent
        appraisalRisk={appraisalComparisonDocument.data}
        subject={subjectDoc.data.propertyState}
      />
    </Section>
  );
};
