import { ClosedTop4CompDocument, DocumentRoles } from '@hcs/types';
import { ReportApiReports } from '@hcs/types';

import { useDocumentRole } from './useDocumentRole';

export const useClosedTop4CompDocuments = <Data extends ReportApiReports>() => {
  return useDocumentRole<ClosedTop4CompDocument, Data>(
    DocumentRoles.ClosedTop4Comp
  );
};
