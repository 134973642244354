import React from 'react';

import { ValuationMethod } from '@hcs/types';
import { PropertyExplorerReports } from '@hcs/types';
import { formatDateShort, formatMoney } from '@hcs/utils';

import { BiasRisk } from '../..//component/BiasRisk/BiasRisk';
import { useAppraisalComparisonDocument } from '../..//hooks/useAppraisalComparisonDocument';
import { useAppraisalSubjectValueDocument } from '../..//hooks/useAppraisalSubjectValueDocument';
import { useReport } from '../../hooks/useReport';
import { useReportValue } from '../../hooks/useReportValue';
import {
  PropertyReportCoverPage,
  PropertyReportCoverPageProps,
} from '../PropertyReports';

export const AppraisalReviewCoverPage = <D extends PropertyExplorerReports>({
  metadata: metadataProp,
  customContent,
}: PropertyReportCoverPageProps) => {
  const report = useReport<D>();
  const reportValue = useReportValue<D>();
  const appraisalSubjectValueDocument = useAppraisalSubjectValueDocument();
  const appraisalComparisonDoc = useAppraisalComparisonDocument();
  const metadata = [
    ...(metadataProp || []),
    {
      dataHcName: 'appraised-value',
      label: 'Appraised Value',
      value: formatMoney(
        appraisalSubjectValueDocument?.data[ValuationMethod.UserEntered]?.value
          .value
      ),
    },
    {
      dataHcName: 'value',
      label: 'Value Estimate',
      value: formatMoney(reportValue?.selectedValue?.value.value),
    },
    {
      label: 'Effective Date',
      value: formatDateShort(report.effectiveDate),
      dataHcName: 'effective-date',
    },
  ];
  if (report.clientID) {
    metadata.push({
      dataHcName: 'client-file-id',
      label: 'Client File ID',
      value: report.clientID,
    });
  }
  metadata.push({
    dataHcName: 'risk',
    label: 'Risk',
    value: (
      <BiasRisk
        colorBg
        includeLevel
        riskType="overall"
        riskLevel={appraisalComparisonDoc.data.overall}
      />
    ),
  });
  return (
    <PropertyReportCoverPage
      customContent={customContent}
      metadata={metadata}
    />
  );
};
