import React, { useMemo } from 'react';
import classNames from 'classnames';

import { formatMoney, formatPricePerSqFt } from '@hcs/utils';

import { useAppraisalSubjectValueDocument } from '../..//hooks/useAppraisalSubjectValueDocument';
import { useReportValue } from '../..//hooks/useReportValue';

import styles from './AppraisedValueComparisonChart.module.css';

interface ChartDatum {
  label: string;
  value: number | undefined | null;
  valuePerSqFt: number | undefined | null;
  className?: string;
}
interface Props {
  className?: string;
}
const dataHcName = 'appraised-value-comparison-chart';
export const AppraisedValueComparisonChart = ({ className }: Props) => {
  const { label, selectedValue } = useReportValue(false) || {};
  const appraisalSubjectValueDocument = useAppraisalSubjectValueDocument();

  const chartData: ChartDatum[] = useMemo(() => {
    const { valuePerSqFt, valueLowerPerSqFt, valueUpperPerSqFt } =
      selectedValue?.calculatedFields || {};
    const { value, valueLower, valueUpper } = selectedValue?.value || {};
    return [
      {
        label: 'Appraisal',
        value: appraisalSubjectValueDocument.data.userValue.value.value,
        valuePerSqFt:
          appraisalSubjectValueDocument.data.userValue.calculatedFields
            .valuePerSqFt,
        className: styles.Appraisal,
      },
      {
        label: 'Low',
        value: valueLower,
        valuePerSqFt: valueLowerPerSqFt,
        className: styles.Low,
      },
      {
        label: 'HC Value',
        value: value,
        valuePerSqFt: valuePerSqFt,
        className: styles.HcValue,
      },
      {
        label: 'High',
        value: valueUpper,
        valuePerSqFt: valueUpperPerSqFt,
        className: styles.High,
      },
    ].sort((a, b) => {
      const vA = a.value || 0;
      const vB = b.value || 0;
      if (vA > vB) {
        return 1;
      } else if (vA < vB) {
        return -1;
      } else {
        return 0;
      }
    });
  }, [label, selectedValue, appraisalSubjectValueDocument]);
  const maxVal = chartData[chartData.length - 1]?.value || 0;
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.AppraisedValueComparisonChart, className)}
    >
      {chartData.map((d) => {
        return (
          <div key={`${d.label}`} className={styles.ValueArea}>
            <div className={styles.Label}>{d.label}</div>
            <div
              className={classNames(styles.Bar, d.className)}
              style={{
                height: `calc(${((d.value || 0) / maxVal) * 100}% - 60px)`,
              }}
            >
              <div className={styles.BarLabels}>
                <div className={styles.Value}>{formatMoney(d.value)}</div>
                <div className={styles.ValuePerSqft}>
                  {formatPricePerSqFt(d.valuePerSqFt)}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
