import React from 'react';

import { Glossary } from '@hcs/pdf/glossary';
import { Section } from '@hcs/pdf/pdf-service';
import { DataSources } from '@hcs/pdf/pdf-service';
import { Disclaimer } from '@hcs/pdf/pdf-service';
import { GlossaryTerms, GlossaryTermsProp } from '@hcs/types';

import { PropertyReportPage } from '../PropertyReports/PropertyReportPage';

interface Props {
  glossaryTerms?: GlossaryTermsProp;
}
const dataHcName = 'pexp-glossary-continued-page';
export const PexpGlossaryContinuedPage = (props: Props) => {
  const glossaryTerms: GlossaryTermsProp = props.glossaryTerms || [
    GlossaryTerms.NonDisclosureState,
    GlossaryTerms.OccupancyType,
    GlossaryTerms.PropertyType,
    GlossaryTerms.Similarity,
    GlossaryTerms.NearbyProperties,
  ];
  return (
    <PropertyReportPage dataHcName={dataHcName}>
      <Section
        dataHcName={`${dataHcName}-glossary-section`}
        sectionHeaderProps={{
          title: `Glossary Continued`,
        }}
      >
        <Glossary glossaryTerms={glossaryTerms} />
        <DataSources productName="Property Explorer" />
        <Disclaimer productName="Property Explorer" />
      </Section>
    </PropertyReportPage>
  );
};
