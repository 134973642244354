import React from 'react';

import { Section } from '@hcs/pdf/pdf-service';

import { DescList } from '../../features/DescList';

import { BpoReportPage } from './BpoReportPage';

const ConditionRatings = [
  {
    term: 'C1',
    description: (
      <>
        <p>
          The improvements have been very recently constructed and have not
          previously been occupied. The entire structure and all components are
          new, and the dwelling features no physical depreciation.
        </p>{' '}
        <p>
          <strong>Note: </strong>Newly constructed improvements that feature
          recycled materials and/or components can be considered new dwellings
          provided that the dwelling is placed on a 100 percent new foundation
          and the recycled materials and the recycled components have been
          rehabilitated/re-manufactured into like-new condition. Improvements
          that have not been previously occupied are not considered “new” if
          they have any significant physical depreciation (that is, newly
          constructed dwellings that have been vacant for an extended period of
          time without adequate maintenance or upkeep).
        </p>
      </>
    ),
  },
  {
    term: 'C2',
    description: (
      <>
        <p>
          The improvements feature no deferred maintenance, little or no
          physical depreciation, and require no repairs. Virtually all building
          components are new or have been recently repaired, refinished, or
          rehabilitated. All outdated components and finishes have been updated
          and/or replaced with components that meet current standards. Dwellings
          in this category either are almost new or have been recently
          completely renovated and are similar in condition to new construction.
        </p>
        <p>
          <strong>Note: </strong>The improvements represent a relatively new
          property that is well-maintained with no deferred maintenance and
          little or no physical depreciation, or an older property that has been
          recently completely renovated.
        </p>
      </>
    ),
  },
  {
    term: 'C3',
    description: (
      <>
        <p>
          The improvements are well-maintained and feature limited physical
          depreciation due to normal wear and tear. Some components, but not
          every major building component, may be updated or recently
          rehabilitated. The structure has been well-maintained.
        </p>
        <p>
          <strong>Note: </strong>The improvement is in its first-cycle of
          replacing short-lived building components (appliances, floor
          coverings, HVAC, etc.) and is being well-maintained. Its estimated
          effective age is less than its actual age. It also may reflect a
          property in which the majority of short-lived building components have
          been replaced but not to the level of a complete renovation.
        </p>
      </>
    ),
  },
  {
    term: 'C4',
    description: (
      <>
        <p>
          The improvements feature some minor deferred maintenance and physical
          deterioration due to normal wear and tear. The dwelling has been
          adequately maintained and requires only minimal repairs to building
          components/mechanical systems and cosmetic repairs. All major building
          components have been adequately maintained and are functionally
          adequate.
        </p>
        <p>
          <strong>Note: </strong>The estimated effective age may be close to or
          equal to its actual age. It reflects a property in which some of the
          short-lived building components have been replaced, and some
          short-lived building components are at or near the end of their
          physical life expectancy; however, they still function adequately.
          Most minor repairs have been addressed on an ongoing basis resulting
          in an adequately maintained property
        </p>
      </>
    ),
  },
  {
    term: 'C5',
    description: (
      <>
        <p>
          The improvements feature obvious deferred maintenance and are in need
          of some significant repairs. Some building components need repairs,
          rehabilitation, or updating. The functional utility and overall
          livability are somewhat diminished due to condition, but the dwelling
          remains useable and functional as a residence.
        </p>
        <p>
          <strong>Note: </strong>Some significant repairs are needed to the
          improvements due to the lack of adequate maintenance. It reflects a
          property in which many of its short-lived building components are at
          the end of or have exceeded their physical life expectancy, but remain
          functional.
        </p>
      </>
    ),
  },
  {
    term: 'C6',
    description: (
      <>
        <p>
          The improvements have substantial damage or deferred maintenance with
          deficiencies or defects that are severe enough to affect the safety,
          soundness, or structural integrity of the improvements. The
          improvements are in need of substantial repairs and rehabilitation,
          including many or most major components.
        </p>
        <p>
          <strong>Note: </strong>Substantial repairs are needed to the
          improvements due to the lack of adequate maintenance or property
          damage. It reflects a property with conditions severe enough to affect
          the safety, soundness, or structural integrity of the improvements.
        </p>
      </>
    ),
  },
];

export const ConditionRatingPage = () => {
  return (
    <BpoReportPage dataHcName="condition-rating-page">
      <Section
        sectionHeaderProps={{
          title: 'Condition Rating',
        }}
        dataHcName="condition-rating-section"
      >
        <p>
          Appendix D: Field-Specific Standardization Requirements of Fannie Mae
          and Freddie Mac Uniform Appraisal Dataset Specification.
        </p>
        <DescList list={ConditionRatings} pageIndex={0} />
      </Section>
    </BpoReportPage>
  );
};
