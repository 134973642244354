import { DocumentRoles, HcSuggestedCompDocument } from '@hcs/types';
import { ReportApiReports } from '@hcs/types';

import { useDocumentRole } from './useDocumentRole';

export const useHcSuggestedCompDocuments = <
  Data extends ReportApiReports
>() => {
  return useDocumentRole<HcSuggestedCompDocument, Data>(
    DocumentRoles.HcSuggestedComp
  );
};
