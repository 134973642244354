import React from 'react';
import classNames from 'classnames';

import styles from './InspectionChoiceField.module.css';
interface Props {
  choices: string[];
  values: string[];
}
export const InspectionChoiceField = ({ choices, values }: Props) => {
  return (
    <div className={styles.InspectionChoiceField}>
      {choices.map((choice, i) => (
        <>
          {i > 0 && <span> / </span>}
          <span
            key={choice}
            className={classNames({
              [styles.selected]: values.includes(choice),
            })}
          >
            {choice}
          </span>
        </>
      ))}
    </div>
  );
};
