import React from 'react';
import { VictoryLabel } from 'victory';

import { LineChart } from '@hcs/pdf/charts';
import { NullState, SubContent } from '@hcs/pdf/pdf-service';
import { ChartSizes } from '@hcs/types';

import { useSaleToListPriceRatioChartDocument } from '../../hooks/useSaleToListPriceRatioChartDocument';

const dataHcName = 'sale-list-price-trend-chart';
interface Props {
  chartSize?: ChartSizes;
  className?: string;
}
export const SaleListPriceTrendChart = ({ chartSize, className }: Props) => {
  const saleToListPriceRatioChartDoc = useSaleToListPriceRatioChartDocument();
  const chartData = saleToListPriceRatioChartDoc?.data?.chart.data;
  const shouldShowNullState =
    !chartData?.length || chartData?.some((d) => !d.y);
  if (shouldShowNullState) {
    return (
      <>
        <SubContent>Sale/List Price Trend</SubContent>
        <NullState dataHcName={`${dataHcName}-null-state`} />
      </>
    );
  }

  return (
    <LineChart
      title="Sale/List Price Trend"
      dataHcName={dataHcName}
      className={className}
      chartSize={chartSize}
      chartData={chartData}
      isXDateStr={false}
      chart={{ domainPadding: { y: 20, x: 10 } }}
      xAxis={{
        tickLabelComponent: <VictoryLabel angle={-45} dx={-20} />,
      }}
      yAxis={{ label: 'Sale/List Price Ratio' }}
    />
  );
};
