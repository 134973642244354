import React, { useMemo } from 'react';
import classNames from 'classnames';
import getTime from 'date-fns/getTime';
import {
  Point,
  PointProps,
  VictoryArea,
  VictoryLine,
  VictoryScatter,
} from 'victory';

import { Chart, ChartTitle } from '@hcs/pdf/charts';
import { NullState } from '@hcs/pdf/pdf-service';
import { SubjectMarker } from '@hcs/pdf/pdf-service';
import { ChartSizes } from '@hcs/types';
import { DocumentRoles } from '@hcs/types';
import { ForecastChartDatum } from '@hcs/types';
import { formatMoney, formatMoneyAbbrev, formatPercent } from '@hcs/utils';

import { useForecastChartDocument } from '../../hooks/useForecastChartDocument';
import { useForecastStats } from '../../hooks/useForecastStats';

import styles from './ForecastChart.module.css';

const SUBJECT_SIZES = {
  small: 19,
  medium: 22,
  large: 26,
  xlarge: 26,
};
const POINT_SIZES = {
  small: 3,
  medium: 4,
  large: 8,
  xlarge: 8,
};
const ScatterDataComponentFactory =
  (chartSize: ChartSizes) => (props: PointProps) => {
    const subjectSize = SUBJECT_SIZES[chartSize];
    const subjectAdjustment = subjectSize / 2;
    const pointSize = POINT_SIZES[chartSize];
    return props.index === 0 ? (
      <SubjectMarker
        x={(props.x || 0) - subjectAdjustment}
        y={(props.y || 0) - subjectAdjustment}
        height={subjectSize}
        width={subjectSize}
      />
    ) : (
      <Point {...props} size={pointSize} />
    );
  };
interface Props {
  chartSize?: ChartSizes;
}
const dataHcName = 'forecast-chart';
export const ForecastChart = ({ chartSize = 'medium' }: Props) => {
  const ScatterDataComponent = useMemo(
    () => ScatterDataComponentFactory(chartSize),
    [chartSize]
  );
  const forecastStats = useForecastStats();
  const forecastChartDocument = useForecastChartDocument();
  const chart = forecastChartDocument?.data?.chart;
  const firstPoint = chart?.data?.[0];
  const lastPoint = chart?.data?.[chart?.data.length - 1];
  const currentYear = chart?.data?.[chart.currentMonthIndex];
  if (!firstPoint || !lastPoint || !currentYear || !chart.data?.length) {
    return <NullState dataHcName={`${dataHcName}-null`} />;
  }
  const domain: {
    x: [Date, Date];
    y: [number, number];
  } = {
    x: [new Date(firstPoint.x), new Date(lastPoint.x)],
    y: [
      Math.min(...chart.data.map((d) => d.y)),
      Math.max(...chart.data.map((d) => d.y)),
    ],
  };

  // For accessing stats
  const idxYear1 = chart.currentMonthIndex + 12;
  const idxYear2 = chart.currentMonthIndex + 24;
  const forecastYears: ForecastChartDatum[] =
    forecastStats?.map((f) => ({
      x: f.date,
      y: f.price,
      metaData: {
        hpi: f.hpi,
        percentDiff: f.percentDiff,
      },
    })) || [];
  const presentDate = new Date(currentYear.x);
  return (
    <Chart
      dataHcName={dataHcName}
      title={`HouseCanary Forecast Based on ${
        forecastChartDocument?.role === DocumentRoles.ForecastChartBlock
          ? 'Block'
          : 'Zipcode'
      } Market Conditions`}
      chartSize={chartSize}
      chart={{
        domain,
        domainPadding: { y: 50 },
        scale: { x: 'time', y: 'linear' },
      }}
      xAxis={{
        label: 'Year',
      }}
      yAxis={{
        label: 'Value of Home',
        tickFormat: (val) => formatMoneyAbbrev(val),
      }}
      contentBelow={
        <div className={styles.Legend} data-hc-name={`${dataHcName}-legend`}>
          <ChartTitle
            dataHcName={`${dataHcName}-legend-title`}
            className={styles.LegendTitle}
          >
            {forecastYears.length} Year Growth
          </ChartTitle>
          <div className={styles.LegendItems}>
            {forecastYears.map((forecast, i) => {
              return (
                <div
                  key={`forecast-${forecast.x}`}
                  data-hc-name={`${dataHcName}-legend-item`}
                >
                  <div className={classNames(styles.LegendItemRow, styles.Bar)}>
                    <span
                      data-hc-name={`${dataHcName}-legend-bar-label`}
                      className={styles.LegendItemLabel}
                    >
                      {i + 1} Year
                    </span>
                    <span
                      data-hc-name={`${dataHcName}-legend-percent-change`}
                      className={styles.LegendItemValue}
                    >
                      {formatPercent(forecast.metaData.percentDiff, true)}
                    </span>
                  </div>
                  <div className={styles.LegendItemRow}>
                    <span
                      data-hc-name={`${dataHcName}-legend-year`}
                      className={styles.LegendItemLabel}
                    >{`${forecast.x.split('-')[0]}`}</span>

                    <span
                      data-hc-name={`${dataHcName}-legend-percent-value`}
                      className={styles.LegendItemValue}
                    >
                      {formatMoney(forecast.y)}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      }
    >
      <svg width="0" height="0" viewBox="0 0 0 0" style={{ display: 'block' }}>
        <defs>
          <linearGradient
            id="1YearGrowthGradient"
            x1="0%"
            x2="0%"
            y1="0%"
            y2="100%"
          >
            <stop offset="0%" stopColor="#d6e4ff" />
            <stop offset="100%" stopColor="#9bbeff" />
          </linearGradient>
        </defs>
      </svg>
      <VictoryArea
        x={(datum: ForecastChartDatum) => new Date(datum.x).getTime()}
        y={(datum: ForecastChartDatum) => datum.y}
        //   y0={domain.y[1] / 0.9}
        data={chart.data.slice(chart.currentMonthIndex)}
        style={{
          data: {
            fill: 'rgb(216,216,216)',
            opacity: 0.1,
          },
        }}
      />
      <VictoryArea
        x={(datum: ForecastChartDatum) => new Date(datum.x).getTime()}
        y={(datum: ForecastChartDatum) => datum.y}
        data={chart.data.slice(chart.currentMonthIndex, idxYear1 + 1)}
        style={{
          data: {
            fill: '#d6e4ff',
            opacity: 0.33,
          },
        }}
      />
      <VictoryArea
        x={(datum: ForecastChartDatum) => new Date(datum.x).getTime()}
        y={(datum: ForecastChartDatum) => datum.y}
        data={chart.data.slice(idxYear1, idxYear2 + 1)}
        style={{
          data: {
            fill: '#b9d1ff',
            opacity: 0.66,
          },
        }}
      />
      <VictoryArea
        x={(datum: ForecastChartDatum) => new Date(datum.x).getTime()}
        y={(datum: ForecastChartDatum) => datum.y}
        data={chart.data.slice(idxYear2)}
        style={{
          data: {
            fill: '#9bbeff',
            opacity: 0.99,
          },
        }}
      />
      <VictoryLine
        interpolation="natural"
        x={(datum: ForecastChartDatum) => new Date(datum.x).getTime()}
        y={(datum: ForecastChartDatum) => datum.y}
        data={chart.data}
        style={{
          data: {
            stroke: '#6ba0ff',
          },
        }}
      />
      <VictoryLine
        x={() => getTime(new Date(presentDate))}
        samples={1}
        style={{ data: { stroke: '#6ba0ff' } }}
      />
      <VictoryScatter
        x={(datum: ForecastChartDatum) => new Date(datum.x).getTime()}
        y={(datum: ForecastChartDatum) => datum.y}
        data={[chart.data[chart.currentMonthIndex], ...forecastYears].filter(
          (data) => data
        )}
        dataComponent={<ScatterDataComponent />}
        style={{
          data: {
            stroke: '#6ba0ff',
            strokeWidth:
              chartSize === 'large' || chartSize === 'xlarge' ? '2px' : '1px',
            fill: '#fff',
          },
        }}
      />
    </Chart>
  );
};
