import { PdfTemplate, PdfTemplateIds } from '@hcs/types';

import { ReportApiAgileInsightsPdf } from './ReportApiAgileInsightsPdf';
import { ReportApiAppraisalReviewPdf } from './ReportApiAppraisalReviewPdf';
import { ReportApiBPOPdf } from './ReportApiBPOPdf';
import { ReportApiEffectiveDatePdf } from './ReportApiEffectiveDatePdf';
import { ReportApiLegacyPdf } from './ReportApiLegacyPdf';
import { ReportApiValuePdf } from './ReportApiValuePdf';

export const reportApiTemplateConfig: Partial<
  Record<PdfTemplateIds, PdfTemplate>
> = {
  [PdfTemplateIds.AgileInsights]: ReportApiAgileInsightsPdf,
  [PdfTemplateIds.ReportApiValue]: ReportApiValuePdf,
  [PdfTemplateIds.ReportApiBpoReport]: ReportApiBPOPdf,
  [PdfTemplateIds.ReportApiLegacy]: ReportApiLegacyPdf,
  [PdfTemplateIds.ReportApiEffectiveDate]: ReportApiEffectiveDatePdf,
  [PdfTemplateIds.ReportApiAppraisalReview]: ReportApiAppraisalReviewPdf,
};
