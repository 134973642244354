import React from 'react';

import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import { ChartSizes } from '@hcs/types';
import { PropertyStateFields } from '@hcs/types';

import { NearbyPropertiesChart } from './NearbyPropertyChart';

const { label } = PROPERTY_STATE_FIELD_CONFIGS[PropertyStateFields.livingArea];
interface Props {
  className?: string;
  chartSize?: ChartSizes;
}
export const NearbyPropertiesChartLivingArea = (props: Props) => (
  <NearbyPropertiesChart
    {...props}
    xAxisLabel="Square Feet"
    label={label}
    nearbyChartKey="sqFt"
  />
);
