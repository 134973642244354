import React from 'react';

import { Stats } from '@hcs/design-system';
import { LineChart } from '@hcs/pdf/charts';
import { NullState } from '@hcs/pdf/pdf-service';
import { ChartSizes } from '@hcs/types';
import { formatNumber } from '@hcs/utils';

import { useDaysOnMarketChartDocument } from '../../hooks/useDaysOnMarketChartDocument';

const dataHcName = 'days-on-market-chart';
interface Props {
  chartSize?: ChartSizes;
  className?: string;
}
export const DaysOnMarketChart = ({ chartSize, className }: Props) => {
  const daysOnMarketDoc = useDaysOnMarketChartDocument();
  if (!daysOnMarketDoc?.data?.chart.data?.length) {
    return <NullState dataHcName={`${dataHcName}-null-state`} />;
  }
  return (
    <LineChart
      title="Days on Market - Sold or De-listed Properties"
      dataHcName={dataHcName}
      className={className}
      chartSize={chartSize}
      chartData={daysOnMarketDoc.data.chart.data}
      yAxis={{ label: 'Days on Market' }}
      xAxis={{}}
      contentBelow={
        <Stats
          dataHcName={`${dataHcName}-stats`}
          stats={[
            {
              label: 'This month last year',
              value: formatNumber(
                daysOnMarketDoc.data.chart.stats.median.lastYear
              ),
              dataHcName: 'year-prev',
            },
            {
              label: 'This month',
              value: formatNumber(
                daysOnMarketDoc.data.chart.stats.median.current
              ),
              dataHcName: 'year-current',
            },
          ]}
        />
      }
    />
  );
};
