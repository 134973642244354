import React from 'react';

import { PageFooter } from '@hcs/pdf/pdf-service';
import { PropertyReports } from '@hcs/types';
import { formatDateShort } from '@hcs/utils';

import { useReport } from '../../hooks/useReport';
import { useReportConfig } from '../../hooks/useReportConfig';

const dataHcName = 'bpo-report-page-footer';
export const BpoReportPageFooter = <D extends PropertyReports>({
  simpleFooter,
}: {
  simpleFooter?: boolean;
}) => {
  const { productName } = useReportConfig();
  const report = useReport<D>();
  return (
    <PageFooter
      title={productName}
      leftContent={
        <>
          <div data-hc-name={`${dataHcName}-order-date-label`}>
            Effective Date:&nbsp;
          </div>
          <div data-hc-name={`${dataHcName}-order-date-value`}>
            {formatDateShort(report.effectiveDate)}
          </div>
        </>
      }
      simpleFooter={simpleFooter}
    />
  );
};
