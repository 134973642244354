import { DocumentRoles, RentalHcSuggestedCompDocument } from '@hcs/types';
import { ReportApiReports } from '@hcs/types';

import { useDocumentRole } from './useDocumentRole';

export const useHcSuggestedRentalCompDocuments = <
  Data extends ReportApiReports
>() => {
  return useDocumentRole<RentalHcSuggestedCompDocument, Data>(
    DocumentRoles.RentalHcSuggestedComp
  );
};
