import React from 'react';

import { TwoColSection } from '@hcs/pdf/pdf-service';

import { DaysOnMarketChart } from '../DaysOnMarketChart';
import { ForecastChart } from '../ForecastChart';
import { MonthsOfSupplyChart } from '../MonthsOfSupplyChart';

const dataHcName = 'neighborhood-marketability-section';
export const NeighborhoodMarketabilitySection = () => {
  return (
    <TwoColSection
      dataHcName={dataHcName}
      sectionHeaderProps={{ title: 'Neighborhood & Subject Marketability' }}
      contentLeft={
        <>
          <MonthsOfSupplyChart chartSize="medium" />
          <ForecastChart chartSize="medium" />
        </>
      }
      contentRight={<DaysOnMarketChart chartSize="medium" />}
    />
  );
};
