import React from 'react';

import { TwoColSection } from '@hcs/pdf/pdf-service';

import { BiasRiskFactors } from '../..//features/BiasRiskFactors/BiasRiskFactors';

const dataHcName = 'bias-risk-breakdown-section';
export const BiasRiskBreakdownSection = () => {
  return (
    <TwoColSection
      dataHcName={dataHcName}
      sectionHeaderProps={{
        title: 'Risk Assessment Breakdown',
        titleBorder: false,
      }}
      contentLeft={<BiasRiskFactors biasRiskType="value" />}
      contentRight={<BiasRiskFactors biasRiskType="comparables" />}
    />
  );
};
