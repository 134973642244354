import React from 'react';

import { NullState } from '@hcs/pdf/pdf-service';
import { Section } from '@hcs/pdf/pdf-service';

import { useNearbyPropertiesChartDocument } from '../../hooks/useNearbyPropertiesChartDocument';

import { NearbyPropertiesChartAge } from './NearbyPropertiesChartAge';
import { NearbyPropertiesChartBaths } from './NearbyPropertiesChartBaths';
import { NearbyPropertiesChartLivingArea } from './NearbyPropertiesChartLivingArea';
import { NearbyPropertiesChartLotSize } from './NearbyPropertiesChartLotSize';
import { NearbyPropertiesChartsLegend } from './NearbyPropertiesChartsLegend';
import { NearbyPropertiesChartBeds } from '.';

import styles from './NearbyPropertiesCharts.module.css';

const dataHcName = 'nearby-properties-section';
export const NearbyPropertiesChartsSection = () => {
  const nearbyPropertiesChartDoc = useNearbyPropertiesChartDocument();
  return (
    <Section
      dataHcName={dataHcName}
      sectionHeaderProps={{
        title: `Subject's Comparability to Market`,
      }}
    >
      {nearbyPropertiesChartDoc?.data.charts ? (
        <div className={styles.NearbyPropertiesChartsSectionContent}>
          <NearbyPropertiesChartBeds chartSize="small" />
          <NearbyPropertiesChartBaths chartSize="small" />
          <NearbyPropertiesChartLivingArea chartSize="small" />
          <NearbyPropertiesChartAge chartSize="small" />
          <NearbyPropertiesChartLotSize chartSize="small" />
          <NearbyPropertiesChartsLegend />
        </div>
      ) : (
        <NullState dataHcName={`${dataHcName}-error`} />
      )}
    </Section>
  );
};
