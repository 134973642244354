import React from 'react';
import classNames from 'classnames';

import styles from './NoImagePlaceholder.module.css';

interface Props {
  dataHcName: string;
  showText?: boolean;
  className?: string;
}

export const NoImageIcon = ({ dataHcName }: { dataHcName: string }) => (
  <svg
    data-hc-name={dataHcName}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.636 0C8.836 0 0 8.836 0 19.636c0 10.8 8.836 19.637 19.636 19.637 10.8 0 19.637-8.837 19.637-19.637S30.436 0 19.636 0zm15.71 19.636c0 3.535-1.179 6.873-3.339 9.426l-1.963-1.964c.785-.196 1.374-.982 1.374-1.767v-9.818c0-1.178-.785-1.964-1.963-1.964H27.49l-1.964-1.964h-6.283l-1.571 2.16h-1.178l-6.284-6.48c2.553-1.963 5.89-3.338 9.425-3.338 8.64 0 15.71 7.07 15.71 15.71zm-19.244-.981l6.283 6.283c-.589.197-1.178.393-1.767.393-2.749 0-4.909-2.16-4.909-4.91 0-.588.196-1.177.393-1.766zm2.749-2.75c.589-.196 1.178-.392 1.767-.392 2.75 0 4.91 2.16 4.91 4.909 0 .589-.197 1.178-.393 1.767l-6.284-6.284zM3.927 19.636c0-3.534 1.178-6.872 3.338-9.621l3.731 3.73H9.818c-1.178 0-1.963.786-1.963 1.964v9.818c0 1.178.785 1.964 1.963 1.964h14.924l4.516 4.516c-2.749 2.16-5.89 3.338-9.622 3.338-8.64 0-15.709-7.069-15.709-15.709z"
      fill="#D7D7D7"
      fillRule="nonzero"
    />
  </svg>
);

export const NoImagePlaceHolder = ({
  className,
  showText,
  dataHcName,
}: Props) => (
  <div
    data-hc-name={dataHcName}
    className={classNames(styles.NoImagePlaceholder, className)}
  >
    <NoImageIcon dataHcName={`${dataHcName}-icon`} />
    {showText && (
      <>
        <span data-hc-name={`${dataHcName}-text-line-1`}>Sorry no</span>,
        <span data-hc-name={`${dataHcName}-text-line-2`}>photo available</span>
      </>
    )}
  </div>
);
