import React from 'react';

import { BpoNeighborhoodMarketabilitySection } from '../../features/BpoNeighborhoodMarketabilitySection';

import { BpoReportPage } from './BpoReportPage';

const dataHcName = 'bpo-neighborhood-marketability-page';
export const BpoNeighborhoodMarketabilityPage = () => {
  return (
    <BpoReportPage dataHcName={dataHcName}>
      <BpoNeighborhoodMarketabilitySection />
    </BpoReportPage>
  );
};
