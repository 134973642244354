import {
  DocumentRoles,
  ForecastChartBlockDocument,
  ForecastChartZipDocument,
} from '@hcs/types';
import { ReportApiReports } from '@hcs/types';

import { useSingleDocumentRole } from './useSingleDocumentRole';

export const useForecastChartDocument = <Data extends ReportApiReports>() => {
  const zipDoc = useSingleDocumentRole<ForecastChartZipDocument, Data>(
    DocumentRoles.ForecastChartZip
  );
  const blockDoc = useSingleDocumentRole<ForecastChartBlockDocument, Data>(
    DocumentRoles.ForecastChartBlock
  );

  if (blockDoc?.data?.metaData.selected && blockDoc.data.chart?.data?.length) {
    return blockDoc;
  } else {
    return zipDoc;
  }
};
