import React from 'react';

import { Personalization } from '@hcs/types';

import { ImgFit } from '../ImgFit';

import styles from './Cobranding.module.css';

interface Props
  extends Omit<
    Personalization,
    | 'includeAddressLine1'
    | 'includeAddressLine2'
    | 'includeAskQuestion'
    | 'includeCityStateZipcode'
    | 'includeCompanyLogo'
    | 'includeCompanyName'
    | 'includeEmail'
    | 'includeLicenseNumber'
    | 'includeName'
    | 'includePhone'
    | 'includePhoto'
    | 'includeWebsite'
    | 'companyLogoUrl'
    | 'createdAt'
    | 'id'
    | 'licenseNumber'
    | 'organizationId'
    | 'updatedAt'
    | 'userEmail'
    | 'userId'
  > {
  dataHcName: string;
}

export const Cobranding = ({
  dataHcName,
  addressLine1,
  addressLine2,
  city,
  companyName,
  email,
  firstName,
  lastName,
  phone,
  photoUrl,
  state,
  websiteUrl,
  zipcode,
}: Props) => {
  return (
    <div className={styles.Cobranding} data-hc-name={dataHcName}>
      {photoUrl && (
        <div
          className={styles.PersonalImageContainer}
          data-hc-name={`${dataHcName}-image-container`}
        >
          <ImgFit
            objectFit="cover"
            height={60}
            width={60}
            src={photoUrl}
            dataHcName={`${dataHcName}-image`}
            className={styles.PersonalImage}
          />
        </div>
      )}

      <div
        className={styles.PersonalInfoContainer}
        data-hc-name={`${dataHcName}-info-container`}
      >
        {((firstName && lastName) || companyName) && (
          <p
            className={styles.PersonalInfo}
            data-hc-name={`${dataHcName}-names`}
          >
            {firstName && (
              <span
                data-hc-name={`${dataHcName}-first-name`}
                className={styles.PersonalFullNameAndCompany}
              >
                {firstName}
              </span>
            )}

            {lastName && (
              <span
                data-hc-name={`${dataHcName}-last-name`}
                className={styles.PersonalFullNameAndCompany}
              >
                {firstName ? ` ${lastName}` : lastName}
              </span>
            )}

            {companyName && (
              <span
                data-hc-name={`${dataHcName}-company-name`}
                className={styles.PersonalFullNameAndCompany}
              >
                {` • `}
                {companyName}
              </span>
            )}
          </p>
        )}

        {addressLine1 && (
          <p
            className={styles.PersonalInfo}
            data-hc-name={`${dataHcName}-address-line-one`}
          >
            {addressLine1}
          </p>
        )}

        {addressLine2 && (
          <p
            className={styles.PersonalInfo}
            data-hc-name={`${dataHcName}-address-line-two`}
          >
            {addressLine2}
          </p>
        )}

        {city && state && zipcode && (
          <p
            className={styles.PersonalInfo}
            data-hc-name={`${dataHcName}-city-state-zipcode`}
          >
            {`${city}, ${state} ${zipcode}`}
          </p>
        )}

        {email && (
          <p
            className={styles.PersonalInfo}
            data-hc-name={`${dataHcName}-email`}
          >
            {email}
          </p>
        )}

        {phone && (
          <p
            className={styles.PersonalInfo}
            data-hc-name={`${dataHcName}-phone`}
          >
            {phone}
          </p>
        )}

        {websiteUrl && (
          <p
            className={styles.PersonalInfo}
            data-hc-name={`${dataHcName}-website-url`}
          >
            {websiteUrl}
          </p>
        )}
      </div>
    </div>
  );
};
