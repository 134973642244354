import React from 'react';

import { ChartSizes } from '@hcs/types';

import { NearbyPropertiesChart } from './NearbyPropertyChart';

const label = 'Site Area';
interface Props {
  className?: string;
  chartSize?: ChartSizes;
}
export const NearbyPropertiesChartLotSize = (props: Props) => (
  <NearbyPropertiesChart
    {...props}
    xAxisLabel="Square Feet"
    nearbyChartKey="siteArea"
    label={label}
  />
);
