import React from 'react';

import { SubjectSatelliteImage } from '../../features';

import { BpoReportPage } from './BpoReportPage';

export const AerialPropertyPhotoPage = () => {
  return (
    <BpoReportPage dataHcName="arial-property-photo-page">
      <SubjectSatelliteImage />
    </BpoReportPage>
  );
};
