import React from 'react';

import { Section } from '@hcs/pdf/pdf-service';
import { CompFields, CompTypes, PropertyStateFields } from '@hcs/types';
import { arrayToChunks } from '@hcs/utils';

import {
  CompsCompareComp,
  CompsCompareSubject,
  CompsCompareTable,
  MAX_COMPS_PER_PAGE_COMPARE,
} from '../../features/CompsCompareTable/CompsCompareTable';
import { CompsCompareTableValueRows } from '../../features/CompsCompareTable/CompsCompareTableValueRows';
import { useSelectedOrSuggestedCompFrontPhotos } from '../../hooks/useSelectedOrSuggestedCompFrontPhotos';
import { useSelectedOrSuggestedComps } from '../../hooks/useSelectedOrSuggestedComps';
import { useSubjectDocument } from '../../hooks/useSubjectDocument';
import { useSubjectFrontPhoto } from '../../hooks/useSubjectFrontPhoto';
import { PropertyReportPage } from '../PropertyReports/PropertyReportPage';

export interface PexpCompsComparePageProps {
  fields?: (
    | CompFields
    | PropertyStateFields
    | CompFields[]
    | PropertyStateFields[]
  )[];
  pageIndexInit?: number;
  numPagesTotal?: number;
}
export const PexpCompsComparePage = ({
  fields = [
    CompFields.similarity,
    CompFields.distance,
    PropertyStateFields.lastCloseDate,
    PropertyStateFields.lastClosePrice,
    PropertyStateFields.lastClosePricePerSqFt,
    PropertyStateFields.currentListDate,
    PropertyStateFields.currentListingPrice,
    PropertyStateFields.currentListingPricePerSqFt,
    PropertyStateFields.bedrooms,
    PropertyStateFields.bathrooms,
    PropertyStateFields.livingArea,
    PropertyStateFields.lotSize,
    PropertyStateFields.propertyType,
    PropertyStateFields.yearBuilt,
    PropertyStateFields.currentStatus,
    PropertyStateFields.subdivisionName,
    PropertyStateFields.daysOnMarketActive,
    PropertyStateFields.daysOnMarketCumulative,
    PropertyStateFields.currentValue,
    PropertyStateFields.pool,
    PropertyStateFields.garageSpaces,
    PropertyStateFields.stories,
    PropertyStateFields.basementHas,
    PropertyStateFields.currentDistressed,
    PropertyStateFields.currentFlip,
  ],
  pageIndexInit = 0,
  numPagesTotal,
}: PexpCompsComparePageProps) => {
  const { isSuggested, comps, compType } = useSelectedOrSuggestedComps(
    CompTypes.Sold
  );
  const dataHcName = `pexp-comps-page-${compType}-${
    isSuggested ? 'suggested' : 'selected'
  }`;
  const subjectDocument = useSubjectDocument();
  const subjectPhoto = useSubjectFrontPhoto();
  const compPhotos = useSelectedOrSuggestedCompFrontPhotos();
  // Do not render page if no comps
  if (!comps.length) return null;
  const compChunks = arrayToChunks(comps, MAX_COMPS_PER_PAGE_COMPARE);
  return (
    <>
      {compChunks.map((compsForPage, pageIndexChunk) => {
        const pageIndex = pageIndexInit + pageIndexChunk;
        const subject: CompsCompareSubject = {
          type: 'subject',
          schema: subjectDocument.data,
          userPropertyAdjustments: subjectDocument.userPropertyAdjustments,
        };
        const comps: CompsCompareComp[] = compsForPage.map((comp) => ({
          type: 'comp',
          schema: comp.compSchema,
          userPropertyAdjustments: comp.userPropertyAdjustments,
        }));
        return (
          <PropertyReportPage
            dataHcName={`${dataHcName}-${pageIndex}`}
            key={`${dataHcName}-${pageIndex}`}
          >
            <Section
              sectionHeaderProps={{
                title: `${
                  isSuggested ? `HouseCanary Suggested` : 'Selected'
                } Sold & Active Comparables (${pageIndex + 1} of ${
                  numPagesTotal || compChunks.length
                })`,
              }}
              dataHcName={`${dataHcName}-section`}
            >
              <CompsCompareTable
                dataHcName={`${dataHcName}-table-${pageIndex}`}
                compType={CompTypes.Sold}
                subject={subject}
                comps={comps}
                subjectPhotoUrl={subjectPhoto}
                compPhotoUrls={compPhotos}
                fields={fields}
                // Number comps shouldn't take the map pages into account
                pageIndex={pageIndexChunk}
              />
              <CompsCompareTableValueRows
                subject={subject}
                comps={comps}
                compType={compType}
                isSuggestedComps={isSuggested}
              />
            </Section>
          </PropertyReportPage>
        );
      })}
    </>
  );
};
