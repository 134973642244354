import React from 'react';

import { BiasRiskOverallSection } from '../..//features/BiasRiskOverallSection';
import { BiasRiskBreakdownSection } from '../..//features/BiasRiskSectionBreakdown';
import { SimilarPropertiesSection } from '../..//features/SimilarPropertiesSection';
import { AppraisalReviewPage } from '../..//pdf-pages/AppraisalReviewReports/AppraisalReviewPage';

const dataHcName = 'appraisal-review-summary-page';
export const AppraisalReviewSummaryPage = () => {
  return (
    <AppraisalReviewPage dataHcName={dataHcName}>
      <BiasRiskOverallSection />
      <BiasRiskBreakdownSection />
      <SimilarPropertiesSection />
    </AppraisalReviewPage>
  );
};
