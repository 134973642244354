import React from 'react';

import {
  HeaderDetail,
  PageHeader,
  PageHeaderProps,
} from '@hcs/pdf/pdf-service';
import { PageHeaderProduct } from '@hcs/pdf/pdf-service';
import { COUNTY_CONFIG } from '@hcs/property-state';
import { PropertyReports, PropertyStateType } from '@hcs/types';
import {
  formatCityStateZip,
  formatDateShort,
  formatStreetAddress,
} from '@hcs/utils';

import { useReportConfig } from '../..//hooks/useReportConfig';
import { useReport } from '../../hooks/useReport';
import { useSubjectDocument } from '../../hooks/useSubjectDocument';

import styles from './PropertyReports.module.css';

const { formatValueShort } = COUNTY_CONFIG;
interface Props {
  headerDetails?: PageHeaderProps['headerDetails'];
  cobrandLogo?: boolean;
}
const dataHcName = 'property-report-page-header';
export const PropertyReportPageHeader = <D extends PropertyReports>({
  headerDetails: headerDetailsProp,
  cobrandLogo = true,
}: Props) => {
  const { productName } = useReportConfig();
  const subjectDocument = useSubjectDocument<D>();
  const report = useReport<D>();
  const headerDetails: HeaderDetail[] = [
    {
      dataHcName: `${dataHcName}-effective-date`,
      label: 'Effective Date',
      value: formatDateShort(report.effectiveDate),
    },
  ];
  if (report.clientID) {
    headerDetails.unshift({
      dataHcName: `${dataHcName}-client-file-id`,
      label: 'Client File ID',
      value: report.clientID,
    });
  }
  if (headerDetailsProp?.length) {
    headerDetails.concat(headerDetailsProp);
  }
  return (
    <>
      <PageHeader cobrandLogo={cobrandLogo} headerDetails={headerDetails} />
      <PageHeaderProduct
        productName={productName}
        leftContentLabel={formatStreetAddress(
          subjectDocument?.data.propertyState.location
        )}
        leftContentValue={formatCityStateZip(
          subjectDocument?.data.propertyState.location
        )}
      >
        {subjectDocument?.data.propertyState.location && formatValueShort && (
          <>
            <span
              data-hc-name={`${dataHcName}-left-county-label`}
              className={styles.CountyLabel}
            >
              County:
            </span>
            <span
              data-hc-name={`${dataHcName}-left-county-value`}
              className={styles.CountyValue}
            >
              {formatValueShort({
                propertyStateType: PropertyStateType.Core,
                propertyState: subjectDocument?.data.propertyState,
              })}
            </span>
          </>
        )}
      </PageHeaderProduct>
    </>
  );
};
