import React from 'react';

import { CompTypes } from '@hcs/types';

import { CompsStatus, useTop4Comps } from '../../hooks/useTop4Comps';

import {
  Top4CompsComparePage,
  Top4CompsComparePageProps,
} from './Top4CompsComparePage';

interface Props {
  compsCompareFields?: Top4CompsComparePageProps['fields'];
  compsStatus: CompsStatus;
}
export const BpoCompsPages = ({ compsCompareFields, compsStatus }: Props) => {
  const { comps } = useTop4Comps({ compType: CompTypes.Sold, compsStatus });

  if (!comps?.length) return null;

  return (
    <Top4CompsComparePage
      fields={compsCompareFields}
      compsStatus={compsStatus}
    />
  );
};
