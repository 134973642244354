import React, { ReactNode } from 'react';

import { TwoColSection } from '@hcs/pdf/pdf-service';
import { ListingStatus } from '@hcs/types';

import { Top4CompsRemark } from '../Top4CompsRemark/Top4CompsRemark';

export interface Top4CompsRemarkSectionProps {
  contentLeft?: ReactNode;
  contentRight?: ReactNode;
}
const dataHcName = 'top-4-comps-remark';
export const Top4CompsRemarkSection = (props: Top4CompsRemarkSectionProps) => {
  return (
    <TwoColSection
      dataHcName={dataHcName}
      contentLeft={<Top4CompsRemark compsStatus={ListingStatus.CLOSED} />}
      contentRight={<Top4CompsRemark compsStatus={ListingStatus.ACTIVE} />}
      {...props}
    />
  );
};
