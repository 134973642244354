import { DocumentRoles, NearbyFarmDocument } from '@hcs/types';
import { ReportApiReports } from '@hcs/types';

import { useSingleDocumentRole } from './useSingleDocumentRole';

export const useNearbyPropertiesChartDocument = <
  Data extends ReportApiReports
>() => {
  return useSingleDocumentRole<NearbyFarmDocument, Data>(
    DocumentRoles.NearbyFarm
  );
};
