import React from 'react';

import { MarketRiskSection } from '../../features/MarketRiskSection';
import { NeighborhoodMarketabilitySection } from '../../features/NeighborhoodMarketabilitySection';
import { PropertyReportPage } from '../PropertyReports/PropertyReportPage';

const dataHcName = 'pexp-market-page';
export const PexpMarketPage = () => {
  return (
    <PropertyReportPage dataHcName={dataHcName}>
      <MarketRiskSection />
      <NeighborhoodMarketabilitySection />
    </PropertyReportPage>
  );
};
