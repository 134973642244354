import React from 'react';

import { InspectionConditionSection } from '../../features/InspectionConditionSection';
import { BpoReportPage } from '../BpoReports';

export const PropertyReportInspectionConditionPage = () => {
  return (
    <BpoReportPage dataHcName="inspection-ext">
      <InspectionConditionSection />
    </BpoReportPage>
  );
};
