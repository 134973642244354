import React, { useCallback, useEffect, useState } from 'react';

import { Section, usePdfAddendum } from '@hcs/pdf/pdf-service';
import { AddendumDescriptionType, AddendumItem } from '@hcs/types';

import { SUBJECT_TRANSACTION_LAST_IDX_ON_PAGE } from '../../features';
import { DescList } from '../../features/DescList';
import { useSubjectTransactionHistoryEvents } from '../../hooks';

import { BpoReportPage } from './BpoReportPage';

import styles from './AddendumPages.module.css';

const dataHcName = 'addendum-page';
interface Props {
  pageIndex: number;
  addendumList: AddendumItem[];
}
const AddendumPage = ({ pageIndex, addendumList }: Props) => {
  const [nextPageStartItemIndex, setNextPageStartItemIndex] = useState<
    number | null
  >(null);

  const onItemOverflow = useCallback(
    (itemIndex: number) => {
      if (
        nextPageStartItemIndex === null ||
        (itemIndex > 0 && itemIndex < nextPageStartItemIndex)
      ) {
        setNextPageStartItemIndex(itemIndex);
      }
    },
    [nextPageStartItemIndex]
  );

  return (
    <>
      <BpoReportPage dataHcName={dataHcName}>
        <Section
          className={styles.AddendumSection}
          sectionHeaderProps={{
            title: `Addendum${pageIndex > 0 ? ' Continued' : ''}`,
          }}
          dataHcName="addendum-section"
        >
          <DescList
            list={addendumList}
            pageIndex={pageIndex}
            onItemOverflow={onItemOverflow}
          />
        </Section>
      </BpoReportPage>
      {nextPageStartItemIndex !== null && (
        <AddendumPage
          pageIndex={pageIndex + 1}
          addendumList={addendumList.slice(nextPageStartItemIndex)}
        />
      )}
    </>
  );
};

export const AddendumPages = () => {
  const {
    state: { addendumList },
    actions: { addItemToList },
  } = usePdfAddendum();

  const transactionHistoryEvents = useSubjectTransactionHistoryEvents();
  const shouldAddTransactionHistoryToAddendum =
    transactionHistoryEvents &&
    transactionHistoryEvents?.length > SUBJECT_TRANSACTION_LAST_IDX_ON_PAGE + 1;

  useEffect(() => {
    addItemToList({
      term: 'Superfund Site',
      description: AddendumDescriptionType.SuperFund,
      source: 'Neighborhood & Subject Marketability (page 3)',
    });

    if (shouldAddTransactionHistoryToAddendum) {
      addItemToList({
        term: 'Transaction History',

        description: AddendumDescriptionType.TransactionHistory,
        source: 'Section: Transaction History (page 2)',
      });
    }
  }, [addItemToList, shouldAddTransactionHistoryToAddendum]);

  return <AddendumPage pageIndex={0} addendumList={addendumList} />;
};
