import React, { useMemo } from 'react';
import classNames from 'classnames';

import {
  PropertyStateArgs,
  PropertyStateFields,
  PropertyStateSources,
  PropertyStateType,
} from '@hcs/types';
import { PropertyStateSourcesSchema } from '@hcs/types';

import {
  PropertyCompareTable,
  PropertyCompareTableProps,
} from '../PropertyCompareTable';

import styles from './PropertyDataSourcesTable.module.css';

export interface PropertyDataSourcesTableProps {
  className?: string;
  dataHcName: string;
  fields: PropertyStateFields[];
  propertyStateSources: Partial<PropertyStateSourcesSchema>;
  appraisalPropertyStateArgs?: PropertyStateArgs;
  dataVerificationConfig?: PropertyCompareTableProps['dataVerificationConfig'];
}

const dataHcName = 'property-data-sources-table';
export const PropertyDataSourcesTable = ({
  propertyStateSources,
  appraisalPropertyStateArgs,
  className,
  dataVerificationConfig,
  ...props
}: PropertyDataSourcesTableProps) => {
  const properties: PropertyCompareTableProps['properties'] = useMemo(() => {
    const p: PropertyCompareTableProps['properties'] = [
      {
        uniqueId: 'hc',
        label: 'HouseCanary',
        propertyStateArgs: {
          propertyStateType: PropertyStateType.Core,
          propertyState: propertyStateSources[PropertyStateSources.HC],
        },
      },
      {
        uniqueId: 'mls',
        label: 'MLS',
        propertyStateArgs: {
          propertyStateType: PropertyStateType.Core,
          propertyState: propertyStateSources[PropertyStateSources.MLS],
        },
      },
      {
        uniqueId: 'pr',
        label: 'Public Record',
        propertyStateArgs: {
          propertyStateType: PropertyStateType.Core,
          propertyState: propertyStateSources[PropertyStateSources.PR],
        },
      },
    ];
    if (appraisalPropertyStateArgs) {
      p.unshift({
        uniqueId: 'appraisal',
        label: 'Appraisal',
        propertyStateArgs: appraisalPropertyStateArgs,
      });
    }
    return p;
  }, [propertyStateSources, appraisalPropertyStateArgs]);
  return (
    <PropertyCompareTable
      {...props}
      className={classNames(styles.Table, className)}
      theme={{
        Label: styles.Label,
        Value: styles.Value,
      }}
      properties={properties}
      dataVerificationConfig={dataVerificationConfig}
      dataHcName={dataHcName}
    />
  );
};
